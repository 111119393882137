import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../ModalLayout";
import { hideReceiptOptionsModalAction } from "../../Redux/Actions";
import { Button, Spinner } from "reactstrap";
import InputForm from "../FormComponents/InputForm";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { sendOrderReceiptMutation } from "../../graphql/mutations";
import { Radio, FormControlLabel, Alert } from "@mui/material";
import Swal from "sweetalert2";
import HelperFns from "../../Helpers/HelperFns";

const ReceiptOptionsModal = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // states from store
  const isModalVissible = useSelector(
    (state) => state?.main?.checkout?.receiptOptionsModalActions?.isVissible
  );
  const receiptOptionsModal = useSelector(
    (state) => state?.main?.checkout?.receiptOptionsModal
  );

  // modal error message useState
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  // submit useState
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [radioBtnValue, setRadioBtnValue] = useState("skip");

  const [receiptOptionsModalValues, setReceiptOptionsModalValues] = useState({
    phone_number: "",
    email: "",
  });

  // handle radio btn on change
  const handleRadioBtnChange = (event) => {
    setRadioBtnValue(event.target.value);
  };

  // handle inputs form component change
  const handleInputOnChange = (name, value) => {
    setReceiptOptionsModalValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitBtn = () => {
    setModalErrorMessage("");
    setIsSubmitting(true);

    if (radioBtnValue === "skip") {
      // to close modal
      toggleModal();

      // navigate to back to product list page
      navigate(
        `/products?station_id=${props?.station_id}&station_name=${props?.station_name}&store_id=${props?.store_id}&store_name=${props?.storeName}`
      );

      window.location.reload();
    } else if (radioBtnValue === "print") {
      // to close modal
      toggleModal();

      // handle download link
      HelperFns.downloadFileFromLink(receiptOptionsModal?.receipt);

      // navigate to back to product list page
      navigate(
        `/products?station_id=${props?.station_id}&station_name=${props?.station_name}&store_id=${props?.store_id}&store_name=${props?.storeName}`
      );

      window.location.reload();
    } else if (radioBtnValue === "email" || radioBtnValue === "sms_text") {
      // handle receipt order mutation
      handleSendOrderReceipt();
    } else {
      return;
    }
  };

  // handle toggle modal
  const toggleModal = () => {
    dispatch(hideReceiptOptionsModalAction());
  };

  // Start of handle receipt options mutation
  const handleSendOrderReceipt = () => {
    sendOrderReceipt({
      variables: {
        orderId: receiptOptionsModal?.orderId,
        type: receiptOptionsModal?.type,
        receipt: receiptOptionsModal?.receipt,
        phone: receiptOptionsModalValues?.phone_number,
        email: receiptOptionsModalValues?.email,
      },
    })
      .then((response) => {
        if (response?.errors || response?.sendOrderReceipt?.status == "error") {
          // error swal
          Swal.fire({
            title: "Error",
            text:
              response?.errors[0]?.extensions?.validation ??
              response?.errors[0]?.message,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });
  };

  const [
    sendOrderReceipt,
    {
      data: sendOrderReceiptResponse,
      loading: sendOrderReceiptIsLoading,
      error: sendOrderReceiptError,
    },
  ] = useMutation(sendOrderReceiptMutation, {
    onCompleted: (data) => {
      if (data?.sendOrderReceipt?.message) {
        // to close modal
        toggleModal();

        // navigate to back to product list page
        navigate(
          `/products?station_id=${props?.station_id}&station_name=${props?.station_name}&store_id=${props?.store_id}&store_name=${props?.storeName}`
        );

        window.location.reload();
      }
    },
  });
  // End of handle receipt options mutation

  useEffect(() => {
    setIsSubmitting(false);
    setModalErrorMessage("");
    return () => {};
  }, [isModalVissible]);

  return (
    <>
      <ModalLayout
        modalTitle="Please select an option to get the receipt"
        isOpen={isModalVissible}
        className="confirm_password_modal_wrapper_style"
        centered
      >
        <div>
          <div className="row mb-3">
            <div className="col-12">
              <FormControlLabel
                className="radio_label_style"
                control={
                  <Radio
                    checked={radioBtnValue === "sms_text"}
                    onChange={handleRadioBtnChange}
                    value="sms_text"
                    sx={{
                      color: "#b7c0cd",
                      "&.Mui-checked": {
                        color: "#3e8ef7",
                      },
                    }}
                  />
                }
                label="Text"
                labelPlacement="end"
              />
            </div>

            {radioBtnValue === "sms_text" ? (
              <div className="col-12 mt-3">
                <div className="custom_input_form_wrapper_style">
                  <label>Phone Number</label>
                  <InputForm
                    type="text"
                    name="phone_number"
                    placeholder="Entre phone number"
                    value={receiptOptionsModalValues?.phone_number}
                    datatestid="receipt_options_phone_number_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="row mb-3">
            <div className="col-12">
              <FormControlLabel
                className="radio_label_style"
                control={
                  <Radio
                    checked={radioBtnValue === "email"}
                    onChange={handleRadioBtnChange}
                    value="email"
                    sx={{
                      color: "#b7c0cd",
                      "&.Mui-checked": {
                        color: "#3e8ef7",
                      },
                    }}
                  />
                }
                label="Email"
                labelPlacement="end"
              />
            </div>

            {radioBtnValue === "email" ? (
              <div className="col-12 mt-3">
                <div className="custom_input_form_wrapper_style">
                  <label>Email</label>
                  <InputForm
                    type="text"
                    name="email"
                    placeholder="Entre Email"
                    value={receiptOptionsModalValues?.email}
                    datatestid="receipt_options_email_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className="row mb-3">
            <div className="col-12">
              <FormControlLabel
                className="radio_label_style"
                control={
                  <Radio
                    checked={radioBtnValue === "print"}
                    onChange={handleRadioBtnChange}
                    value="print"
                    sx={{
                      color: "#b7c0cd",
                      "&.Mui-checked": {
                        color: "#3e8ef7",
                      },
                    }}
                  />
                }
                label="Print"
                labelPlacement="end"
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12">
              <FormControlLabel
                className="radio_label_style"
                control={
                  <Radio
                    checked={radioBtnValue === "skip"}
                    onChange={handleRadioBtnChange}
                    value="skip"
                    sx={{
                      color: "#b7c0cd",
                      "&.Mui-checked": {
                        color: "#3e8ef7",
                      },
                    }}
                  />
                }
                label="Skip"
                labelPlacement="end"
              />
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <Button
              disabled={sendOrderReceiptIsLoading}
              className="closeing_station_btn_style w-100"
              type="submit"
              onClick={handleSubmitBtn}
            >
              {sendOrderReceiptIsLoading ? (
                <Spinner size={"sm"} style={{ color: "#fff" }} />
              ) : (
                "Finish"
              )}
            </Button>
          </div>
        </div>

        {/* (Start) Error Message */}
        {modalErrorMessage && isSubmitting && (
          <Alert severity="error" className="mt-2">
            {modalErrorMessage}
          </Alert>
        )}
        {/* (End) Error Message */}
      </ModalLayout>
    </>
  );
};

export default ReceiptOptionsModal;
