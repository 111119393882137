// handle login/logout types
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const SET_TOKEN = "SET_TOKEN";

// handle confirm password modal types
const SHOW_CONFIRM_PASSWORD_MODAL = "SHOW_CONFIRM_PASSWORD_MODAL";
const HIDE_CONFIRM_PASSWORD_MODAL = "HIDE_CONFIRM_PASSWORD_MODAL";

export default {
  // handle login/logout types
  LOGIN,
  LOGOUT,
  SET_TOKEN,

  // handle confirm password modal types
  SHOW_CONFIRM_PASSWORD_MODAL,
  HIDE_CONFIRM_PASSWORD_MODAL,
};
