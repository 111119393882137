import React, { useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  fetchCustomerInfoQuery,
  fetchCountryStatesQuery,
  verfiyAddressQuery,
} from "../../graphql/queries";
import { createNewCustomerMutation } from "../../graphql/mutations";
import {
  storeCustomerInfoFormAction,
  addDataToValidatedCartAction,
  storeCartTotalAmount,
  addSplitPaymentsItemsAction,
  setReamainigValueAction,
  setPaidValueAction,
} from "../../Redux/Actions";
import Swal from "sweetalert2";
import InputForm from "../FormComponents/InputForm";
import SelectForm from "../FormComponents/SelectForm";
import {
  Alert,
  Checkbox,
  FormControlLabel,
  Backdrop,
  CircularProgress,
  Box,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import paymentStatus from "../../Helpers/Constants/PaymentConstants";

const CustomerInfoStep = (props) => {
  let { storeFrontId } = useParams();

  const dispatch = useDispatch();

  // init useStates of customer info form
  const [errorMessage, setErrorMessage] = useState([]);
  const [errorAddressVarificationMessage, setErrorAddressVarificationMessage] =
    useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showNoCustomerSection, setShowNoCustomerSection] = useState(false);
  const [showEmailDropdown, setShowEmailDropdown] = useState(false);
  const [showPhoneDropdown, setShowPhoneDropdown] = useState(false);

  // states from store
  const countries = useSelector((state) => state?.main?.checkout?.countries);

  const validatedCart = useSelector(
    (state) => state?.main?.checkout?.validatedCart
  );

  const cart = useSelector((state) => state?.main?.items?.cart);

  const cartTotalPrice = useSelector(
    (state) => state?.main?.items?.cartTotalPrice
  );

  const [customerInfoFormValues, setCustomerInfoFormValues] = useState({
    id: null,
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address_one: "",
    address_two: "",
    country: "238",
    countryCode: "US",
    city: "",
    state: "",
    zip: "",
    shipping_address_id: 0,
    is_marketing: true,
  });

  // handle inputs form component change
  const handleInputOnChange = (name, value) => {
    setCustomerInfoFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle checkbox change
  const handleCheckboxChange = (event) => {
    setCustomerInfoFormValues((prevState) => ({
      ...prevState,
      is_marketing: event?.target?.checked,
    }));
  };

  // handle on select form component change
  const handleSelectOnChange = (e, selectName) => {
    setCustomerInfoFormValues((prevState) => ({
      ...prevState,
      [selectName]: e?.value,
    }));
  };

  const handleTotalCartAmountCalculations = () => {
    // handle init payment to get client secret for stripe
    props?.handleInitPayment(
      (
        cartTotalPrice?.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue?.itemTotalPrice,
          0
        ) +
        cartTotalPrice?.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue?.itemTotalSalesTaxses,
          0
        ) +
        (validatedCart?.shipping?.amount ? +validatedCart?.shipping?.amount : 0)
      )?.toFixed(2),
      paymentStatus.STRIPE_PAYMENT,
      "customerInfoStep"
    );

    // handle store total cart amount value to use it in another components
    dispatch(
      storeCartTotalAmount(
        (
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalPrice,
            0
          ) +
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalSalesTaxses,
            0
          ) +
          (validatedCart?.shipping?.amount
            ? +validatedCart?.shipping?.amount
            : 0)
        )?.toFixed(2)
      )
    );

    // handle init split payment calcualtions
    dispatch(setPaidValueAction(0));
    dispatch(
      setReamainigValueAction(
        +(
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalPrice,
            0
          ) +
          cartTotalPrice?.reduce(
            (accumulator, currentValue) =>
              accumulator + currentValue?.itemTotalSalesTaxses,
            0
          ) +
          (validatedCart?.shipping?.amount
            ? +validatedCart?.shipping?.amount
            : 0)
        )?.toFixed(2)
      )
    );

    // handle add split payments items when rendring the component
    dispatch(addSplitPaymentsItemsAction(cart));
  };

  // Start of handle fetch customer info query
  const [
    getCustomerInfo,
    {
      data: customerInfoData,
      loading: customerInfoLoading,
      error: customerInfoResponseError,
    },
  ] = useLazyQuery(fetchCustomerInfoQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);
    },
  });
  // End of handle fetch customer info query

  // handle search exsiting custome data by phone or email
  const handleSearchBtn = (e, byValue) => {
    e.preventDefault();

    // set show no customer section
    setShowNoCustomerSection(true);

    if (byValue === "byEmail") {
      setShowEmailDropdown(true);
    } else {
      setShowPhoneDropdown(true);
    }

    // fetch customers info
    getCustomerInfo({
      variables: {
        filters: {
          phone: customerInfoFormValues?.phone,
          email: customerInfoFormValues?.email,
        },
      },
    });
  };

  // handle choose customer
  const handleChooseCustomer = (customer) => {
    setCustomerInfoFormValues({
      id: customer?.id,
      first_name: customer?.name?.split(" ", 1)?.[0],
      last_name: customer?.name?.split(" ", 2)?.[1],
      email: customer?.email,
      phone: customer?.detail?.phone,
      address_one: customer?.shippingAddresses?.[0]?.address,
      address_two: customer?.shippingAddresses?.[0]?.address,
      country: customer?.shippingAddresses?.[0]?.country?.id,
      countryCode: customer?.shippingAddresses?.[0]?.country?.code,
      city: customer?.shippingAddresses?.[0]?.city,
      state: customer?.shippingAddresses?.[0]?.state,
      zip: customer?.shippingAddresses?.[0]?.zip,
      shipping_address_id: customer?.shippingAddresses?.[0]?.id ?? 0,
      is_marketing: true,
    });

    // close dropdowns
    setShowEmailDropdown(false);
    setShowPhoneDropdown(false);
  };

  // Start of handle fetch country states query
  const [
    fetchCountryStates,
    {
      data: countryStatesData,
      loading: countryStatesIsLoading,
      error: countryStatesResponseError,
    },
  ] = useLazyQuery(fetchCountryStatesQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);
    },
  });
  // End of handle fetch country states query

  // handle on change in country and fetch states if there is any
  useEffect(() => {
    fetchCountryStates({
      variables: {
        country_id: customerInfoFormValues?.country
          ? +customerInfoFormValues?.country
          : 238,
      },
    });
  }, [customerInfoFormValues?.country]);

  // Start of handle verfiy address query
  const [
    verfiyAddress,
    {
      data: addressVerficationData,
      loading: addressVerficationIsLoading,
      error: addressVerficationResponseError,
    },
  ] = useLazyQuery(verfiyAddressQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      // handle verfiy address before creating new customer
      if (
        data?.verifyAddress?.status === "verified" ||
        data?.verifyAddress?.status === "error"
      ) {
        // handle create new customer
        handleCreateNewCustomer(customerInfoFormValues);
      } else {
        // handle zip code error only
        if (
          data?.verifyAddress?.messages[0]?.detail_code ===
          "invalid_postal_code"
        ) {
          // handle error message
          setErrorAddressVarificationMessage(
            data?.verifyAddress?.messages[0]?.message
          );
        }
      }

      console.log("onCompleted", data);
    },
  });
  // End of handle verfiy address query

  // handle verfiy address
  const handleVerfiyAddress = () => {
    verfiyAddress({
      variables: {
        input: {
          name:
            customerInfoFormValues?.first_name +
            " " +
            customerInfoFormValues?.last_name,
          address:
            customerInfoFormValues?.address_one +
            " " +
            customerInfoFormValues?.address_two,
          zip: customerInfoFormValues?.zip,
          countryCode: customerInfoFormValues?.countryCode,
          city: customerInfoFormValues?.city,
          state: customerInfoFormValues?.state,
        },
        store_front_id: +storeFrontId,
      },
    });
  };

  // Start of handle create new customer mutation
  const handleCreateNewCustomer = (customerInfoFormValues) => {
    createNewCustomer({
      variables: {
        input: {
          first_name: customerInfoFormValues?.first_name,
          last_name: customerInfoFormValues?.last_name,
          email: customerInfoFormValues?.email,
          phone: customerInfoFormValues?.phone,
          address: customerInfoFormValues?.address_one,
          city: customerInfoFormValues?.city,
          state: customerInfoFormValues?.state
            ? customerInfoFormValues?.state
            : "",
          zip: +customerInfoFormValues?.zip?.replace("-", ""),
          customer_term: 0,
          is_marketing: customerInfoFormValues?.is_marketing,
        },
      },
    })
      .then((response) => {
        if (response?.errors || response?.createCustomer?.status === 0) {
          // handle error message
          setErrorMessage(response?.errors[0]?.extensions?.validation);
        }
      })
      .catch((error) => {
        setErrorMessage(error?.message ?? "Something went wrong");
        console.log("catch", { error });
      });
  };

  const [
    createNewCustomer,
    {
      data: createNewCustomerResponse,
      loading: createNewCustomerIsLoading,
      error: createNewCustomerError,
    },
  ] = useMutation(createNewCustomerMutation, {
    onCompleted: (customerInfoData) => {
      if (customerInfoData?.createCustomer?.status === 1) {
        // store customer info form
        dispatch(
          storeCustomerInfoFormAction({
            id: customerInfoData?.createCustomer?.customer?.id,
            first_name: customerInfoData?.createCustomer?.customer?.name?.split(
              " ",
              1
            )?.[0],
            last_name: customerInfoData?.createCustomer?.customer?.name?.split(
              " ",
              2
            )?.[1],
            email: customerInfoData?.createCustomer?.customer?.email,
            phone: customerInfoData?.createCustomer?.customer?.detail?.phone,
            address_one:
              customerInfoData?.createCustomer?.customer?.shippingAddresses?.[0]
                ?.address,
            address_two:
              customerInfoData?.createCustomer?.customer?.shippingAddresses?.[0]
                ?.address,
            country:
              customerInfoData?.createCustomer?.customer?.shippingAddresses?.[0]
                ?.country?.id,
            countryCode:
              customerInfoData?.createCustomer?.customer?.shippingAddresses?.[0]
                ?.country?.code,
            city: customerInfoData?.createCustomer?.customer
              ?.shippingAddresses?.[0]?.city,
            state:
              customerInfoData?.createCustomer?.customer?.shippingAddresses?.[0]
                ?.state,
            zip: customerInfoData?.createCustomer?.customer
              ?.shippingAddresses?.[0]?.zip,
            shipping_address_id:
              customerInfoData?.createCustomer?.customer?.shippingAddresses?.[0]
                ?.id ?? 0,
            is_marketing: true,
          })
        );

        // handle in case of backorder
        if (validatedCart?.items?.some((obj) => obj?.isBackorder === false)) {
          // handle add data to validated cart
          let data = {
            ...validatedCart,
            customerId: +customerInfoData?.createCustomer?.customer?.id,
            shippingAddressId: 0,
            shipping: {
              carrierId: null,
              serviceMethod: null,
              amount: 0,
              discountAmount: 0,
            },
          };

          dispatch(addDataToValidatedCartAction({ data }));
        }

        // check if it is backorder or not to init payment for stripe
        if (props?.isBackorder) {
          // handle form step
          props?.handleNextStep();
        } else {
          handleTotalCartAmountCalculations();
        }
      }
    },
  });
  // End of handle create new customer mutation

  // handle submit customer step form data
  const handleSubmitBtn = () => {
    setErrorMessage([]);
    setErrorAddressVarificationMessage("");
    setIsSubmitting(true);

    // handle continue with exited cutomer or create new one
    if (props?.showNewCustomerSection === "new_customer") {
      // to verfiy address fist then continue the flow of creating new customer
      if (
        customerInfoFormValues?.first_name === "" ||
        customerInfoFormValues?.last_name === "" ||
        customerInfoFormValues?.address_one === "" ||
        customerInfoFormValues?.zip === "" ||
        customerInfoFormValues?.countryCode === "" ||
        customerInfoFormValues?.city === "" ||
        customerInfoFormValues?.state === ""
      ) {
        setErrorAddressVarificationMessage(
          "Please fill the required shipping address fields to verify the given address."
        );
      } else {
        handleVerfiyAddress();
      }
    } else if (props?.showNewCustomerSection === "exiting_customer") {
      if (Object.values(customerInfoFormValues).every((x) => x !== "")) {
        // store customer info form
        dispatch(storeCustomerInfoFormAction(customerInfoFormValues));

        // handle in case of backorder
        if (validatedCart?.items?.some((obj) => obj?.isBackorder === false)) {
          // handle add data to validated cart
          let data = {
            ...validatedCart,
            customerId: +customerInfoFormValues?.id,
            shippingAddressId: 0,
            shipping: {
              carrierId: null,
              serviceMethod: null,
              amount: 0,
              discountAmount: 0,
            },
          };

          dispatch(addDataToValidatedCartAction({ data }));
        }

        // check if it is backorder or not to init payment for stripe
        if (props?.isBackorder) {
          // handle form step
          props?.handleNextStep();
        } else {
          handleTotalCartAmountCalculations();
        }
      } else {
        setErrorAddressVarificationMessage(
          "please choose an existing customer first to continue to the next step."
        );
      }
    } else if (props?.showNewCustomerSection === "valued_customer") {
      // store customer info form
      dispatch(
        storeCustomerInfoFormAction({
          id: "674",
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          address_one: "",
          address_two: "",
          country: "",
          countryCode: "",
          city: "",
          state: "",
          zip: "",
          is_marketing: true,
        })
      );

      // handle in case of backorder
      if (validatedCart?.items?.some((obj) => obj?.isBackorder === false)) {
        // handle add data to validated cart
        let data = {
          ...validatedCart,
          customerId: 674,
          shippingAddressId: 0,
          shipping: {
            carrierId: null,
            serviceMethod: null,
            amount: 0,
            discountAmount: 0,
          },
        };

        dispatch(addDataToValidatedCartAction({ data }));
      }

      // check if it is backorder or not to init payment for stripe
      if (props?.isBackorder) {
        // handle form step
        props?.handleNextStep();
      } else {
        handleTotalCartAmountCalculations();
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    // reset all useStates of this form
    setIsSubmitting(false);
    setShowEmailDropdown(false);
    setShowPhoneDropdown(false);
    setErrorMessage([]);
    setErrorAddressVarificationMessage("");
    setShowNoCustomerSection(false);
    setCustomerInfoFormValues({
      id: null,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      address_one: "",
      address_two: "",
      country: "238",
      countryCode: "US",
      city: "",
      state: "",
      zip: "",
      shipping_address_id: 0,
      is_marketing: true,
    });
    return () => {};
  }, [props?.showNewCustomerSection]);

  return (
    <>
      <div
        className={
          props?.showNewCustomerSection === "valued_customer"
            ? "guest_wrapper_style"
            : "step_form_wrapper_style"
        }
      >
        {props?.showNewCustomerSection === "new_customer" ? (
          <>
            <div className="row mb-4">
              <div
                className={
                  errorMessage?.["input.first_name"] && isSubmitting
                    ? "col-12 col-md-6 error_wrapper_style"
                    : "col-12 col-md-6"
                }
              >
                <div className="custom_input_form_wrapper_style">
                  <label>First Name*</label>
                  <InputForm
                    type="text"
                    name="first_name"
                    placeholder="Entre first name"
                    value={customerInfoFormValues?.first_name}
                    datatestid="first_name_customer_info_step_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>

                {/* (Start) Error Message */}
                {errorMessage?.["input.first_name"] && isSubmitting && (
                  <Alert severity="error" className="mt-2">
                    {errorMessage?.["input.first_name"]
                      ?.toString()
                      ?.replace("The input.", "")}
                  </Alert>
                )}
                {/* (End) Error Message */}
              </div>

              <div
                className={
                  errorMessage?.["input.last_name"] && isSubmitting
                    ? "col-12 col-md-6 error_wrapper_style"
                    : "col-12 col-md-6"
                }
              >
                <div className="custom_input_form_wrapper_style">
                  <label>Last Name*</label>
                  <InputForm
                    type="text"
                    name="last_name"
                    placeholder="Entre last name"
                    value={customerInfoFormValues?.last_name}
                    datatestid="last_name_customer_info_step_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>

                {/* (Start) Error Message */}
                {errorMessage?.["input.last_name"] && isSubmitting && (
                  <Alert severity="error" className="mt-2">
                    {errorMessage?.["input.last_name"]
                      ?.toString()
                      ?.replace("The input.", "")}
                  </Alert>
                )}

                {/* (End) Error Message */}
              </div>
            </div>

            <div className="row mb-4">
              <div
                className={
                  errorMessage?.["input.phone"] && isSubmitting
                    ? "col-12 error_wrapper_style mb-4"
                    : "col-12 mb-4"
                }
              >
                <div className="custom_input_form_wrapper_style">
                  <label>Phone*</label>
                  <InputForm
                    type="text"
                    name="phone"
                    placeholder="Entre phone"
                    value={customerInfoFormValues?.phone}
                    datatestid="phone_customer_info_step_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>

                {/* (Start) Error Message */}
                {errorMessage?.["input.phone"] && isSubmitting && (
                  <Alert severity="error" className="mt-2">
                    {errorMessage?.["input.phone"]
                      ?.toString()
                      ?.replace("The input.", "")}
                  </Alert>
                )}
                {/* (End) Error Message */}
              </div>

              <div
                className={
                  errorMessage?.["input.email"] && isSubmitting
                    ? "col-12 error_wrapper_style"
                    : "col-12"
                }
              >
                <div className="custom_input_form_wrapper_style">
                  <label>Email*</label>
                  <InputForm
                    type="text"
                    name="email"
                    placeholder="Entre email"
                    value={customerInfoFormValues?.email}
                    datatestid="email_customer_info_step_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>

                {/* (Start) Error Message */}
                {errorMessage?.["input.email"] && isSubmitting && (
                  <Alert severity="error" className="mt-2">
                    {errorMessage?.["input.email"]
                      ?.toString()
                      ?.replace("The input.", "")}
                  </Alert>
                )}
                {/* (End) Error Message */}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12">
                <FormControlLabel
                  className="checkbox_label_style"
                  control={
                    <Checkbox
                      name="is_marketing"
                      sx={{
                        color: "#b7c0cd",
                        "&.Mui-checked": {
                          color: "#3e8ef7",
                        },
                      }}
                      checked={customerInfoFormValues?.is_marketing}
                      datatestid="is_marketing_customer_info_step_input"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Customer would like to receive offers and promotions"
                />

                {/* (Start) Error Message */}
                {errorMessage?.["input.is_marketing"] && isSubmitting && (
                  <Alert severity="error" className="mt-2">
                    {errorMessage?.["input.is_marketing"]
                      ?.toString()
                      ?.replace("The input.", "")}
                  </Alert>
                )}
                {/* (End) Error Message */}
              </div>
            </div>

            <div className="row mb-4">
              <div
                className={
                  errorMessage?.["input.address"] && isSubmitting
                    ? "col-12 error_wrapper_style mb-4"
                    : "col-12 mb-4"
                }
              >
                <div className="custom_input_form_wrapper_style">
                  <label>Address Line 1*</label>
                  <InputForm
                    type="text"
                    name="address_one"
                    placeholder="Entre address"
                    value={customerInfoFormValues?.address_one}
                    datatestid="address_one_customer_info_step_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>

                {/* (Start) Error Message */}
                {errorMessage?.["input.address"] && isSubmitting && (
                  <Alert severity="error" className="mt-2">
                    {errorMessage?.["input.address"]
                      ?.toString()
                      ?.replace("The input.", "")}
                  </Alert>
                )}
                {/* (End) Error Message */}
              </div>

              <div
                className={
                  errorMessage?.["input.address"] && isSubmitting
                    ? "col-12 error_wrapper_style"
                    : "col-12"
                }
              >
                <div className="custom_input_form_wrapper_style">
                  <label>Address Line 2</label>
                  <InputForm
                    type="text"
                    name="address_two"
                    placeholder="Entre address"
                    value={customerInfoFormValues?.address_two}
                    datatestid="address_two_customer_info_step_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>

                {/* (Start) Error Message */}
                {errorMessage?.["input.address"] && isSubmitting && (
                  <Alert severity="error" className="mt-2">
                    {errorMessage?.["input.address"]
                      ?.toString()
                      ?.replace("The input.", "")}
                  </Alert>
                )}
                {/* (End) Error Message */}
              </div>
            </div>

            <div className="row mb-4">
              <div
                className={
                  errorMessage?.["input.city"] && isSubmitting
                    ? "col-12 col-xl-4 mb-4 mb-xl-0 error_wrapper_style"
                    : "col-12 col-xl-4 mb-4 mb-xl-0"
                }
              >
                <div className="custom_input_form_wrapper_style">
                  <label>City*</label>
                  <InputForm
                    type="text"
                    name="city"
                    placeholder="Entre city"
                    value={customerInfoFormValues?.city}
                    datatestid="city_customer_info_step_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>

                {/* (Start) Error Message */}
                {errorMessage?.["input.city"] && isSubmitting && (
                  <Alert severity="error" className="mt-2">
                    {errorMessage?.["input.city"]
                      ?.toString()
                      ?.replace("The input.", "")}
                  </Alert>
                )}
                {/* (End) Error Message */}
              </div>

              {countryStatesData?.getStates?.length > 0 ? (
                <div
                  className={
                    errorMessage?.["input.state"] && isSubmitting
                      ? "col-12 col-lg-6 col-xl-4 error_wrapper_style"
                      : "col-12 col-lg-6 col-xl-4"
                  }
                >
                  <div className="custom_select_wrapper_style">
                    <label>State*</label>

                    <SelectForm
                      name="state"
                      selectOptions={countryStatesData?.getStates?.map(
                        ({ name }) => ({
                          label: name,
                          value: name,
                        })
                      )}
                      placeholder="Select state"
                      handleSelectFormChange={(e) =>
                        handleSelectOnChange(e, "state")
                      }
                      selectContainerDataTestId="state_container_filter_data_test_id_in_custom_info_step"
                      isClearable
                    />
                  </div>

                  {/* (Start) Error Message */}
                  {errorMessage?.["input.state"] && isSubmitting && (
                    <Alert severity="error" className="mt-2">
                      {errorMessage?.["input.state"]
                        ?.toString()
                        ?.replace("The input.", "")}
                    </Alert>
                  )}
                  {/* (End) Error Message */}
                </div>
              ) : null}

              <div
                className={
                  errorMessage?.["input.zip"] && isSubmitting
                    ? "col-12 col-lg-6 col-xl-4 error_wrapper_style"
                    : "col-12 col-lg-6 col-xl-4"
                }
              >
                <div className="custom_input_form_wrapper_style">
                  <label>Zip*</label>
                  <InputForm
                    type="text"
                    name="zip"
                    placeholder="Entre Zip code"
                    value={customerInfoFormValues?.zip}
                    datatestid="zip_customer_info_step_input"
                    handleInputOnChange={handleInputOnChange}
                  />
                </div>

                {/* (Start) Error Message */}
                {errorMessage?.["input.zip"] && isSubmitting && (
                  <Alert severity="error" className="mt-2">
                    {errorMessage?.["input.zip"]
                      ?.toString()
                      ?.replace("The input.", "")}
                  </Alert>
                )}

                {/* (End) Error Message */}
              </div>

              {/* <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-12">
                    <label>Country*</label>
                    <SelectForm
                      name="country"
                      selectOptions={{
                        label: "United States of America",
                        value: "238",
                        code: "US",
                      }}
                      handleSelectFormChange={(e) =>
                        handleSelectOnChange(e, "country")
                      }
                      selectContainerDataTestId="country_container_filter_data_test_id_in_custom_info_step"
                      isDisabled={true}
                      isLoading={countryStatesIsLoading}
                      defaultValue={{
                        label: "United States of America",
                        value: "238",
                        code: "US",
                      }}
                    />
                  </div>
                </div>
              </div> */}
            </div>

            {/* (Start) Error address verfication Message */}
            {errorAddressVarificationMessage && isSubmitting && (
              <Alert severity="error" className="mt-2">
                {errorAddressVarificationMessage}
              </Alert>
            )}
            {/* (End) Error address verfication Message */}
          </>
        ) : props?.showNewCustomerSection === "exiting_customer" ? (
          <>
            <div className="row position-relative">
              <div className="col-12 mb-4">
                <div className="custom_input_form_wrapper_style">
                  <label>Email*</label>
                  <form onSubmit={(e) => handleSearchBtn(e, "byEmail")}>
                    <InputForm
                      type="text"
                      name="email"
                      placeholder="Enter email"
                      value={customerInfoFormValues?.email}
                      datatestid="search_customer_email_input"
                      handleInputOnChange={handleInputOnChange}
                      inputIconComponent={
                        <img
                          src={require("../../Assets/img/search-icon.png")}
                          disabled={
                            customerInfoFormValues?.email === "" ? true : false
                          }
                          type="submit"
                          onClick={(e) => handleSearchBtn(e, "byEmail")}
                          datatestid="search_customer_email_btn"
                        />
                      }
                    />
                  </form>
                </div>

                {!showEmailDropdown ? null : (
                  <>
                    {customerInfoLoading ? (
                      <Backdrop
                        sx={{
                          backgroundColor: "rgba(80, 104, 136, 0.44)",
                          zIndex: 1,
                        }}
                        open={customerInfoLoading}
                      >
                        <Box sx={{ width: "100%", textAlign: "center" }}>
                          <CircularProgress size={112} />
                        </Box>
                      </Backdrop>
                    ) : (
                      <>
                        {customerInfoData?.getCustomers?.length > 0 ? (
                          <div class="input_dropdown_style">
                            <h3>Suggested search</h3>

                            {customerInfoData?.getCustomers?.map(
                              (customer, i) => {
                                return (
                                  <div
                                    className="dropdown_result_style"
                                    datatestid={`customer-info-index-${i}`}
                                    onClick={() =>
                                      handleChooseCustomer(customer)
                                    }
                                  >
                                    {customer?.email} [ {customer?.name} ]
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          <>
                            {showNoCustomerSection ? (
                              <div
                                className="d-flex justify-content-center align-items-center p-5 no_result_text_style input_dropdown_style"
                                onClick={() => setShowNoCustomerSection(false)}
                              >
                                <div className="row">
                                  <div className="col-12">
                                    No available results
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="col-12">
                <div className="custom_input_form_wrapper_style">
                  <label>Phone*</label>
                  <form onSubmit={(e) => handleSearchBtn(e, "byPhone")}>
                    <InputForm
                      type="text"
                      name="phone"
                      value={customerInfoFormValues?.phone}
                      datatestid="search_customer_phone_input"
                      placeholder="Enter phone number"
                      handleInputOnChange={handleInputOnChange}
                      inputIconComponent={
                        <img
                          src={require("../../Assets/img/search-icon.png")}
                          disabled={
                            customerInfoFormValues?.phone === "" ? true : false
                          }
                          type="submit"
                          onClick={(e) => handleSearchBtn(e, "byPhone")}
                          datatestid="search_customer_phone_btn"
                        />
                      }
                    />
                  </form>
                </div>

                {!showPhoneDropdown ? null : (
                  <>
                    {customerInfoLoading ? (
                      <Backdrop
                        sx={{
                          backgroundColor: "rgba(80, 104, 136, 0.44)",
                          zIndex: 1,
                        }}
                        open={customerInfoLoading}
                      >
                        <Box sx={{ width: "100%", textAlign: "center" }}>
                          <CircularProgress size={112} />
                        </Box>
                      </Backdrop>
                    ) : (
                      <>
                        {customerInfoData?.getCustomers?.length > 0 ? (
                          <div class="input_dropdown_style">
                            <h3>Suggested search</h3>

                            {customerInfoData?.getCustomers?.map(
                              (customer, i) => {
                                return (
                                  <div
                                    className="dropdown_result_style"
                                    datatestid={`customer-info-index-${i}`}
                                    onClick={() =>
                                      handleChooseCustomer(customer)
                                    }
                                  >
                                    {customer?.detail?.phone} [ {customer?.name}{" "}
                                    ]
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          <>
                            {showNoCustomerSection ? (
                              <div
                                className="d-flex justify-content-center align-items-center p-5 no_result_text_style input_dropdown_style"
                                onClick={() => setShowNoCustomerSection(false)}
                              >
                                <div className="row">
                                  <div className="col-12">
                                    No available results
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <p>Complete Checkout as Guest...!</p>
        )}
      </div>

      <div className="d-flex step_form_btns_wrapper_style">
        <Button
          className="next_step_btn_style"
          type="submit"
          onClick={handleSubmitBtn}
          datatestid="continue_from_customer_info_step_to_next_step_btn"
          disabled={
            addressVerficationIsLoading ||
            createNewCustomerIsLoading ||
            props?.initpaymentIsLoading
          }
        >
          {addressVerficationIsLoading ||
          createNewCustomerIsLoading ||
          props?.initpaymentIsLoading ? (
            <Spinner size={"sm"} style={{ color: "#fff" }} />
          ) : (
            "Continue"
          )}
        </Button>

        <Button className="back_to_step_btn_style" disabled={true}>
          Back
        </Button>
      </div>
    </>
  );
};

export default CustomerInfoStep;
