import React from "react";

const WarningForCashBalanceModal = (props) => {
  return (
    <div className="warning_section_wrapper_style">
      <img src={require("../../Assets/img/warning-icon.png")} />

      <label>Warning</label>

      <p>
        Your current count does not match the Opening Cash plus Cash
        Transactions.
      </p>
    </div>
  );
};

export default WarningForCashBalanceModal;
