import React, { useEffect, useState } from "react";
import InputForm from "../FormComponents/InputForm";
import SelectForm from "../FormComponents/SelectForm";
import { Button, Spinner } from "reactstrap";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getReturnOrderQuery,
  fetchReturnReasonOptionsQuery,
} from "../../graphql/queries";
import { returnItemsCartMutation } from "../../graphql/mutations";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  storeOrderItemInfoAction,
  addToReturnedItemsCartAction,
  clearReturnedItemsCartAction,
  removeReturnedCartItemAction,
} from "../../Redux/Actions";
import HelperFns from "../../Helpers/HelperFns";
import ReturnedItemsCartIncDecInput from "./ReturnedItemsCartIncDecInput";
import ReturnedItemsInfoIncDecInput from "./ReturnedItemsInfoIncDecInput";

const ReturnItems = (props) => {
  const dispatch = useDispatch();

  const returnOrderData = useSelector(
    (state) => state?.main?.items?.returnOrderData
  );
  const returnedItemsCart = useSelector(
    (state) => state?.main?.items?.returnedItemsCart
  );

  const [returnItemValues, setReturnItemValues] = useState({
    order_number: "",
    rmaReasonId: "",
    rmaNote: "",
  });

  // handle fetch of return order reason options
  useEffect(() => {
    fetchReturnReasonOptions();
  }, []);

  // handle inputs form component change
  const handleInputOnChange = (name, value) => {
    setReturnItemValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle on select form component change
  const handleSelectOnChange = (e, selectName) => {
    setReturnItemValues((prevState) => ({
      ...prevState,
      [selectName]: e?.value,
    }));
  };

  // handle order number input submit
  const handleOrderNumberSubmit = (e) => {
    e.preventDefault();

    handleGetReturnOrder({
      variables: {
        id: returnItemValues?.order_number,
      },
    });
  };

  // handle order return item btn
  const handleReturnItemBtn = (itemData, index) => {
    let orderData = {
      ...returnOrderResponse?.getReturnOrder,
      items: [
        ...returnOrderResponse?.getReturnOrder?.items?.filter(
          (el) => el?.quantity > 0 && el?.is_returnable === false
        ),
        {
          ...itemData,
          quantity: +itemData?.quantity - +itemData?.custom_quantity,
        },
      ],
    };

    dispatch(storeOrderItemInfoAction(orderData));

    dispatch(addToReturnedItemsCartAction(itemData, index));
  };

  // handle remove returned cart item
  const handleRemoveReturnedCartItem = (index, itemData) => {
    const currentItems = JSON.parse(JSON.stringify(returnOrderData.items))

    const removedItemIndex = currentItems.findIndex(item => {
      return item.item_id === itemData.item_id && item.is_returnable === true
    })

    if (removedItemIndex >= 0) {
      const currentItem = currentItems[removedItemIndex]

      currentItem.quantity = itemData.quantity
    } else {
      currentItems.push(itemData)
    }

    dispatch(removeReturnedCartItemAction(index, currentItems));
  };

  // handle clear returned cart item
  const handleClearReturnedCartItem = (itemData) => {
    dispatch(clearReturnedItemsCartAction(itemData));
  };

  // handle return items btn
  const handleReturnItemsBtn = () => {
    let data = {
      items: [
        ...returnedItemsCart?.map(
          ({
            order_item_id,
            price,
            price_after_discount,
            custom_quantity,
          }) => ({
            orderItemId: +order_item_id,
            quantity: +custom_quantity,
            refundAmount: returnOrderData?.amount_is_editable
              ? price_after_discount
                ? +(+price_after_discount * +custom_quantity)?.toFixed(2)
                : +(+price * +custom_quantity)?.toFixed(2)
              : null,
          })
        ),
      ],
      transactionId: +returnOrderData?.transaction_id,
      rmaReasonId: +returnItemValues?.rmaReasonId,
      returnShippingAmount:
        +returnOrderData?.remaining_refundable_shipping?.toFixed(2),
      rmaNote: returnItemValues?.rmaNote,
    };

    // handle return items in the cart
    handleReturnItems({ data });
  };

  // Start of handle return order query
  const [
    handleGetReturnOrder,
    {
      data: returnOrderResponse,
      loading: isReturnOrderLoading,
      error: returnOrderError,
    },
  ] = useLazyQuery(getReturnOrderQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);

      if (data?.getReturnOrder?.status === "false") {
        // error swal
        Swal.fire({
          title: "Error",
          text: data?.getReturnOrder?.message,
          timer: 3000,
          showConfirmButton: false,
          imageUrl: require("../../Assets/img/error-icon.png"),
          imageAlt: "Image not found",
          customClass: {
            popup: "swal-error-style",
            container: "swal-title",
          },
        });

        // reset all returned items
        handleClearReturnedCartItem([]);
      } else {
        let orderData = {
          ...data?.getReturnOrder,
          items: [
            ...data?.getReturnOrder?.items?.map((item) => ({
              ...item,
              custom_quantity: 0,
            })),
          ],
        };

        dispatch(storeOrderItemInfoAction(orderData));
      }
    },
  });
  // End of handle return order query

  // Start of handle fetch return reason options query
  const [
    fetchReturnReasonOptions,
    {
      data: returnReasonOptionsData,
      loading: returnReasonOptionsIsLoading,
      error: returnReasonOptionsResponseError,
    },
  ] = useLazyQuery(fetchReturnReasonOptionsQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);
    },
  });
  // End of handle fetch return reason options query

  // Start of handle returned items cart mutation
  const handleReturnItems = (cartData) => {
    returnItems({
      variables: {
        cart: cartData?.data,
      },
    })
      .then((response) => {
        if (response?.errors) {
          // error swal
          Swal.fire({
            title: "Error",
            text:
              response?.errors[0]?.extensions?.validation ??
              response?.errors[0]?.message,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });
  };

  const [
    returnItems,
    {
      data: returnItemsCartResponse,
      loading: returnItemsCartIsLoading,
      error: returnItemsCartError,
    },
  ] = useMutation(returnItemsCartMutation, {
    onCompleted: (data) => {
      if (data?.return?.message) {
        // reset all returned items
        handleClearReturnedCartItem([]);

        // handle the receipt options modal
        props?.handleShowReceiptOptionsModal(
          returnOrderData?.id,
          data?.return?.receipt
        );
      }
    },
  });
  // End of handle returned items cart mutation

  return (
    <>
      {isReturnOrderLoading || returnReasonOptionsIsLoading ? (
        <Backdrop
          sx={{
            backgroundColor: "rgba(80, 104, 136, 0.44)",
            zIndex: 1,
          }}
          open={isReturnOrderLoading || returnReasonOptionsIsLoading}
        >
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <CircularProgress size={112} />
          </Box>
        </Backdrop>
      ) : null}

      <div className="return_items_wrapper_style">
        <div className="row">
          <div className="col-12 col-lg-8 mb-3 mb-lg-0">
            <div className="order_info_wrapper_style">
              <div className="row mt-1">
                <div className="col-12">
                  <form onSubmit={handleOrderNumberSubmit}>
                    <div className="custom_input_form_wrapper_style">
                      <label>Order Number*</label>
                      <InputForm
                        type="text"
                        name="order_number"
                        placeholder="Entre order number"
                        value={returnItemValues?.order_number}
                        datatestid="return__item_order_number_input"
                        handleInputOnChange={handleInputOnChange}
                      />
                    </div>
                  </form>
                </div>
              </div>

              {returnOrderData?.is_returnable &&
              returnOrderData?.items?.filter((el) => el?.quantity > 0)
                ?.length ? (
                <>
                  <h3>Items in this order</h3>

                  <div className="row">
                    <div className="col-12">
                      <div className="items_in_this_order_wrapper_style">
                        {returnOrderData?.items
                          ?.filter((el) => el?.quantity > 0)
                          ?.map((item, i) => {
                            return (
                              <div
                                className="d-flex align-items-center justify-content-between my-3"
                                key={i}
                              >
                                <div>
                                  <h4>Quantity: {item?.quantity}</h4>
                                </div>

                                <div>
                                  <h2>{item?.item_name}</h2>

                                  <div className="d-flex align-items-baseline justify-content-between">
                                    <div>
                                      <p>
                                        Waranty Days:{" "}
                                        {item?.warranty_days
                                          ? item?.warranty_days
                                          : "Out"}
                                      </p>
                                    </div>

                                    <div className="mx-2">
                                      <p>
                                        Return Days:{" "}
                                        {item?.return_days
                                          ? item?.return_days
                                          : "Out"}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  {item?.price_after_discount ? (
                                    <>
                                      <h4>
                                        $
                                        {(
                                          +item?.price_after_discount *
                                          +item?.quantity
                                        )?.toFixed(2)}
                                      </h4>

                                      <div className="return_order_price_discount_style">
                                        $
                                        {(
                                          +item?.price * +item?.quantity
                                        )?.toFixed(2)}
                                      </div>
                                    </>
                                  ) : (
                                    <h4>
                                      $
                                      {(
                                        +item?.price * +item?.quantity
                                      )?.toFixed(2)}
                                    </h4>
                                  )}
                                </div>

                                {item?.is_returnable ? (
                                  <>
                                    <div>
                                      <ReturnedItemsInfoIncDecInput
                                        index={i}
                                        quantity={item?.quantity}
                                        custom_quantity={item?.custom_quantity}
                                      />
                                    </div>

                                    <div>
                                      <Button
                                        onClick={() =>
                                          handleReturnItemBtn(item, i)
                                        }
                                        disabled={
                                          +item?.custom_quantity === 0
                                            ? true
                                            : false
                                        }
                                      >
                                        Return
                                      </Button>
                                    </div>
                                  </>
                                ) : (
                                  <div className="return_item_status_style">
                                    Item Can't be Returned
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {returnOrderData?.status === "false" ? (
                    <div className="d-flex justify-content-center align-items-center p-5 no_result_text_style">
                      <div className="row">
                        <div className="col-12">
                          Order doesn't exist try another order number
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>

          <div className="col-12 col-lg-4">
            <div className="returned_item_detials_wrapper_style">
              <div className="cart_wrapper_style">
                <div className="cart_header_style">
                  <p className="mb-0">Returned Items</p>

                  <h2
                    onClick={() =>
                      handleClearReturnedCartItem(returnedItemsCart)
                    }
                  >
                    Delete All
                  </h2>
                </div>

                <div className="cart_items_wrapper_style">
                  {returnedItemsCart?.length ? (
                    <>
                      {returnedItemsCart?.map((returnedItem, i) => {
                        return (
                          <div className="cart_items_divs_style" key={i}>
                            <div className="d-flex align-items-start justify-content-between">
                              <div className="d-flex">
                                <img
                                  className="product_in_cart_img_style"
                                  height={85}
                                  src={returnedItem?.image_url?.small}
                                  onError={(e) => HelperFns.addDefaultSrc(e)}
                                  alt="Image Not Found"
                                />

                                <div className="px-2">
                                  <h2>{returnedItem?.item_name}</h2>
                                </div>
                              </div>

                              <img
                                className="cursor-pointer"
                                onClick={() =>
                                  handleRemoveReturnedCartItem(i, returnedItem)
                                }
                                src={require("../../Assets/img/trash-icon.png")}
                              />
                            </div>

                            <div className="d-flex justify-content-between">
                              <div className="input_Increment_decrement_wrapper_style">
                                {returnOrderData?.amount_is_editable ? null : (
                                  <div className="px-0">
                                    <p className="mb-0">
                                      {returnedItem?.custom_quantity}
                                    </p>
                                  </div>
                                )}

                                <div className="px-2">
                                  <p className="mb-0">X</p>
                                </div>

                                <div className="px-0">
                                  <p className="mb-0">
                                    ${+returnedItem?.price?.toFixed(2)}
                                  </p>
                                </div>

                                <div className="d-flex align-items-baseline px-1">
                                  <p className="px-1 mb-0">=</p>
                                  <p className="px-1 mb-0">
                                    $
                                    {(
                                      +returnedItem?.price *
                                      +returnedItem?.custom_quantity
                                    )?.toFixed(2)}
                                  </p>
                                </div>
                              </div>

                              {returnOrderData?.amount_is_editable ? (
                                <ReturnedItemsCartIncDecInput
                                  index={i}
                                  quantity={returnedItem?.custom_quantity}
                                />
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="text-center empty_cart_wrapper_style">
                        <img
                          src={require("../../Assets/img/empty-cart-icon-in-return-items.png")}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {returnedItemsCart?.length > 0 ? (
                  <>
                    <div className="return_reason_wrapper_style">
                      <div className="custom_select_wrapper_style my-3">
                        <label>Return Reason*</label>

                        <SelectForm
                          name="rmaReasonId"
                          selectOptions={returnReasonOptionsData?.rmaReasons?.map(
                            ({ id, name }) => ({
                              label: name,
                              value: id,
                            })
                          )}
                          placeholder="Select Reason"
                          handleSelectFormChange={(e) =>
                            handleSelectOnChange(e, "rmaReasonId")
                          }
                          selectContainerDataTestId="return_reason_select"
                          isClearable
                        />
                      </div>

                      <textarea
                        rows="4"
                        name="rmaNote"
                        onChange={(e) =>
                          handleInputOnChange("rmaNote", e?.target?.value)
                        }
                        value={returnItemValues?.rmaNote}
                        placeholder="Enter Return Note"
                        textAreaTestId="return_reason_note_text_area"
                      />
                    </div>

                    <div className="cart_bottom_style">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>Subtotal</h4>

                        <h3>
                          - $
                          {returnedItemsCart
                            ?.reduce(
                              (accumulator, currentValue) =>
                                accumulator +
                                (currentValue?.price_after_discount
                                  ? +currentValue?.price_after_discount
                                  : +currentValue?.price) *
                                  +currentValue?.custom_quantity,
                              0
                            )
                            ?.toFixed(2)}
                        </h3>
                      </div>

                      <div className="d-flex align-items-center justify-content-between my-3">
                        <h4>Sales Tax</h4>

                        <h3>
                          - $
                          {returnedItemsCart
                            ?.reduce(
                              (accumulator, currentValue) =>
                                accumulator +
                                +currentValue?.tax *
                                  +currentValue?.custom_quantity,
                              0
                            )
                            ?.toFixed(2)}
                        </h3>
                      </div>

                      <div className="d-flex align-items-center justify-content-between my-3">
                        <h4>Refundable Shipping Amount</h4>

                        <h3>
                          - $
                          {returnOrderData?.remaining_refundable_shipping?.toFixed(
                            2
                          )}
                        </h3>
                      </div>

                      <hr />

                      <div className="d-flex align-items-center justify-content-between">
                        <h6>
                          <b>Total</b>
                        </h6>

                        <h5>
                          <b>
                            - $
                            {(
                              returnedItemsCart?.reduce(
                                (accumulator, currentValue) =>
                                  accumulator +
                                  (currentValue?.price_after_discount
                                    ? +currentValue?.price_after_discount
                                    : +currentValue?.price) *
                                    +currentValue?.custom_quantity,
                                0
                              ) +
                              returnedItemsCart?.reduce(
                                (accumulator, currentValue) =>
                                  accumulator +
                                  +currentValue?.tax *
                                    +currentValue?.custom_quantity,
                                0
                              ) +
                              +returnOrderData?.remaining_refundable_shipping
                            )?.toFixed(2)}
                          </b>
                        </h5>
                      </div>

                      <Button
                        className="checkout_cart_btn_style"
                        datatestid="return_cart_items_btn"
                        onClick={handleReturnItemsBtn}
                      >
                        {returnItemsCartIsLoading ? (
                          <Spinner size={"sm"} style={{ color: "#fff" }} />
                        ) : (
                          <>Return</>
                        )}
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="cart_bottom_style empty_cart_wrapper_style">
                    <Button className="checkout_cart_btn_style">Return</Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReturnItems;
