import React from "react";
import {
  onReturnedItemsInfoInputIncrementAction,
  onReturnedItemsInfoInputDecrementAction,
} from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

const ReturnedItemsInfoIncDecInput = (props) => {
  const dispatch = useDispatch();

  // Start of increment decrement quantity number function
  const incQuantityeNum = () => {
    dispatch(
      onReturnedItemsInfoInputIncrementAction(
        "custom_quantity",
        props?.index,
        +props?.quantity
      )
    );
  };

  const decQuantityNum = () => {
    if (props?.quantity > 1) {
      dispatch(
        onReturnedItemsInfoInputDecrementAction(
          "custom_quantity",
          props?.index,
          +props?.quantity
        )
      );
    }
  };
  // End of increment decrement quantity number function

  return (
    <div className="input_Increment_decrement_wrapper_style">
      <div className="d-flex align-items-center me-1 number_input_wrapper_style">
        <IndeterminateCheckBoxIcon
          datatestid={`dec-returned-item-quantity-btn-${props?.index}`}
          onClick={decQuantityNum}
        />

        <input
          type="number"
          onWheel={(e) => e.target.blur()}
          value={props?.custom_quantity}
          datatestid={`item-returned-item-info-quantity-input-${props?.index}`}
        />

        <AddBoxIcon
          datatestid={`inc-returned-item-info-quantity-btn-${props?.index}`}
          onClick={incQuantityeNum}
        />
      </div>
    </div>
  );
};

export default ReturnedItemsInfoIncDecInput;
