import Types from "../Actions/types";

export const initState = {
  authUser: {},
  token: null,
  confirmPasswordModal: {},
  confirmPasswordModalActions: {
    isVissible: false,
  },
  confirmPasswordModalOutOfSessionActions: {
    isVissible: false,
  },
};

export const authReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      return {
        ...state,
        token: action.payload.token ?? null,
        authUser: {
          ...state.authUser,
          ...action.payload.data,
        },
      };

    case Types.SHOW_CONFIRM_PASSWORD_MODAL:
      if (action.payload.data.modalType === "exit_pos_session") {
        return {
          ...state,
          confirmPasswordModalOutOfSessionActions: {
            isVissible: true,
          },
          confirmPasswordModal: {
            ...state.confirmPasswordModal,
            ...action.payload.data,
          },
        };
      } else {
        return {
          ...state,
          confirmPasswordModalActions: {
            isVissible: true,
          },
          confirmPasswordModal: {
            ...state.confirmPasswordModal,
            ...action.payload.data,
          },
        };
      }

    case Types.HIDE_CONFIRM_PASSWORD_MODAL:
      return {
        ...state,
        confirmPasswordModalActions: {
          isVissible: false,
        },
        confirmPasswordModalOutOfSessionActions: {
          isVissible: false,
        },
        confirmPasswordModal: initState.confirmPasswordModal,
      };

    case Types.LOGOUT:
      return { ...initState };

    case Types.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};
