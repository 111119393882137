import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../Layout/Navbar";
import ConfirmPasswordModal from "../../Components/ConfirmPasswordModal";
import OpenDrawerManuallyModal from "../../Components/OpenDrawerManuallyModal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ItemCombination from "../../Components/ItemCombination";
import { hideConfirmPasswordModalAction } from "../../Redux/Actions";

const ProductDetails = () => {
  const dispatch = useDispatch();

  // states from store
  const isConfirmPasswordModalVissible = useSelector(
    (state) => state?.main?.auth?.confirmPasswordModalActions?.isVissible
  );
  const isOpenDrawerManuallyModalVissible = useSelector(
    (state) => state?.main?.items?.openDrawerManuallyModalActions?.isVissible
  );
  const stationInfo = useSelector((state) => state?.main?.station?.stationInfo);
  const itemsCombinations = useSelector(
    (state) => state?.main?.items?.itemsCombinations
  );

  useEffect(() => {
    dispatch(hideConfirmPasswordModalAction());
  }, []);

  return (
    <>
      {/* Start of NavBar Component */}
      <Navbar itemsListIsOpened={true} />
      {/* End of NavBar Component */}

      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className="station_store_name_wrapper_style"
      >
        <Link underline="hover" href="/store-fronts">
          Home
        </Link>
        <Link underline="hover" href="/store-fronts">
          Stores
        </Link>
        <Link underline="hover" href="/store-fronts">
          {stationInfo?.storeName} Retail POS
        </Link>
        <Link underline="hover" href="/store-fronts">
          Stations
        </Link>
        <Link
          underline="hover"
          href={`/products?station_id=${stationInfo?.stationId}&station_name=${stationInfo?.stationName}&store_id=${stationInfo?.storeId}&store_name=${stationInfo?.storeName}`}
        >
          {stationInfo?.stationName}
        </Link>
        <Link color="text.primary">{itemsCombinations?.name}</Link>
      </Breadcrumbs>

      <div className="content_style">
        <ItemCombination />
      </div>

      {/* Start of confirm password modal */}
      {isConfirmPasswordModalVissible ? <ConfirmPasswordModal /> : null}
      {/* End of confirm password modal */}

      {/* Start of open drawer manually modal */}
      {isOpenDrawerManuallyModalVissible ? (
        <OpenDrawerManuallyModal stationId={+stationInfo?.stationId} />
      ) : null}
      {/* End of open drawer manually modal */}
    </>
  );
};

export default ProductDetails;
