import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Assets/sass/main.scss";
import { ApolloProvider } from "@apollo/client";
import { client } from "./Services/Client";
import { Provider } from "react-redux";
import { Store, persistor } from "./Redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import AppRoutes from "./AppRoutes";


import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    dark: {
      main: '#263238',
      light: "#263238",
      dark: "#263238",
      contrastText: '#263238',
      background: {
        default: '#263238',
      },
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#E0C2FF',
      light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#47008F',
    },
  },
});

const App = (props) => {
  return (
    <Provider store={Store}>
      <PersistGate persistor={persistor}>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <AppRoutes />
          </ThemeProvider>
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
