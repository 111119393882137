import React, { useEffect } from "react";
import ModalLayout from "../ModalLayout";
import { Box, Backdrop } from "@mui/material";
import { Button } from "reactstrap";
import { fetchStationsQuery } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import {
  showCashDrawerModalAction,
  hideStationModalAction,
  showSalesPersonIdModalAction,
  storeStationStripeInfoAction,
  storeStationInfoAction,
  clearCartAction,
} from "../../Redux/Actions";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import stationStatus from "../../Helpers/Constants/StationConstants";
import HelperFns from "../../Helpers/HelperFns";
import Swal from "sweetalert2";

const StationsModal = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // states from store
  const isModalVissible = useSelector(
    (state) => state?.main?.station?.stationModalActions?.isVissible
  );
  const stationModal = useSelector(
    (state) => state?.main?.station?.stationModal
  );

  // handle toggle modal
  const toggleModal = () => {
    dispatch(hideStationModalAction());
  };

  // Start of handle fetch stations query
  const [
    getStations,
    { data: stationData, loading: stationLoading, error: stationError },
  ] = useLazyQuery(fetchStationsQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      // close station modal
      toggleModal();

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);

      let stripeData = [...data?.getStations];

      // store the station stripe info
      dispatch(storeStationStripeInfoAction({ stripeData }));
    },
  });
  // End of handle fetch stations query

  // handle close station function
  const handleCloseStation = (stationId, stationName) => {
    let data = {
      station_id: stationId,
      station_name: stationName,
      store_id: stationModal?.storeId,
      storeName: stationModal?.storeName,
      modalType: "close_station",
    };

    // close modal
    toggleModal();
    dispatch(showCashDrawerModalAction({ data }));
  };

  // handle open station function
  const handleOpenStation = (stationId, stationName) => {
    let data = {
      station_id: stationId,
      station_name: stationName,
      store_id: stationModal?.storeId,
      storeName: stationModal?.storeName,
      modalType: "open_station",
    };

    // close modal
    toggleModal();
    dispatch(showCashDrawerModalAction({ data }));

    dispatch(
      storeStationInfoAction({
        data: {
          stationId: stationId,
          stationName: stationName,
          storeId: stationModal?.storeId,
          storeName: stationModal?.storeName,
        },
      })
    );
  };

  // handle show sales person id modal
  const handleShowSalesPersonIdModal = () => {
    let data = {};
    dispatch(showSalesPersonIdModalAction({ data }));
  };

  // handle go to products container with link
  const handleNavigateToProductPage = (stationId, stationName) => {
    handleShowSalesPersonIdModal();

    // store stattion info
    let data = {
      stationId: stationId,
      stationName: stationName,
      storeId: stationModal?.storeId,
      storeName: stationModal?.storeName,
    };
    dispatch(storeStationInfoAction({ data }));

    // navigate to station items list
    navigate(
      `/products?station_id=${stationId}&station_name=${stationName}&store_id=${stationModal?.storeId}&store_name=${stationModal?.storeName}`
    );

    // clear cart
    dispatch(clearCartAction());

    // close modal
    toggleModal();
  };

  useEffect(() => {
    if (isModalVissible) {
      // fetch stations
      getStations({
        variables: {
          storeId: +stationModal?.storeId,
        },
      });
    }
    return () => {};
  }, [isModalVissible]);

  return (
    <>
      {stationLoading ? (
        <Backdrop
          sx={{
            backgroundColor: "rgba(80, 104, 136, 0.44)",
            zIndex: 1,
          }}
          open={stationLoading}
        >
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <CircularProgress size={112} />
          </Box>
        </Backdrop>
      ) : (
        <ModalLayout
          isOpen={isModalVissible}
          toggle={toggleModal}
          modalTitle={`${stationModal?.storeName}`}
          className="modal_right_style drawer_animation_style"
        >
          <div className="station_modal_wrapper_style">
            <div className="modal-container">
              {stationData?.getStations?.length > 0 ? (
                <>
                  <h3 className="sub_modal_title_style">Choose station</h3>

                  {stationData?.getStations?.map((station) => {
                    return (
                      <div
                        key={station?.id}
                        className="station_content_wrapper_style"
                      >
                        <div className="station_name_status_wrapper_style">
                          <div className="d-flex align-items-center">
                            <span className="station_index_style pe-2">
                              {station?.name}
                            </span>

                            {/* <img
                              className="mx-1"
                              src={require("../../Assets/img/question-icon.png")}
                            /> */}
                          </div>

                          {station?.status == stationStatus.STATION_OPENED ? (
                            <div className="d-flex align-items-center">
                              <img
                                className="mx-1"
                                src={require("../../Assets/img/lock-open-icon.png")}
                              />

                              <span className="station_status_style">
                                Opened
                              </span>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center">
                              <img
                                className="mx-1"
                                src={require("../../Assets/img/lock-close-icon.png")}
                              />

                              <span className="station_status_style">
                                Closed
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="d-flex align-items-center station_avatar_wrapper_style">
                          <AvatarGroup max={3} spacing="small">
                            {station?.stationUser === null ? (
                              <div className="zero_cashiers_icon_wrapper_style">
                                <img
                                  src={require("../../Assets/img/person-circule-icon.png")}
                                />
                              </div>
                            ) : (
                              <Avatar
                                sx={{
                                  bgcolor: HelperFns.stringToColor(
                                    station?.stationUser?.cashier?.name
                                  ),
                                }}
                              >
                                {HelperFns.getInitials(
                                  station?.stationUser?.cashier?.name
                                )}
                              </Avatar>
                            )}
                          </AvatarGroup>

                          <div className="cashiers_quanitiy_style">
                            <p className="mb-0 mx-1">{`(${
                              station?.stationUser === null ? 0 : 1
                            }) Cashiers`}</p>
                          </div>
                        </div>

                        <div className="d-flex stations_btn_wrapper_style">
                          {station?.status == stationStatus.STATION_OPENED ? (
                            <>
                              <Button
                                className="go_to_pos_btn_style"
                                datatestid={`go-to-pos-station-${station?.id}-btn`}
                                onClick={() =>
                                  handleNavigateToProductPage(
                                    station?.id,
                                    station?.name
                                  )
                                }
                              >
                                Go To POS
                              </Button>

                              <Button
                                className="close_station_btn_style mx-2"
                                datatestid={`close-station-${station?.id}-btn`}
                                onClick={() =>
                                  handleCloseStation(station?.id, station?.name)
                                }
                              >
                                Close
                              </Button>
                            </>
                          ) : (
                            <Button
                              className="open_station_btn_style"
                              datatestid={`open-station-${station?.id}-btn`}
                              onClick={() =>
                                handleOpenStation(station?.id, station?.name)
                              }
                            >
                              Open
                              <img
                                className="ms-2"
                                src={require("../../Assets/img/blue-arrow-icon.png")}
                              />
                            </Button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="d-flex justify-content-center align-items-center p-5 no_result_text_style">
                  <div className="row">
                    <div className="col-12">
                      There are no stations to display
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </ModalLayout>
      )}
    </>
  );
};

export default StationsModal;
