import Types from "../types";

// Start of show and hide sales person id action
export const showSalesPersonIdModalAction = (data) => ({
  type: Types.SHOW_SALES_PERSON_ID_MODAL,
  payload: data,
});

export const hideSalesPersonIdModalAction = () => ({
  type: Types.HIDE_SALES_PERSON_ID_MODAL,
});
// End of show and hide sales person id action

// Start of show and hide of open drawer manually modal action
export const showOpenDrawerManuallyModalAction = (data) => ({
  type: Types.SHOW_OPEN_DRAWER_MANUALLY_MODAL,
  payload: data,
});

export const hideOpenDrawerManuallyAction = () => ({
  type: Types.HIDE_OPEN_DRAWER_MANUALLY_MODAL,
});
// End of show and hide of open drawer manually modal action

// Start of items combination actions
export const showItemCombinationAction = (data) => ({
  type: Types.SHOW_ITEMS_COMBINATION,
  payload: data,
});

export const hideItemCombinationAction = () => ({
  type: Types.HIDE_ITEMS_COMBINATION,
});

export const fetchedItemCombinationsAction = (data) => ({
  type: Types.FETCHED_ITEM_COMBINATION,
  payload: data,
});

export const addCustomCombinationsForItemsAction = (data) => ({
  type: Types.ADD_CUSTOM_ITEM_COMBINATION,
  payload: data,
});

export const itemCombinationInputChange = (name, index, value, limit) => {
  return {
    type: Types.ON_ITEM_COMBINATION_CHANGE,
    payload: {
      name,
      index,
      value,
      limit,
    },
  };
};

export const removeCombinationItemAction = (name, index) => {
  return {
    type: Types.REMOVE_ITEM_COMBINATION,
    payload: {
      name,
      index,
    },
  };
};

export const customCombinationInputChange = (name, index, value) => {
  return {
    type: Types.ON_CUSTOM_COMBINATION_CHANGE,
    payload: {
      name,
      index,
      value,
    },
  };
};
// End of items combination actions

// Start of cart actions
export const addToCartAction = (data) => ({
  type: Types.ADD_TO_CART,
  payload: data,
});

export const clearCartAction = () => ({
  type: Types.CLEAR_CART,
});

export const resetCartTotalAction = () => ({
  type: Types.RESET_CART_TOTAL,
});

export const onCartInputIncrementAction = (name, index) => {
  return {
    type: Types.ON_CART_INPUT_INCREMENT,
    payload: {
      name,
      index,
    },
  };
};

export const onCartInputDecrementAction = (name, index) => {
  return {
    type: Types.ON_CART_INPUT_DECREMENT,
    payload: {
      name,
      index,
    },
  };
};

export const calculateTotalItemPriceAction = (value, name, index) => ({
  type: Types.ADD_ITEM_PRICE_AND_QUANTITY_TO_CART_TOTAL,
  payload: {
    value,
    name,
    index,
  },
});

export const removeCartItemAction = (index, itemId) => {
  return {
    type: Types.REMOVE_CART_ITEM,
    payload: {
      index,
      itemId,
    },
  };
};
// End of cart actions

// Start of return items actions
export const storeOrderItemInfoAction = (data) => ({
  type: Types.STORE_RETURN_ORDER_INFO,
  payload: data,
});

export const addToReturnedItemsCartAction = (data, index) => ({
  type: Types.ADD_TO_RETURNED_ITEMS_CART,
  payload: {
    data,
    index,
  },
});

export const clearReturnedItemsCartAction = (data) => ({
  type: Types.CLEAR_RETURNED_ITEMS_CART,
  payload: data,
});

export const removeReturnedCartItemAction = (index, itemData) => {
  return {
    type: Types.REMOVE_RETURNED_CART_ITEM,
    payload: {
      index,
      itemData,
    },
  };
};

export const onReturnedItemsCartInputIncrementAction = (name, index) => {
  return {
    type: Types.ON_RETURNED_ITEMS_CART_INPUT_INCREMENT,
    payload: {
      name,
      index,
    },
  };
};

export const onReturnedItemsCartInputDecrementAction = (name, index) => {
  return {
    type: Types.ON_RETURNED_ITEMS_CART_INPUT_DECREMENT,
    payload: {
      name,
      index,
    },
  };
};

export const onReturnedItemsInfoInputIncrementAction = (name, index, limit) => {
  return {
    type: Types.ON_RETURNED_ITEMS_INFO_INPUT_INCREMENT,
    payload: {
      name,
      index,
      limit,
    },
  };
};

export const onReturnedItemsInfoInputDecrementAction = (name, index, limit) => {
  return {
    type: Types.ON_RETURNED_ITEMS_INFO_INPUT_DECREMENT,
    payload: {
      name,
      index,
      limit,
    },
  };
};
// End of return items actions
