import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../Layout/Navbar";
import ConfirmPasswordModal from "../../Components/ConfirmPasswordModal";
import OpenDrawerManuallyModal from "../../Components/OpenDrawerManuallyModal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  hideConfirmPasswordModalAction,
  showReceiptOptionsModalAction,
} from "../../Redux/Actions";
import ReturnItems from "../../Components/ReturnItems";
import ReceiptOptionsModal from "../../Components/ReceiptOptionsModal";

const ReturnItemsPage = () => {
  const dispatch = useDispatch();

  // states from store
  const isConfirmPasswordModalVissible = useSelector(
    (state) => state?.main?.auth?.confirmPasswordModalActions?.isVissible
  );
  const isOpenDrawerManuallyModalVissible = useSelector(
    (state) => state?.main?.items?.openDrawerManuallyModalActions?.isVissible
  );
  const isReceiptOptionsModalVissible = useSelector(
    (state) => state?.main?.checkout?.receiptOptionsModalActions?.isVissible
  );
  const stationInfo = useSelector((state) => state?.main?.station?.stationInfo);

  // handle show receipt options modal
  const handleShowReceiptOptionsModal = (orderId, receipt) => {
    let data = {
      orderId: +orderId,
      receipt: receipt,
      type: "return",
    };
    dispatch(showReceiptOptionsModalAction({ data }));
  };

  useEffect(() => {
    dispatch(hideConfirmPasswordModalAction());
  }, []);

  return (
    <>
      {/* Start of NavBar Component */}
      <Navbar itemsListIsOpened={true} inReturnItemsPage={true} />
      {/* End of NavBar Component */}

      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className="station_store_name_wrapper_style"
      >
        <Link underline="hover" href="/store-fronts">
          Home
        </Link>
        <Link underline="hover" href="/store-fronts">
          Stores
        </Link>
        <Link underline="hover" href="/store-fronts">
          {stationInfo?.storeName} Retail POS
        </Link>
        <Link underline="hover" href="/store-fronts">
          Stations
        </Link>
        <Link
          underline="hover"
          href={`/products?station_id=${stationInfo?.stationId}&station_name=${stationInfo?.stationName}&store_id=${stationInfo?.storeId}&store_name=${stationInfo?.storeName}`}
        >
          {stationInfo?.stationName}
        </Link>
        <Link color="text.primary">Return Items</Link>
      </Breadcrumbs>

      <div className="content_style">
        <ReturnItems
          handleShowReceiptOptionsModal={handleShowReceiptOptionsModal}
        />
      </div>

      {/* Start of confirm password modal */}
      {isConfirmPasswordModalVissible ? <ConfirmPasswordModal /> : null}
      {/* End of confirm password modal */}

      {/* Start of open drawer manually modal */}
      {isOpenDrawerManuallyModalVissible ? (
        <OpenDrawerManuallyModal stationId={+stationInfo?.stationId} />
      ) : null}
      {/* End of open drawer manually modal */}

      {/* Start of receipt options modal */}
      {isReceiptOptionsModalVissible ? (
        <ReceiptOptionsModal
          station_id={stationInfo?.stationId}
          station_name={stationInfo?.stationName}
          store_id={stationInfo?.storeId}
          storeName={stationInfo?.storeName}
        />
      ) : null}
      {/* End of receipt options modal */}
    </>
  );
};

export default ReturnItemsPage;
