import React, { useState, useCallback, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import {
  Tabs,
  Tab,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Breadcrumbs,
  Link,
} from "@mui/material";
import Navbar from "../../Layout/Navbar";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate, useLocation, useBeforeUnload } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CustomerInfoStep from "../../Components/CustomerInfoStep";
import ShippingStep from "../../Components/ShippingStep";
import CartStep from "../../Components/CartStep";
import PaymentStep from "../../Components/PaymentStep";
import ConfirmPasswordModal from "../../Components/ConfirmPasswordModal";
import OpenDrawerManuallyModal from "../../Components/OpenDrawerManuallyModal";
import ReceiptOptionsModal from "../../Components/ReceiptOptionsModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Config } from "../../Config";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import {
  initiatePaymentMutation,
  checkoutMutation,
} from "../../graphql/mutations";
import { useParams } from "react-router";
import {
  clearShippingAddresses,
  hideConfirmPasswordModalAction,
  showConfirmPasswordModalAction,
  resetCheckoutAction,
  clearCartAction,
  showSalesPersonIdModalAction,
  showSplitPaymentSectionAction,
  showCashSectionAction,
  showSplitItemsSectionAction,
  showReceiptOptionsModalAction,
} from "../../Redux/Actions";
import { v4 as uuid } from "uuid";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(Config.STRIPE_API_KEY);

const CheckoutPage = () => {
  // states from store
  const validatedCart = useSelector(
    (state) => state?.main?.checkout?.validatedCart
  );

  const stationInfo = useSelector((state) => state?.main?.station?.stationInfo);

  const authUser = useSelector((state) => state?.main?.auth?.authUser);

  const isConfirmPasswordModalVissible = useSelector(
    (state) => state?.main?.auth?.confirmPasswordModalActions?.isVissible
  );
  const isOpenDrawerManuallyModalVissible = useSelector(
    (state) => state?.main?.items?.openDrawerManuallyModalActions?.isVissible
  );
  const isReceiptOptionsModalVissible = useSelector(
    (state) => state?.main?.checkout?.receiptOptionsModalActions?.isVissible
  );

  // start of handle checkout form steps
  const steps = validatedCart?.items?.some((obj) => obj?.isBackorder === true)
    ? [
        {
          label: "Customer info",
          icon: require("../../Assets/img/customer-info-icon.png"),
          activeIcon: require("../../Assets/img/customer-info-icon.png"),
        },
        {
          label: "Shipping",
          icon: require("../../Assets/img/shipping-icon.png"),
          activeIcon: require("../../Assets/img/shipping-icon-blue.png"),
        },
        {
          label: "Payment methods",
          icon: require("../../Assets/img/payment-method-icon.png"),
          activeIcon: require("../../Assets/img/payment-method-icon-blue.png"),
        },
      ]
    : [
        {
          label: "Customer info",
          icon: require("../../Assets/img/customer-info-icon.png"),
          activeIcon: require("../../Assets/img/customer-info-icon.png"),
        },
        {
          label: "Payment methods",
          icon: require("../../Assets/img/payment-method-icon.png"),
          activeIcon: require("../../Assets/img/payment-method-icon-blue.png"),
        },
      ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackToStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let { storeFrontId, stationName, storeId, storeName } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  // to reset checkout page
  useEffect(() => {
    setActiveStep(0);
    dispatch(resetCheckoutAction());
  }, []);

  // tabs use states
  const [tabValue, setTabValue] = useState("valued_customer");

  const handleToggleTabsChange = (event, newValue) => {
    setTabValue(newValue);
    setShowNewCustomerSection(newValue);

    // to reset values when change tab
    dispatch(resetCheckoutAction());
    setActiveStep(0);
  };

  // use state for steps name to use in init stripe
  const [stepName, setStepFormName] = useState("");

  // handle navigate to items list
  const handleAddMoreItemsBtn = () => {
    // -1 means go back using router dom history
    navigate(-1);

    // close exit session modal
    dispatch(hideConfirmPasswordModalAction());

    // clear shipping address
    dispatch(clearShippingAddresses());
  };

  // save it off before users navigate away and when user change url
  useBeforeUnload(
    useCallback(() => {
      let data = {
        authEmail: authUser?.email,
        modalType: "exit_pos_session",
      };
      dispatch(showConfirmPasswordModalAction({ data }));
    }, [location])
  );

  const [hideDiscountSections, setHideDiscountSections] = useState(false);

  const [clientSecret, setClientSecret] = useState("");

  const [stripeUniqueId, setUniqueIdForStripe] = useState("");

  // init useStates of customer info form
  const [showNewCustomerSection, setShowNewCustomerSection] =
    useState("valued_customer");
  // End of handle checkout form steps

  // handle render active steps
  const renderActiveTab = (stepsIndex) => {
    if (validatedCart?.items?.some((obj) => obj?.isBackorder === true)) {
      return (
        <>
          {activeStep === 0 ? (
            <CustomerInfoStep
              handleNextStep={handleNextStep}
              showNewCustomerSection={showNewCustomerSection}
              setShowNewCustomerSection={setShowNewCustomerSection}
              isBackorder={validatedCart?.items?.some(
                (obj) => obj?.isBackorder === true
              )}
            />
          ) : activeStep === 1 ? (
            <ShippingStep
              handleBackToStep={handleBackToStep}
              handleInitPayment={handleInitPayment}
              initpaymentIsLoading={initpaymentIsLoading}
            />
          ) : activeStep === 2 ? (
            <Elements
              key={stripeUniqueId}
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <PaymentStep
                handleNextStep={handleNextStep}
                handleBackToStep={handleBackToStep}
                handleInitPayment={handleInitPayment}
                station_id={storeFrontId}
                station_name={stationName}
                store_id={storeId}
                storeName={storeName}
                paymentIntentClientSecret={clientSecret}
              />
            </Elements>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          {activeStep === 0 ? (
            <CustomerInfoStep
              handleNextStep={handleNextStep}
              showNewCustomerSection={showNewCustomerSection}
              setShowNewCustomerSection={setShowNewCustomerSection}
              isBackorder={validatedCart?.items?.some(
                (obj) => obj?.isBackorder === true
              )}
              handleInitPayment={handleInitPayment}
              initpaymentIsLoading={initpaymentIsLoading}
            />
          ) : activeStep === 1 ? (
            <Elements
              key={stripeUniqueId}
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <PaymentStep
                handleNextStep={handleNextStep}
                handleBackToStep={handleBackToStep}
                handleInitPayment={handleInitPayment}
                station_id={storeFrontId}
                station_name={stationName}
                store_id={storeId}
                storeName={storeName}
                paymentIntentClientSecret={clientSecret}
              />
            </Elements>
          ) : null}
        </>
      );
    }
  };

  // Start of handle init payment mutation
  const handleInitPayment = (totalAmount, paymentMethod, stepFormName) => {
    initiatePayment({
      variables: {
        amount: +totalAmount,
        // if it is 1 ==> means cash method and if is 2 ==> means stripe payment method
        method: paymentMethod,
        stationId: +storeFrontId,
      },
    })
      .then((response) => {
        if (response?.errors || response?.initiatePayment?.status === 0) {
          // error swal
          Swal.fire({
            title: "Error",
            text:
              response?.errors[0]?.extensions?.validation ??
              response?.errors[0]?.message,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });

    // to handle init payments stripe by steps name
    setStepFormName(stepFormName);
  };

  const [
    initiatePayment,
    {
      data: initpaymentResponse,
      loading: initpaymentIsLoading,
      error: initpaymentError,
    },
  ] = useMutation(initiatePaymentMutation, {
    onCompleted: (data) => {
      if (data?.initiatePayment?.client_secret) {
        // set client secrect for stripe
        setClientSecret(data?.initiatePayment?.client_secret);

        // set unique id for stirpe to render
        setUniqueIdForStripe(uuid());

        if (stepName === "shippingStep" || stepName === "customerInfoStep") {
          handleNextStep();
        }
      }
    },
  });
  // End of handle init payment mutation

  // handle show sales person id modal
  const handleShowSalesPersonIdModal = () => {
    let data = {};
    dispatch(showSalesPersonIdModalAction({ data }));
  };

  // handle show receipt options modal
  const handleShowReceiptOptionsModal = (orderId, receipt) => {
    let data = {
      orderId: +orderId,
      receipt: receipt,
      type: "checkout",
    };
    dispatch(showReceiptOptionsModalAction({ data }));
  };

  // Start of handle checkout mutation
  const handleCheckout = () => {
    checkout({
      variables: {
        cart: validatedCart,
      },
    })
      .then((response) => {
        if (response?.errors || response?.checkout?.status === "error") {
          // error swal
          Swal.fire({
            title: "Error",
            text:
              response?.errors[0]?.extensions?.validation ??
              response?.errors[0]?.message,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });
  };

  const [
    checkout,
    {
      data: checkoutResponse,
      loading: checkouttIsLoading,
      error: checkoutError,
    },
  ] = useMutation(checkoutMutation, {
    onCompleted: (data) => {
      if (data?.checkout?.status === "success") {
        Swal.fire({
          title: "Success",
          text: data?.checkout?.message,
          timer: 3000,
          showConfirmButton: false,
          imageUrl: require("../../Assets/img/sucsses-icon.png"),
          imageAlt: "Image not found",
          customClass: {
            popup: "swal-success-style",
            container: "swal-title",
          },
        });

        // clear shipping address
        dispatch(clearShippingAddresses());

        // clear cart
        dispatch(clearCartAction());

        // show sales person id modal
        handleShowSalesPersonIdModal();

        // clear the session states
        dispatch(showSplitPaymentSectionAction(false));
        dispatch(showSplitItemsSectionAction(false));
        dispatch(showCashSectionAction("creditCardSection"));

        // handle the receipt option modal
        handleShowReceiptOptionsModal(
          data?.checkout?.order?.id,
          data?.checkout?.receipt
        );
      }
    },
  });
  // End of handle checkout mutation

  return (
    <>
      {/* Start of NavBar Component */}
      <Navbar itemsListIsOpened={true} />
      {/* End of NavBar Component */}

      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        className="station_store_name_wrapper_style"
      >
        <Link underline="hover" href="/store-fronts">
          Home
        </Link>
        <Link underline="hover" href="/store-fronts">
          Stores
        </Link>
        <Link underline="hover" href="/store-fronts">
          {stationInfo?.storeName} Retail POS
        </Link>
        <Link underline="hover" href="/store-fronts">
          Stations
        </Link>
        <Link underline="hover" href="/store-fronts">
          {stationInfo?.stationName}
        </Link>
        <Link underline="hover" href="/store-fronts">
          {validatedCart?.items[0].itemName}
        </Link>
        <Link color="text.primary">Checkout</Link>
      </Breadcrumbs>

      <div className="content_style">
        <div className="checkout_form_wrapper_style">
          <h4>Checkout</h4>

          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 mb-4 mb-lg-0">
              <Tabs value={tabValue} onChange={handleToggleTabsChange}>
                <Tab label="Existing customer" value="exiting_customer" />

                <Tab label="New customer" value="new_customer" />

                <Tab label="Guest" value="valued_customer" />
              </Tabs>

              <div className="checkout_content_wrapper_style">
                <div className="steps_content_wrapper_style">
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps?.map((step, index) => (
                      <Step key={step?.label}>
                        <StepLabel>
                          <div className="d-flex">
                            <div className="me-1">
                              {activeStep !== index && activeStep <= index ? (
                                <img src={step?.icon} />
                              ) : (
                                <img src={step?.activeIcon} />
                              )}
                            </div>

                            <div>{step?.label}</div>
                          </div>
                        </StepLabel>

                        <StepContent>
                          <div className="row stepper_content_wrapper_style">
                            <div className="col-12 p-0">
                              {renderActiveTab(index)}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>

                  {activeStep === steps?.length && (
                    <div className="payment_completed_wrapper_style mt-4">
                      <img
                        src={require("../../Assets/img/check-mark-icon.png")}
                      />

                      <p>All steps completed - you're finished</p>
                    </div>
                  )}
                </div>

                <div className="complete_process_btn_style">
                  {activeStep === steps?.length ? (
                    <Button
                      disabled={checkouttIsLoading}
                      className="active_complete_process_btn_style"
                      type="submit"
                      datatestid="complete_process_btn"
                      onClick={handleCheckout}
                    >
                      {checkouttIsLoading ? (
                        <Spinner size={"sm"} style={{ color: "#fff" }} />
                      ) : (
                        "Complete Process"
                      )}
                    </Button>
                  ) : (
                    <Button>Complete Process</Button>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-5 col-xl-4">
              <div className="d-flex justify-content-end">
                <Button
                  className="add_items_btn_style"
                  onClick={handleAddMoreItemsBtn}
                  datatestid="add_new_items_btn_from_checkout_page"
                >
                  Add new items
                  <img
                    className="ms-2"
                    src={require("../../Assets/img/add-icon.png")}
                  />
                </Button>
              </div>

              <div className="order_summary_content_wrapper_style">
                <CartStep
                  hideDiscountSections={hideDiscountSections}
                  setHideDiscountSections={setHideDiscountSections}
                  handleInitPayment={handleInitPayment}
                  initpaymentIsLoading={initpaymentIsLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start of confirm password modal */}
      {isConfirmPasswordModalVissible ? <ConfirmPasswordModal /> : null}
      {/* End of confirm password modal */}

      {/* Start of open drawer manually modal */}
      {isOpenDrawerManuallyModalVissible ? (
        <OpenDrawerManuallyModal stationId={+stationInfo?.stationId} />
      ) : null}
      {/* End of open drawer manually modal */}

      {/* Start of receipt options modal */}
      {isReceiptOptionsModalVissible ? (
        <ReceiptOptionsModal
          station_id={stationInfo?.stationId}
          station_name={stationInfo?.stationName}
          store_id={stationInfo?.storeId}
          storeName={stationInfo?.storeName}
        />
      ) : null}
      {/* End of receipt options modal */}
    </>
  );
};

export default CheckoutPage;
