import authTypes from "./authActions/types";
import stationTypes from "./stationActions/types";
import itemsTypes from "./itemsActions/types";
import checkoutTypes from "./checkoutActions/types";

const actionTypes = {
  ...authTypes,
  ...stationTypes,
  ...itemsTypes,
  ...checkoutTypes,
};

export default actionTypes;
