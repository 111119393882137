import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../ModalLayout";
import { hideStripeTerminalDisconnectedModalAction } from "../../Redux/Actions";
import { Button, Spinner } from "reactstrap";

const StripeTerminalDisconnectedModal = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // states from store
  const stripeTerminalDisconnectedModal = useSelector(
    (state) => state?.main?.station?.stripeTerminalDisconnectedModal
  );

  const isModalVissible = useSelector(
    (state) =>
      state?.main?.station?.stripeTerminalDisconnectedModalActions?.isVissible
  );

  // handle toggle modal
  const toggleModal = () => {
    dispatch(hideStripeTerminalDisconnectedModalAction());
  };

  // handle go back to pos
  const ExitPos = () => {
    // close sales person id modal
    toggleModal();

    navigate("/store-fronts");
  };

  return (
    <>
      <ModalLayout
        isOpen={isModalVissible}
        className="terminal_disconnect_modal_wrapper_style"
        centered
      >
        <div className="text-center">
          <img src={require("../../Assets/img/error-icon.png")} />
        </div>

        <div className="text-center">
          <label>Error</label>

          <p>{stripeTerminalDisconnectedModal?.modalMessage}</p>
        </div>

        <Button
          disabled={props?.terminalLoader}
          className="closeing_station_btn_style"
          onClick={() => props?.handleInitStripeTerminal()}
        >
          {props?.terminalLoader ? (
            <Spinner size={"sm"} style={{ color: "#fff" }} />
          ) : (
            "Reconnect"
          )}
        </Button>

        <Button
          className="closeing_station_btn_style go_to_pos_btn_style"
          onClick={ExitPos}
        >
          Exit POS
        </Button>
      </ModalLayout>
    </>
  );
};

export default StripeTerminalDisconnectedModal;
