import { useEffect } from "react";
import {
  calculateTotalItemPriceAction,
  onCartInputIncrementAction,
  onCartInputDecrementAction,
} from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

const CartIncDecInput = (props) => {
  const dispatch = useDispatch();

  // Start of increment decrement quantity number function
  const incQuantityeNum = () => {
    if (
      +props?.quantity < props?.quanity_limit ||
      props?.quanity_limit === null
    ) {
      dispatch(onCartInputIncrementAction("quantity", props?.index));
    }
  };

  const decQuantityNum = () => {
    if (
      +props?.quantity > props?.quanity_limit ||
      props?.quanity_limit !== null
    ) {
      if (props?.quantity > 1) {
        dispatch(onCartInputDecrementAction("quantity", props?.index));
      }
    }
  };
  // End of increment decrement quantity number function

  // handle add quantity and item price to total price
  useEffect(() => {
    dispatch(
      calculateTotalItemPriceAction(
        +props?.price * +props?.quantity,
        "itemTotalPrice",
        props?.index
      )
    );
  }, [props?.price, props?.quantity]);

  // handle add quantity and item sales taxses to total sales taxses
  useEffect(() => {
    dispatch(
      calculateTotalItemPriceAction(
        +props?.salesTaxses * +props?.quantity,
        "itemTotalSalesTaxses",
        props?.index
      )
    );
  }, [props?.quantity]);

  return (
    <div className="input_Increment_decrement_wrapper_style">
      <div className="d-flex align-items-center me-1 number_input_wrapper_style">
        <IndeterminateCheckBoxIcon
          datatestid={`dec-item-quantity-btn-${props?.index}`}
          onClick={decQuantityNum}
        />

        <input
          type="number"
          onWheel={(e) => e.target.blur()}
          value={props?.quantity}
          datatestid={`item-quantity-input-${props?.index}`}
        />

        <AddBoxIcon
          datatestid={`inc-item-quantity-btn-${props?.index}`}
          onClick={incQuantityeNum}
        />
      </div>
    </div>
  );
};

export default CartIncDecInput;
