import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../ModalLayout";
import { hideSalesPersonIdModalAction } from "../../Redux/Actions";
import { Button, Spinner } from "reactstrap";
import { Alert, TextField } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { validateSalesPersonIdQuery } from "../../graphql/queries";

const SalesPersonIdModal = (props) => {
  const dispatch = useDispatch();

  // states from store
  const isModalVissible = useSelector(
    (state) => state?.main?.items?.salesPersonIdModalActions?.isVissible
  );
  const salesPersonIdModal = useSelector(
    (state) => state?.main?.items?.salesPersonIdModal
  );

  const stripeTerminalInfo = useSelector(
    (state) => state?.main?.station?.stripeTerminalInfo
  );

  // modal error message useState
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  // submit useState
  const [isSubmitting, setIsSubmitting] = useState(false);

  // inputs useStates
  const [salesPersonIdValue, setSalesPersonIdValue] = useState("");

  const handleSubmitBtn = () => {
    handleValidateSalesPersonId({
      variables: {
        sales_person_id: +salesPersonIdValue,
      },
    });

    setModalErrorMessage("");
    setIsSubmitting(true);
  };

  // handle toggle modal
  const toggleModal = () => {
    dispatch(hideSalesPersonIdModalAction());
  };

  // handle inputs change
  const handleInputChange = (e) => {
    setSalesPersonIdValue(e?.target?.value);
  };

  // Start of handle validate sales person id query
  const [
    handleValidateSalesPersonId,
    {
      data: salesPersonValidationResponse,
      loading: isValidationingSalesPersonIdLoading,
      error: salesPersonValidationError,
    },
  ] = useLazyQuery(validateSalesPersonIdQuery, {
    onError(err) {
      // handle error message
      setModalErrorMessage(
        err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message
      );

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);

      if (data?.validateSalesPersonId?.status == "1") {
        if (props?.isStripeTerminalActivated && !stripeTerminalInfo?.terminal) {
          // trigger connect to stripe terminal function
          props?.handleInitStripeTerminal();
        } else {
          // close sales person id modal
          toggleModal();
        }
      } else {
        // handle error message
        setModalErrorMessage(data?.validateSalesPersonId?.message);
      }
    },
  });
  // End of handle validate sales person id query

  // handle auto focus for input
  const inputElement = document.getElementById("salesPersonIdInputElement");
  inputElement?.focus();

  useEffect(() => {
    setIsSubmitting(false);
    setModalErrorMessage("");
    setSalesPersonIdValue("");
    return () => {};
  }, [isModalVissible]);

  return (
    <>
      <ModalLayout
        isOpen={isModalVissible}
        className="sales_person_id_modal_wrapper_style"
        centered
      >
        <form>
          <div className="row mb-3">
            <div className="col-12">
              <h2 className="mb-2">Enter Your ID For Go POS</h2>

              <TextField
                fullWidth
                onWheel={(e) => e.target.blur()}
                placeholder="Enter ID"
                className="custom_input_style"
                id="salesPersonIdInputElement"
                type="number"
                name="sales_person_id"
                datatestid="sales_person_id_input"
                value={salesPersonIdValue}
                onChange={handleInputChange}
                label="Cashier ID"
                variant="outlined"
              />
            </div>
          </div>

          <Button
            disabled={
              isValidationingSalesPersonIdLoading || props?.terminalLoader
            }
            className="closeing_station_btn_style"
            datatestid="validate_sales_person_id_btn"
            type="submit"
            onClick={handleSubmitBtn}
          >
            {isValidationingSalesPersonIdLoading || props?.terminalLoader ? (
              <Spinner size={"sm"} style={{ color: "#fff" }} />
            ) : (
              "Continue"
            )}
          </Button>
        </form>

        {/* (Start) Error Message */}
        {modalErrorMessage && isSubmitting && (
          <Alert severity="error" className="mt-2">
            {modalErrorMessage}
          </Alert>
        )}
        {/* (End) Error Message */}
      </ModalLayout>
    </>
  );
};

export default SalesPersonIdModal;
