import React, { useEffect } from "react";
import HelperFns from "../../../Helpers/HelperFns";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

const IncrementDecrementInput = ({
  max = Infinity,
  min = 0,
  value,
  ...props
}) => {
  useEffect(() => {
    let newValue = HelperFns.clamp(Number(value), max, min);
    props?.onQuantityChange(newValue, props?.index);
  }, [max, min]);

  // Start of increment decrement input number function
  const incNum = () => {
    let newValue = HelperFns.clamp(Number(value || 0) + 1, max, min);
    props?.onQuantityChange(newValue, props?.index);
  };

  const decNum = () => {
    let newValue = HelperFns.clamp(Number(value || 0) - 1, max, min);
    props?.onQuantityChange(newValue, props?.index);
  };

  const handleIncrementDecrementInputChange = (e) => {
    let newValue = HelperFns.clamp(Number(e?.target?.value), max, min);
    props?.onQuantityChange(newValue, props?.index);
  };
  // End of increment decrement input number function

  return (
    <div className="input_Increment_decrement_wrapper_style">
      <div className="d-flex align-items-center me-1 number_input_wrapper_style">
        <IndeterminateCheckBoxIcon onClick={decNum} />

        <input
          type="number"
          className="mb-0 px-2 py-1 text-center"
          name={props?.name}
          value={value}
          onWheel={(e) => e.target.blur()}
          onChange={handleIncrementDecrementInputChange}
          max={max}
          min={min}
          {...props}
        />

        <AddBoxIcon onClick={incNum} />
      </div>
    </div>
  );
};

export default IncrementDecrementInput;
