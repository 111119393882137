// handle sales person id modal types
const SHOW_SALES_PERSON_ID_MODAL = "SHOW_SALES_PERSON_ID_MODAL";
const HIDE_SALES_PERSON_ID_MODAL = "HIDE_SALES_PERSON_ID_MODAL";

// handle open drawer manually modal types
const SHOW_OPEN_DRAWER_MANUALLY_MODAL = "SHOW_OPEN_DRAWER_MANUALLY_MODAL";
const HIDE_OPEN_DRAWER_MANUALLY_MODAL = "HIDE_OPEN_DRAWER_MANUALLY_MODAL";

// handle item combination types
const SHOW_ITEMS_COMBINATION = "SHOW_ITEMS_COMBINATION";
const HIDE_ITEMS_COMBINATION = "HIDE_ITEMS_COMBINATION";
const FETCHED_ITEM_COMBINATION = "FETCHED_ITEM_COMBINATION";
const ADD_CUSTOM_ITEM_COMBINATION = "ADD_CUSTOM_ITEM_COMBINATION";
const ON_ITEM_COMBINATION_CHANGE = "ON_ITEM_COMBINATION_CHANGE";
const REMOVE_ITEM_COMBINATION = "REMOVE_ITEM_COMBINATION";
const ON_CUSTOM_COMBINATION_CHANGE = "ON_CUSTOM_COMBINATION_CHANGE";

// handle cart types
const ADD_TO_CART = "ADD_TO_CART";
const CLEAR_CART = "CLEAR_CART";
const ON_CART_INPUT_INCREMENT = "ON_CART_INPUT_INCREMENT";
const ON_CART_INPUT_DECREMENT = "ON_CART_INPUT_DECREMENT";
const ADD_ITEM_PRICE_AND_QUANTITY_TO_CART_TOTAL =
  "ADD_ITEM_PRICE_AND_QUANTITY_TO_CART_TOTAL";
const RESET_CART_TOTAL = "RESET_CART_TOTAL";
const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";

// handle return items types
const STORE_RETURN_ORDER_INFO = "STORE_RETURN_ORDER_INFO";
const CLEAR_RETURNED_ITEMS_CART = "CLEAR_RETURNED_ITEMS_CART";
const ADD_TO_RETURNED_ITEMS_CART = "ADD_TO_RETURNED_ITEMS_CART";
const REMOVE_RETURNED_CART_ITEM = "REMOVE_RETURNED_CART_ITEM";
const ON_RETURNED_ITEMS_CART_INPUT_INCREMENT =
  "ON_RETURNED_ITEMS_CART_INPUT_INCREMENT";
const ON_RETURNED_ITEMS_CART_INPUT_DECREMENT =
  "ON_RETURNED_ITEMS_CART_INPUT_DECREMENT";
const ON_RETURNED_ITEMS_INFO_INPUT_INCREMENT =
  "ON_RETURNED_ITEMS_INFO_INPUT_INCREMENT";
const ON_RETURNED_ITEMS_INFO_INPUT_DECREMENT =
  "ON_RETURNED_ITEMS_INFO_INPUT_DECREMENT";

export default {
  // handle sales person id modal types
  SHOW_SALES_PERSON_ID_MODAL,
  HIDE_SALES_PERSON_ID_MODAL,

  // handle open drawer manually modal types
  SHOW_OPEN_DRAWER_MANUALLY_MODAL,
  HIDE_OPEN_DRAWER_MANUALLY_MODAL,

  // handle item combination types
  SHOW_ITEMS_COMBINATION,
  HIDE_ITEMS_COMBINATION,
  FETCHED_ITEM_COMBINATION,
  ADD_CUSTOM_ITEM_COMBINATION,
  ON_ITEM_COMBINATION_CHANGE,
  REMOVE_ITEM_COMBINATION,
  ON_CUSTOM_COMBINATION_CHANGE,

  // handle cart types
  ADD_TO_CART,
  CLEAR_CART,
  ON_CART_INPUT_INCREMENT,
  ON_CART_INPUT_DECREMENT,
  ADD_ITEM_PRICE_AND_QUANTITY_TO_CART_TOTAL,
  RESET_CART_TOTAL,
  REMOVE_CART_ITEM,

  // handle return items types
  STORE_RETURN_ORDER_INFO,
  CLEAR_RETURNED_ITEMS_CART,
  ADD_TO_RETURNED_ITEMS_CART,
  REMOVE_RETURNED_CART_ITEM,
  ON_RETURNED_ITEMS_CART_INPUT_INCREMENT,
  ON_RETURNED_ITEMS_CART_INPUT_DECREMENT,
  ON_RETURNED_ITEMS_INFO_INPUT_INCREMENT,
  ON_RETURNED_ITEMS_INFO_INPUT_DECREMENT,
};
