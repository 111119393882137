import React, { useState } from "react";
import SelectForm from "../FormComponents/SelectForm";
import HelperFns from "../../Helpers/HelperFns";
import { components } from "react-select";
import ItemCustomAttributes from "./ItemCustomAttributes";
import ItemConstants from "../../Helpers/Constants/ItemConstants";
import {
  Alert,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import _ from "lodash";

const ItemForm = ({ item, itemCombo, onChange, validation = [] }) => {
  const handleAvailableCompoChange = (value, e) => {
    let updatedCombo = { ...itemCombo };
    let custom_attributes = itemCombo?.custom_attributes?.map((itemAttr) =>
      itemAttr?.options?.length > 1 ||
      itemAttr?.attr_type == ItemConstants.ITEM_ATTRIBUTE_TYPE_TEXT
        ? { ...itemAttr, attributeValue: "", attributeName: "" }
        : itemAttr
    );
    if (value) {
      updatedCombo = {
        ...itemCombo,
        attribute_option_data: value,
        custom_attributes,
        isCustom: false,
      };
      onChange(updatedCombo);
    } else {
      updatedCombo = {
        ...itemCombo,
        custom_attributes,
        attribute_option_data: null,
      };
      onChange(updatedCombo);
    }
  };

  const handleResetItemComboAttributes = () => {
    let custom_attributes = itemCombo?.custom_attributes?.map((itemAttr) =>
      itemAttr?.options?.length > 1 ||
      itemAttr?.attr_type == ItemConstants.ITEM_ATTRIBUTE_TYPE_TEXT
        ? { ...itemAttr, attributeValue: "", attributeName: "" }
        : itemAttr
    );
    let updatedCombo = { ...itemCombo, custom_attributes, isCustom: false };
    onChange(updatedCombo);
  };

  const handleItemAttributeChange = (updatedAttribute) => {
    let updatedCustomAttributes = itemCombo?.custom_attributes?.map((attr) =>
      attr?.attr_id == updatedAttribute?.attr_id ? updatedAttribute : attr
    );
    let updatedCombo = {
      ...itemCombo,
      custom_attributes: updatedCustomAttributes,
    };
    onChange(updatedCombo);
  };

  const OnUseCustomAttributes = (updatedAttributes) => {
    let updatedCombo = {
      ...itemCombo,
      custom_attributes: updatedAttributes,
      attribute_option_data: null,
      isCustom: true,
    };
    onChange(updatedCombo);
  };

  const handleAddCustomCombination = (e, opt) => {
    let updatedItemAttributes = _.cloneDeep(itemCombo?.custom_attributes);
    OnUseCustomAttributes(updatedItemAttributes);
  };

  // tabs use states
  const [tabValue, setTabValue] = useState(
    itemCombo?.hasActiveCompo
      ? "stock-combination"
      : item?.item?.is_backorder && itemCombo?.hasCustomAttributes
      ? "custom-combination"
      : ""
  );

  const handleToggleTabsChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {/* Render Item Image if child of kit */}
      {item?.item?.item_type !== "Simple" ? (
        <div
          className={
            validation?.find((item) => item?.id == itemCombo?.id)
              ? "kits_wrapper_style kit_error_wrapper_style"
              : "kits_wrapper_style"
          }
        >
          <Accordion>
            <AccordionSummary expandIcon={<ArrowDropDown />}>
              <div className="d-flex align-items-center">
                <img
                  src={itemCombo?.image_url?.small}
                  onError={(e) => HelperFns.addDefaultSrc(e)}
                  alt="Image Not Found"
                />

                <h4 className="mb-0">{itemCombo?.name}</h4>
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <Tabs value={tabValue} onChange={handleToggleTabsChange}>
                {itemCombo?.hasActiveCompo ? (
                  <Tab
                    label="Choose from stock"
                    value="stock-combination"
                    onClick={handleResetItemComboAttributes}
                  />
                ) : null}

                {item?.item?.is_backorder && itemCombo?.hasCustomAttributes ? (
                  <Tab
                    label="Choose Custom Combination"
                    value="custom-combination"
                    onClick={handleAddCustomCombination}
                  />
                ) : null}
              </Tabs>

              {tabValue === "stock-combination" ? (
                <>
                  {/* Render available combinations  */}
                  {itemCombo?.hasActiveCompo ? (
                    <>
                      <div
                        className={
                          validation?.find(
                            (item) =>
                              item?.id == itemCombo?.id &&
                              item?.attrId == "ACTIVE_COMBINATION"
                          )
                            ? "custom_select_wrapper_style error_msg_style"
                            : "custom_select_wrapper_style"
                        }
                      >
                        <label>In-Stock Combinations</label>
                        <SelectForm
                          name="availableCompo"
                          selectOptions={itemCombo?.available_combinations}
                          getOptionLabel={(opt) =>
                            `${opt.parsed_attribute_option_data?.join(
                              " "
                            )} - Available: ${opt.quantity}`
                          }
                          getOptionValue={(opt) => opt?.attribute_option_data}
                          onChange={handleAvailableCompoChange}
                          value={itemCombo?.attribute_option_data}
                          isClearable
                          openMenuOnClick={
                            !!!itemCombo?.attribute_option_data &&
                            !itemCombo?.isCustom
                          }
                          components={{
                            DropdownIndicator: (props) =>
                              !props?.hasValue && !itemCombo?.isCustom ? (
                                <components.DropdownIndicator {...props} />
                              ) : null,
                          }}
                        />
                      </div>

                      {validation?.find(
                        (item) =>
                          item?.id == itemCombo?.id &&
                          item?.attrId == "ACTIVE_COMBINATION"
                      ) ? (
                        <Alert severity="error" className="mt-2">
                          Select combination is required
                        </Alert>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}

              {tabValue === "custom-combination" ? (
                <>
                  {/* Render Custom attributes */}
                  {item?.item?.is_backorder &&
                  itemCombo?.hasCustomAttributes ? (
                    <ItemCustomAttributes
                      item_attributes={itemCombo?.custom_attributes}
                      itemCombo={itemCombo}
                      onItemAttrbuteChange={handleItemAttributeChange}
                      onResetItemAttrs={handleResetItemComboAttributes}
                      itemId={itemCombo?.id}
                      validation={validation}
                      itemType={item?.item?.item_type}
                      disabled={
                        !itemCombo?.isCustom && itemCombo?.attribute_option_data
                      }
                    />
                  ) : null}
                </>
              ) : null}
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <>
          <Tabs value={tabValue} onChange={handleToggleTabsChange}>
            {itemCombo?.hasActiveCompo ? (
              <Tab
                label="Choose from stock"
                value="stock-combination"
                onClick={handleResetItemComboAttributes}
              />
            ) : null}

            {item?.item?.is_backorder && itemCombo?.hasCustomAttributes ? (
              <Tab
                label="Choose Custom Combination"
                value="custom-combination"
                onClick={handleAddCustomCombination}
              />
            ) : null}
          </Tabs>

          {tabValue === "stock-combination" ? (
            <>
              {/* Render available combinations  */}
              {itemCombo?.hasActiveCompo ? (
                <>
                  <div
                    className={
                      validation?.find(
                        (item) =>
                          item?.id == itemCombo?.id &&
                          item?.attrId == "ACTIVE_COMBINATION"
                      )
                        ? "custom_select_wrapper_style error_msg_style"
                        : "custom_select_wrapper_style"
                    }
                  >
                    <label>In-Stock Combinations</label>
                    <SelectForm
                      name="availableCompo"
                      selectOptions={itemCombo?.available_combinations}
                      getOptionLabel={(opt) =>
                        `${opt.parsed_attribute_option_data?.join(
                          " "
                        )} - Available: ${opt.quantity}`
                      }
                      getOptionValue={(opt) => opt?.attribute_option_data}
                      onChange={handleAvailableCompoChange}
                      value={itemCombo?.attribute_option_data}
                      isClearable
                      openMenuOnClick={
                        !!!itemCombo?.attribute_option_data &&
                        !itemCombo?.isCustom
                      }
                      components={{
                        DropdownIndicator: (props) =>
                          !props?.hasValue && !itemCombo?.isCustom ? (
                            <components.DropdownIndicator {...props} />
                          ) : null,
                      }}
                    />
                  </div>

                  {validation?.find(
                    (item) =>
                      item?.id == itemCombo?.id &&
                      item?.attrId == "ACTIVE_COMBINATION"
                  ) ? (
                    <Alert severity="error" className="mt-2">
                      Select combination is required
                    </Alert>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}

          {tabValue === "custom-combination" ? (
            <>
              {/* Render Custom attributes */}
              {item?.item?.is_backorder && itemCombo?.hasCustomAttributes ? (
                <ItemCustomAttributes
                  item_attributes={itemCombo?.custom_attributes}
                  itemCombo={itemCombo}
                  onItemAttrbuteChange={handleItemAttributeChange}
                  onResetItemAttrs={handleResetItemComboAttributes}
                  itemId={itemCombo?.id}
                  validation={validation}
                  itemType={item?.item?.item_type}
                  disabled={
                    !itemCombo?.isCustom && itemCombo?.attribute_option_data
                  }
                />
              ) : null}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default ItemForm;
