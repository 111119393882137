import { authReducer } from "./AuthReducer";
import { combineReducers } from "redux";
import { stationReducer } from "./StationReducer";
import { itemsReducer } from "./ItemsReducer";
import { checkoutReducer } from "./CheckoutReducer";

export default combineReducers({
  auth: authReducer,
  station: stationReducer,
  items: itemsReducer,
  checkout: checkoutReducer,
});
