import Types from "../types";

// Start of login/logout action
export const loginAction = (token, data) => ({
  type: Types.LOGIN,
  payload: { token, data },
});

export const setToken = (token) => ({
  type: Types.SET_TOKEN,
  payload: token,
});

export const logoutAction = () => ({
  type: Types.LOGOUT,
});
// End of login/logout action

// Start of show and hide of confirm password modal action
export const showConfirmPasswordModalAction = (data) => ({
  type: Types.SHOW_CONFIRM_PASSWORD_MODAL,
  payload: data,
});

export const hideConfirmPasswordModalAction = () => ({
  type: Types.HIDE_CONFIRM_PASSWORD_MODAL,
});
// End of show and hide of confirm password modal action
