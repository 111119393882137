import React from "react";
import { Modal, ModalBody } from "reactstrap";

const ModalLayout = ({ children, modalTitle = "", toggle, ...props }) => {
  return (
    <div>
      <Modal fade={false} {...props}>
        {modalTitle && (
          <div className="modal_header_style">
            <h2 className="mb-0">{modalTitle}</h2>

            {toggle ? (
              <img
                onClick={toggle}
                className="cursor-pointer modal_close_btn_style"
                src={require("../../Assets/img/close-icon.png")}
              />
            ) : null}
          </div>
        )}

        <ModalBody>{children}</ModalBody>
      </Modal>
    </div>
  );
};

export default ModalLayout;
