import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Backdrop, Alert } from "@mui/material";
import {
  hideItemCombinationAction,
  addToCartAction,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { Button, Badge } from "reactstrap";
import { useLazyQuery, useMutation } from "@apollo/client";
import { fetchItemCombinationQuery } from "../../graphql/queries";
import { salesTaxesMutation } from "../../graphql/mutations";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import HelperFns from "../../Helpers/HelperFns";
import IncrementDecrementInput from "../FormComponents/IncrementDecrementInput";
import ItemForm from "./ItemForm";
import DOMPurify from "dompurify";
import _ from "lodash";
import { v4 as uuid } from "uuid";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ItemCombination = (props) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // states from store
  const itemsCombinations = useSelector(
    (state) => state?.main?.items?.itemsCombinations
  );

  const [item, setItem] = useState({
    item: {},
    itemCombinations: [],
    kitChildren: [],
    price: "",
    quantity: 1,
  });

  // handle get back to station
  const handleBackToStation = () => {
    navigate(
      `/products?station_id=${itemsCombinations?.stationId}&station_name=${itemsCombinations?.stationName}&store_id=${itemsCombinations?.storeId}&store_name=${itemsCombinations?.storeName}`,
      { state: { from: "products-details" } }
    );

    dispatch(hideItemCombinationAction());
  };

  // init useSatates
  const [itemCombinationErrorMessage, setItemCombinationErrorMessage] =
    useState("");

  // Start of handle item sales taxses mutation
  let timer;

  const handleItemSalesTaxses = () => {
    fetchItemSalesTaxses({
      variables: {
        item_id: +itemsCombinations?.item_id,
        store_id: +itemsCombinations?.storeId,
        station_id: +itemsCombinations?.stationId,
      },
    })
      .then((response) => {
        if (response?.errors) {
          Swal.fire({
            title: "Error",
            text:
              response?.graphQLErrors[0]?.extensions?.reason ??
              response?.graphQLErrors[0]?.message,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });

          // refetch agian in set of time interval
          timer = setInterval(() => {
            // fetch item sales taxses
            handleItemSalesTaxses();
          }, 1000);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error?.message ?? "Something went wrong",
          timer: 3000,
          showConfirmButton: false,
          imageUrl: require("../../Assets/img/error-icon.png"),
          imageAlt: "Image not found",
          customClass: {
            popup: "swal-error-style",
            container: "swal-title",
          },
        });

        // refetch agian in set of time interval
        timer = setInterval(() => {
          // fetch item sales taxses
          handleItemSalesTaxses();
        }, 1000);

        console.log("catch", { error });
      });
  };

  const [
    fetchItemSalesTaxses,
    {
      data: salesTaxsesResponse,
      loading: isSalesTaxsesLoading,
      error: salesTaxsesError,
    },
  ] = useMutation(salesTaxesMutation, {
    onCompleted: (data) => {
      // clear the timer
      clearInterval(timer);

      console.log("onCompleted", data?.salesTaxes?.value);
    },
  });

  console.log({ salesTaxsesResponse });

  // End of handle item sales taxses mutation

  // Start of handle fetch item combination query
  const handleFetchItemCombination = () => {
    handleItemSalesTaxses();
    fetchItemCombination({
      variables: {
        item_id: +itemsCombinations?.item_id,
        storeId: +itemsCombinations?.storeId,
        stationId: +itemsCombinations?.stationId,
      },
    });
  };

  const [
    fetchItemCombination,
    {
      data: itemCombinationDataResponse,
      loading: isFetchItemCombinationLoading,
      error: itemCombinationError,
    },
  ] = useLazyQuery(fetchItemCombinationQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      // return to station page
      handleBackToStation();

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      setItem((prevState) => ({
        ...prevState,
        item: { ...itemsCombinations },
        kitChildren: data?.getItemCombinations?.filter(
          (combo) => combo?.item_type === "Kit"
        ),
        itemCombinations: HelperFns.getItemCombinations(
          data?.getItemCombinations,
          itemsCombinations
        ),
        price: itemsCombinations?.price,
      }));
      // fetch item sales taxses
      // handleItemSalesTaxses();

      // console.log("onCompleted", data);
    },
  });

  console.log({ item });

  // End of handle fetch item combination query

  const [validationItem, setValidationItem] = useState([]);
  const [isValid, setIsValid] = useState(false);

  const validateAddToCart = () => {
    let updatedValidations = [];
    item?.itemCombinations.forEach((combo) => {
      if (!combo?.isCustom) {
        if (
          combo?.attribute_option_data == null &&
          combo?.attribute_option_data == undefined
        ) {
          updatedValidations.push({
            id: combo?.id,
            attrId: "ACTIVE_COMBINATION",
          });
        }
      } else {
        combo?.custom_attributes?.forEach((attr) => {
          if (!!!attr?.attributeValue) {
            updatedValidations.push({
              id: combo?.id,
              attrId: attr?.attr_id,
            });
          }
        });
      }
    });
    setValidationItem(updatedValidations);
    if (updatedValidations?.length) {
      return false;
    } else {
      return true;
    }
  };

  // handle add to cart
  const serializeAddToCart = () => {
    let itemCart = {};
    console.log(item);
    let parents = [];

    if (item?.item?.item_type === "Kit") {
      itemCart = {
        id: `kitItem-${item?.item?.item_id}`,
        parentCartItemId: null,
        itemId: +item?.item?.item_id,
        itemName: item?.item?.name,
        attributeOptionData: null,
        quantity: item?.quantity,
        customAttributes: [],
        isBackorder: null,
        price: {
          amount: +item?.price,
          discountAmount: 0,
        },
        tax: {
          amount: +salesTaxsesResponse?.salesTaxes?.value,
          discountAmount: 0,
        },
      };
    }

    let itemsCart = item?.itemCombinations?.map((combo) => ({
      id: combo?.id,
      attributeOptionData:
        combo?.attribute_option_data?.attribute_option_data || null,
      attributeOptionName: combo?.attribute_option_data?.attribute_option_data
        ? combo?.attribute_option_data?.parsed_attribute_option_data?.toString()
        : combo?.isCustom
        ? combo?.custom_attributes
            ?.map(
              (attr) =>
                `${attr?.attr_name}:${
                  attr?.attributeName || attr?.attributeValue
                }`
            )
            ?.toString()
        : null,
      isBackorder: combo?.isCustom ? true : false,
      itemId: +combo?.itemId,
      itemName: combo?.name,
      parentCartItemId: combo?.parentCartItemId,
      price: {
        amount: +item?.price,
        discountAmount: 0,
      },
      tax: {
        amount: +salesTaxsesResponse?.salesTaxes?.value,
        discountAmount: 0,
      },
      quantity: item?.quantity,
      customAttributes: combo?.isCustom
        ? combo?.custom_attributes?.map((attr) => ({
            attributeId: +attr?.attributeId,
            attributeValue: `${attr?.attributeValue}`,
          }))
        : [],
    }));

    return [itemCart, ...itemsCart];

    // console.log({ O: Item });
  };

  const handleAddToCart = () => {
    // reset error message
    setItemCombinationErrorMessage("");
    if (!validateAddToCart()) {
      console.log("notValid");
      return;
    } else {
      // let item = serializeAddToCart();
      let cart = {
        itemDetails: {
          price: item?.price,
          quantity: item?.quantity,
          tax: salesTaxsesResponse?.salesTaxes?.value,
          maxQuantity: maxQuantity(),
          name: item?.item?.name,
          image_url: item?.item?.image_url,
          id: uuid(),
          item_type: item?.item?.item_type,
          weight: item?.item?.weight,
          weight_unit: item?.item?.weight_unit,
          // to filter data array from any empty objects
          data: serializeAddToCart().filter(
            (value) => Object.keys(value).length !== 0
          ),
        },
      };

      // add cart data
      dispatch(addToCartAction(cart));

      // return to station page
      handleBackToStation();

      console.log(cart);

      return;
      console.log("valid");
      console.log("====================================");
      console.log({ final: item });

      console.log("====================================");
    }
  };

  console.log("====================================");
  console.log({ validationItem });
  console.log("====================================");

  useEffect(() => {
    // fetch items combination
    handleFetchItemCombination();

    // reset error message
    setItemCombinationErrorMessage("");
    return () => {};
  }, []);

  const handleItemChange = (e) => {
    setItem((prevState) => ({
      ...prevState,
      itemCombinations: prevState?.itemCombinations?.map((combo) =>
        combo?.id == e.id ? e : combo
      ),
    }));
  };
  const handleQuantityChange = (quantity) => {
    setItem((prevState) => ({
      ...prevState,
      quantity,
    }));
  };

  const maxQuantity = () => {
    let maxAvailable = item?.itemCombinations?.map((combo) =>
      combo?.isCustom
        ? Infinity
        : combo?.attribute_option_data?.quantity || Infinity
    );
    return Math.min(...maxAvailable);
  };

  return (
    <>
      {isFetchItemCombinationLoading || isSalesTaxsesLoading ? (
        <Backdrop
          sx={{
            backgroundColor: "rgba(80, 104, 136, 0.44)",
            zIndex: 1,
          }}
          open={isFetchItemCombinationLoading || isSalesTaxsesLoading}
        >
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <CircularProgress size={112} />
          </Box>
        </Backdrop>
      ) : (
        <div className="items_combination_wrapper_style">
          <div className="row">
            <div className="col-12 col-lg-5">
              {item?.item?.item_type?.toLowerCase() === "kit" ? (
                <div className="product_detials_img_wrapper_style kit_item_case_style">
                  <ImageGallery
                    items={[
                      {
                        original: item?.item?.image_url,
                        thumbnail: item?.item?.image_url,
                        originalHeight: 499,
                        thumbnailHeight: 76,
                      },
                      ...item?.itemCombinations?.map((itemCombo) => ({
                        original: itemCombo?.image_url?.small,
                        thumbnail: itemCombo?.image_url?.small,
                        originalHeight: 499,
                        thumbnailHeight: 76,
                      })),
                    ]}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    onImageError={(e) => HelperFns.addDefaultSrc(e)}
                    onThumbnailError={(e) => HelperFns.addDefaultSrc(e)}
                  />

                  {item?.item?.is_backorder ? (
                    <Badge className="backorder_badge_style" pill>
                      <p className="mb-0">
                        Available on <b>Backorder</b>
                      </p>
                    </Badge>
                  ) : null}
                </div>
              ) : (
                <div className="product_detials_img_wrapper_style simple_item_case_style">
                  <img
                    src={item?.item?.image_url}
                    onError={(e) => HelperFns.addDefaultSrc(e)}
                    alt="Image Not Found"
                  />

                  {item?.item?.is_backorder ? (
                    <Badge className="backorder_badge_style" pill>
                      <p className="mb-0">
                        Available on <b>Backorder</b>
                      </p>
                    </Badge>
                  ) : null}
                </div>
              )}

              <div className="product_description_style">
                {item?.item?.description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        item?.item?.description?.replace(/\n/g, "<br/>")
                      ),
                    }}
                  />
                ) : null}
              </div>
            </div>

            <div className="col-12 col-lg-7">
              <div className="product_details_wrapper_style">
                <div className="product_wrapper_scroll_style">
                  <div className="product_type_style">
                    {item?.item?.item_type} Item
                  </div>

                  <h2>{item?.item?.name}</h2>

                  <div className="price_badge_style">
                    ${item?.item?.price?.toFixed(2)}
                  </div>

                  <div className="product_quanitity_wrapper_style">
                    <label className="">Quantity</label>

                    <IncrementDecrementInput
                      type="number"
                      name="custom_quantity"
                      value={item?.quantity}
                      onQuantityChange={handleQuantityChange}
                      min={0}
                      max={maxQuantity()}
                    />
                  </div>

                  <div className="combinations_wrapper_style">
                    <h3>Choose items by in stock or customize order</h3>

                    {item?.itemCombinations?.map((itemCombo) => (
                      <ItemForm
                        validation={validationItem}
                        onChange={handleItemChange}
                        key={itemCombo?.id}
                        item={item}
                        itemCombo={itemCombo}
                      />
                    ))}
                  </div>
                </div>

                <div className="product_detials_btns_wrapper_style">
                  <Button
                    className="back_to_station_btn_style"
                    onClick={handleBackToStation}
                  >
                    Back to station
                  </Button>

                  <Button
                    className="add_to_cart_btn_style"
                    onClick={handleAddToCart}
                  >
                    Add to cart ({item?.quantity})
                    <img
                      className="ms-2"
                      src={require("../../Assets/img/add-to-cart-icon.png")}
                    />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {/* (Start) Error Message */}
          {itemCombinationErrorMessage && (
            <Alert severity="error" className="mt-2">
              {itemCombinationErrorMessage}
            </Alert>
          )}
          {/* (End) Error Message */}
        </div>
      )}
    </>
  );
};

export default ItemCombination;
