import React, { useEffect } from "react";
import {useLazyQuery, useMutation} from "@apollo/client";
import { loginMutation } from "../../graphql/mutations";
import {loginAction, setToken} from "../../Redux/Actions";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {getUserQuery} from "../../graphql/queries";
import { Config } from "../../Config";

const LoginPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // Start of handle login mutation
  const handleLogin = () => {
    // Get Token from queryString
    const token = getTokenFromQueryString();

    if (!token) {
      return getTokenThroughAiobo();
    }

    // Set the Token
    dispatch(setToken(token))

    // Fetch User
    getUser()
      .then(response => {
        // Set Login
        const user = response.data.me;

        const error = response.error

        if (error) {
          const message = error.graphQLErrors[0]?.extensions?.reason ?? error.graphQLErrors[0]?.message

          return showError(message)
        }

        dispatch(loginAction(token, user))

        navigate(`/store-fronts`);
      })
      .catch(error => {
        const message = error.message ?? 'Something went wrong!';

        return showError(message)
      });
  };


  const [getUser, { loading: isUserLoading }] = useLazyQuery(getUserQuery)

  const getTokenFromQueryString = () => {
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);

    return urlParams.get("token");
  };

  // Redirect to aiobo and login then redirect back
  const getTokenThroughAiobo = () => {
    window.location.href = Config.AIOBO_REDIRECT_URL;
  }

  const showError = (message) => {
    Swal.fire({
      title: "Error",
      text: message,
      timer: 3000,
      showConfirmButton: false,
      imageUrl: require("../../Assets/img/error-icon.png"),
      imageAlt: "Image not found",
      customClass: {
        popup: "swal-error-style",
        container: "swal-title",
      },
    });
  }

  // End of handle login mutation

  useEffect(() => {
    // handle login
    handleLogin();

    return () => {};
  }, []);

  return (
    <div className="login_wrapper_style">
      {isUserLoading ? (
        <div>
          <img
            src={require("../../Assets/img/login-loader.gif")}
            width="600"
            alt="Image Not Found"
          />
        </div>
      ) : null}
    </div>
  );
};

export default LoginPage;
