import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Config } from "../Config";
import Store from "../Redux/Store";

const httpLink = createHttpLink({
  uri: `${Config.APP_API_ENDPOINT}`,
});

const authLink = setContext((_, { headers }) => {
  // states from store
  const { token } = Store.getState().main.auth;

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),

  cache: new InMemoryCache({
    addTypename: false
  }),

  connectToDevTools: false,
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    mutate: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});
