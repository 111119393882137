import Types from "../Actions/types";

export const initState = {
  stationModal: { storeId: null },
  stationModalActions: {
    isVissible: false,
  },
  cashDrawerModal: {
    station_id: null,
    notes: "",
    hundred_bill: "",
    fifty_bill: "",
    twenty_bill: "",
    ten_bill: "",
    five_bill: "",
    one_bill: "",
    twenty_five_cents: "",
    ten_cents: "",
    five_cents: "",
    one_cents: "",
  },
  cashDrawerModalActions: {
    isVissible: false,
  },
  stationInfo: {},
  stationStripeInfo: [],
  stripeTerminalInfo: {},
  stripeTerminalDisconnectedModal: {},
  stripeTerminalDisconnectedModalActions: {
    isVissible: false,
  },
};

export const stationReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.SHOW_STATION_MODAL:
      return {
        ...state,
        stationModalActions: {
          isVissible: true,
        },
        stationModal: {
          ...state.stationModal,
          ...action.payload.data,
        },
        stripeTerminalInfo: initState.stripeTerminalInfo,
      };
    case Types.HIDE_STATION_MODAL:
      return {
        ...state,
        stationModalActions: {
          isVissible: false,
        },
        stationModal: initState.stationModal,
      };
    case Types.SHOW_CASH_DRAWER_MODAL:
      return {
        ...state,
        cashDrawerModalActions: {
          isVissible: true,
        },
        cashDrawerModal: {
          ...state.cashDrawerModal,
          ...action.payload.data,
        },
      };
    case Types.HIDE_CASH_DRAWER_MODAL:
      return {
        ...state,
        cashDrawerModalActions: {
          isVissible: false,
        },
        cashDrawerModal: initState.cashDrawerModal,
      };

    case Types.STORE_STATION_INFO:
      return {
        ...state,
        stationInfo: action.payload.data,
      };

    case Types.STORE_STATION_STRIPE_INFO:
      return {
        ...state,
        stationStripeInfo: action.payload.stripeData,
      };

    case Types.STORE_STRIPE_TERMINAL_INFO:
      return {
        ...state,
        stripeTerminalInfo: action.payload.data,
      };

    case Types.SHOW_STRIPE_TERMINAL_DISCONNECTED_MODAL:
      return {
        ...state,
        stripeTerminalDisconnectedModalActions: {
          isVissible: true,
        },
        stripeTerminalDisconnectedModal: {
          ...state.stripeTerminalDisconnectedModal,
          ...action.payload.data,
        },
      };
    case Types.HIDE_STRIPE_TERMINAL_DISCONNECTED_MODAL:
      return {
        ...state,
        stripeTerminalDisconnectedModalActions: {
          isVissible: false,
        },
        stripeTerminalDisconnectedModal:
          initState.stripeTerminalDisconnectedModal,
      };
    case Types.RESET_STRIPE_TERMINAL_INFO:
      return {
        ...state,
        stripeTerminalInfo: initState.stripeTerminalInfo,
      };

    case Types.INCEREMENT_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]: (
              Number(state[action.payload.formName][action.payload.name]) + 1
            ).toString(),
          },
        };
      }

    case Types.DECREMENT_INPUT_CHANGE:
      if (state[action?.payload?.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action?.payload?.formName],
            [action?.payload?.name]:
              Number(state[action?.payload?.formName][action?.payload?.name]) -
                1 >
              0
                ? (
                    Number(
                      state[action?.payload?.formName][action?.payload?.name]
                    ) - 1
                  )?.toString()
                : "0",
          },
        };
      }

    case Types.INPUT_VALUE_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]:
              action.payload.event.target.value,
          },
        };
      }

    default:
      return state;
  }
};
