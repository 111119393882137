import Types from "../types";

// Start of show and hide station action
export const showStationModalAction = (data) => ({
  type: Types.SHOW_STATION_MODAL,
  payload: data,
});

export const hideStationModalAction = () => ({
  type: Types.HIDE_STATION_MODAL,
});
// End of show and hide station action

// Start of show and hide cash drawer action
export const showCashDrawerModalAction = (data) => ({
  type: Types.SHOW_CASH_DRAWER_MODAL,
  payload: data,
});

export const hideCashDrawerModalAction = () => ({
  type: Types.HIDE_CASH_DRAWER_MODAL,
});
// End of show and hide cash drawer action

// Start of store station info action
export const storeStationInfoAction = (data) => ({
  type: Types.STORE_STATION_INFO,
  payload: data,
});
// End of store station info action

// Start of store station stripe info action
export const storeStationStripeInfoAction = (data) => ({
  type: Types.STORE_STATION_STRIPE_INFO,
  payload: data,
});
// End of store station stripe info action

// Start of store stripe terminal info action
export const storeStripeTerminalInfoAction = (data) => ({
  type: Types.STORE_STRIPE_TERMINAL_INFO,
  payload: data,
});
// End of store stripe terminal info action

// Start of show and hide of stripe terminal disconnected modal action
export const showStripeTerminalDisconnectedModalAction = (data) => ({
  type: Types.SHOW_STRIPE_TERMINAL_DISCONNECTED_MODAL,
  payload: data,
});

export const hideStripeTerminalDisconnectedModalAction = () => ({
  type: Types.HIDE_STRIPE_TERMINAL_DISCONNECTED_MODAL,
});
// End of show and hide of stripe terminal disconnected modal action

// Start of reset stripe terminal info action
export const resetStripeTerminalInfoAction = () => ({
  type: Types.RESET_STRIPE_TERMINAL_INFO,
});
// End of reset stripe terminal info action

// Start of input changes actions
export const onInputIncrementAction = (formName, name) => {
  return {
    type: Types.INCEREMENT_INPUT_CHANGE,
    payload: {
      formName,
      name,
    },
  };
};

export const onInputDecrementAction = (formName, name) => {
  return {
    type: Types.DECREMENT_INPUT_CHANGE,
    payload: {
      formName,
      name,
    },
  };
};

export const onInputValueChangeAction = (formName, event) => {
  return {
    type: Types.INPUT_VALUE_CHANGE,
    payload: {
      formName,
      event,
    },
  };
};
// End of input changes actions
