import Types from "../Actions/types";

export const initState = {
  salesPersonIdModal: {},
  salesPersonIdModalActions: {
    isVissible: false,
  },
  openDrawerManuallyModal: {},
  openDrawerManuallyModalActions: {
    isVissible: false,
  },
  itemsCombinations: {
    combinations: [],
    parsedCustomCombinations: [],
    customCombinations: [],
  },
  cart: [],
  cartTotalPrice: [],
  returnOrderData: {},
  returnedItemsCart: [],
};

export const itemsReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.SHOW_SALES_PERSON_ID_MODAL:
      return {
        ...state,
        salesPersonIdModalActions: {
          isVissible: true,
        },
        salesPersonIdModal: {
          ...state.salesPersonIdModal,
          ...action.payload.data,
        },
      };
    case Types.HIDE_SALES_PERSON_ID_MODAL:
      return {
        ...state,
        salesPersonIdModalActions: {
          isVissible: false,
        },
        salesPersonIdModal: initState.salesPersonIdModal,
      };
    case Types.SHOW_OPEN_DRAWER_MANUALLY_MODAL:
      return {
        ...state,
        openDrawerManuallyModalActions: {
          isVissible: true,
        },
        openDrawerManuallyModal: {
          ...state.openDrawerManuallyModal,
          ...action.payload.data,
        },
      };
    case Types.HIDE_OPEN_DRAWER_MANUALLY_MODAL:
      return {
        ...state,
        openDrawerManuallyModalActions: {
          isVissible: false,
        },
        openDrawerManuallyModal: initState.openDrawerManuallyModal,
      };
    case Types.SHOW_ITEMS_COMBINATION:
      return {
        ...state,
        itemsCombinations: {
          ...state.itemsCombinations,
          ...action.payload.data,
        },
      };
    case Types.HIDE_ITEMS_COMBINATION:
      return {
        ...state,
        itemsCombinations: initState.itemsCombinations,
      };

    case Types.FETCHED_ITEM_COMBINATION:
      return {
        ...state,
        itemsCombinations: {
          ...state.itemsCombinations,
          combinations: action.payload.fetchedItemCombination,
          parsedCustomCombinations: action.payload.parsedCustomCombination,
        },
      };
    case Types.ADD_CUSTOM_ITEM_COMBINATION:
      return {
        ...state,
        itemsCombinations: {
          ...state.itemsCombinations,
          customCombinations: [
            ...state.itemsCombinations.customCombinations,
            action.payload.customCombinationData,
          ],
        },
      };
    case Types.ON_ITEM_COMBINATION_CHANGE:
      let newValues = [...state.itemsCombinations.combinations];
      newValues[action?.payload?.index][action?.payload?.name] =
        action?.payload?.limit > action?.payload?.value
          ? action?.payload?.value
          : action?.payload?.limit;
      return {
        ...state,
        itemsCombinations: {
          ...state.itemsCombinations,
          combinations: newValues,
        },
      };
    case Types.REMOVE_ITEM_COMBINATION:
      let resetedValues = [...state.itemsCombinations.combinations];
      resetedValues[action?.payload?.index][action?.payload?.name] = 0;
      return {
        ...state,
        itemsCombinations: {
          ...state.itemsCombinations,
          combinations: resetedValues,
        },
      };
    case Types.ON_CUSTOM_COMBINATION_CHANGE:
      let newCustomValues = [
        ...state.itemsCombinations.parsedCustomCombinations,
      ];
      newCustomValues[action?.payload?.index][action?.payload?.name] =
        action?.payload?.value;
      return {
        ...state,
        itemsCombinations: {
          ...state.itemsCombinations,
          parsedCustomCombinations: newCustomValues,
        },
      };
    case Types.ADD_TO_CART:
      let cartTotalArray = {
        itemTotalPrice:
          action.payload?.itemDetails?.quantity *
          action.payload?.itemDetails?.price,
        itemTotalSalesTaxses:
          action.payload?.itemDetails?.tax *
          action.payload?.itemDetails?.quantity,
      };
      return {
        ...state,
        cart: [...state.cart, action.payload],
        cartTotalPrice: [...state.cartTotalPrice, cartTotalArray],
      };
    case Types.CLEAR_CART:
      return {
        ...state,
        cart: [],
        cartTotalPrice: initState.cartTotalPrice,
      };
    case Types.ADD_ITEM_PRICE_AND_QUANTITY_TO_CART_TOTAL:
      let newCartTotalValues = [...state.cartTotalPrice];
      newCartTotalValues[action.payload.index][action.payload.name] =
        action.payload.value;
      return {
        ...state,
        cartTotalPrice: newCartTotalValues,
      };
    case Types.ON_CART_INPUT_INCREMENT:
      let newCartIncrementValues = [...state.cart];
      newCartIncrementValues[action.payload.index]["itemDetails"][
        action.payload.name
      ] = (
        Number(
          newCartIncrementValues[action.payload.index]["itemDetails"][
            action.payload.name
          ]
        ) + 1
      )?.toString();
      return {
        ...state,
        cart: newCartIncrementValues,
      };
    case Types.ON_CART_INPUT_DECREMENT:
      let newCartDecrementValues = [...state.cart];
      newCartDecrementValues[action.payload.index]["itemDetails"][
        action.payload.name
      ] =
        Number(
          newCartDecrementValues[action.payload.index]["itemDetails"][
            action.payload.name
          ]
        ) -
          1 >
        0
          ? (
              Number(
                newCartDecrementValues[action.payload.index]["itemDetails"][
                  action.payload.name
                ]
              ) - 1
            ).toString()
          : "0";
      return {
        ...state,
        cart: newCartDecrementValues,
      };
    case Types.RESET_CART_TOTAL:
      return {
        ...state,
        cartTotalPrice: initState.cartTotalPrice,
      };
    case Types.REMOVE_CART_ITEM:
      return {
        ...state,
        cart: state?.cart?.filter((_, i) => i !== action.payload?.index),
        cartTotalPrice: state?.cartTotalPrice?.filter(
          (_, i) => i !== action.payload?.index
        ),
      };
    case Types.STORE_RETURN_ORDER_INFO:
      return {
        ...state,
        returnOrderData: action.payload,
      };
    case Types.ADD_TO_RETURNED_ITEMS_CART:
      return {
        ...state,
        returnedItemsCart: [...state.returnedItemsCart, action.payload?.data],
      };
    case Types.CLEAR_RETURNED_ITEMS_CART:
      return {
        ...state,
        returnedItemsCart: [],
        returnOrderData: {
          ...state.returnOrderData,
          items: [...state.returnOrderData?.items, ...action.payload],
        },
      };
    case Types.REMOVE_RETURNED_CART_ITEM:
      return {
        ...state,
        returnedItemsCart: state?.returnedItemsCart?.filter(
          (_, i) => i !== action.payload?.index
        ),
        returnOrderData: {
          ...state.returnOrderData,
          items: [...action.payload?.itemData],
        },
      };
    case Types.ON_RETURNED_ITEMS_CART_INPUT_INCREMENT:
      let newReturnedItemsCartIncrementValues = [...state.returnedItemsCart];
      newReturnedItemsCartIncrementValues[action.payload.index][
        action.payload.name
      ] = (
        Number(
          newReturnedItemsCartIncrementValues[action.payload.index][
            action.payload.name
          ]
        ) + 1
      )?.toString();
      return {
        ...state,
        returnedItemsCart: newReturnedItemsCartIncrementValues,
      };
    case Types.ON_RETURNED_ITEMS_CART_INPUT_DECREMENT:
      let newReturnedItemsCartDecrementValues = [...state.returnedItemsCart];
      newReturnedItemsCartDecrementValues[action.payload.index][
        action.payload.name
      ] =
        Number(
          newReturnedItemsCartDecrementValues[action.payload.index][
            action.payload.name
          ]
        ) -
          1 >
        0
          ? (
              Number(
                newReturnedItemsCartDecrementValues[action.payload.index][
                  action.payload.name
                ]
              ) - 1
            ).toString()
          : "0";
      return {
        ...state,
        returnedItemsCart: newReturnedItemsCartDecrementValues,
      };
    case Types.ON_RETURNED_ITEMS_INFO_INPUT_INCREMENT:
      let newReturnedItemsInfoIncrementValues = [
        ...state.returnOrderData.items,
      ];
      newReturnedItemsInfoIncrementValues[action.payload.index][
        action.payload.name
      ] =
        action?.payload?.limit >
        Number(
          newReturnedItemsInfoIncrementValues[action.payload.index][
            action.payload.name
          ]
        )
          ? (
              Number(
                newReturnedItemsInfoIncrementValues[action.payload.index][
                  action.payload.name
                ]
              ) + 1
            )?.toString()
          : action?.payload?.limit;

      return {
        ...state,
        returnOrderData: {
          ...state.returnOrderData,
          items: newReturnedItemsInfoIncrementValues,
        },
      };
    case Types.ON_RETURNED_ITEMS_INFO_INPUT_DECREMENT:
      let newReturnedItemsInfoDecrementValues = [
        ...state.returnOrderData.items,
      ];
      newReturnedItemsInfoDecrementValues[action.payload.index][
        action.payload.name
      ] =
        Number(
          newReturnedItemsInfoDecrementValues[action.payload.index][
            action.payload.name
          ]
        ) -
          1 >
        0
          ? (
              Number(
                newReturnedItemsInfoDecrementValues[action.payload.index][
                  action.payload.name
                ]
              ) - 1
            ).toString()
          : "0";
      return {
        ...state,
        returnOrderData: {
          ...state.returnOrderData,
          items: newReturnedItemsInfoDecrementValues,
        },
      };
    default:
      return state;
  }
};
