import React from "react";
import StoreFrontPage from "../Containers/StoreFrontPage";
import LoginPage from "../Containers/LoginPage";
import Products from "../Containers/Products";
import ProductDetails from "../Containers/ProductDetails";
import CheckoutPage from "../Containers/CheckoutPage";
import ReturnItemsPage from "../Containers/ReturnItemsPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UnauthorizedPage from "../Containers/UnauthorizedPage";
import { useSelector } from "react-redux";

const AppRoutes = () => {
  // states from store
  const token = useSelector((state) => state?.main?.auth?.token);

  return (
    <Router>
      {token ? (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/products" element={<Products />} />
          <Route path="/return-items" element={<ReturnItemsPage />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route
            exact
            path="/checkout/:storeFrontId/:stationName/:storeId/:storeName"
            element={<CheckoutPage />}
          />
          <Route path="/store-fronts" element={<StoreFrontPage />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="*" element={<LoginPage />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
        </Routes>
      )}
    </Router>
  );
};

export default AppRoutes;
