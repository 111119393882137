// handle station modal types
const SHOW_STATION_MODAL = "SHOW_STATION_MODAL";
const HIDE_STATION_MODAL = "HIDE_STATION_MODAL";

// handle cash drawer modal types
const SHOW_CASH_DRAWER_MODAL = "SHOW_CASH_DRAWER_MODAL";
const HIDE_CASH_DRAWER_MODAL = "HIDE_CASH_DRAWER_MODAL";

// handle store station info
const STORE_STATION_INFO = "STORE_STATION_INFO";

// handle store station stripe info
const STORE_STATION_STRIPE_INFO = "STORE_SATION_STRIPE_INFO";

// handle store stripe terminal info
const STORE_STRIPE_TERMINAL_INFO = "STORE_STRIPE_TERMINAL_INFO";

// handle show and hide and hide for stripe terminal disconnected modal types
const HIDE_STRIPE_TERMINAL_DISCONNECTED_MODAL =
  "HIDE_STRIPE_TERMINAL_DISCONNECTED_MODAL";
const SHOW_STRIPE_TERMINAL_DISCONNECTED_MODAL =
  "SHOW_STRIPE_TERMINAL_DISCONNECTED_MODAL";

// handle reset terminal types
const RESET_STRIPE_TERMINAL_INFO = "RESET_STRIPE_TERMINAL_INFO";

// handle input changes ===> inc , dec, value types
const INCEREMENT_INPUT_CHANGE = "INCEREMENT_INPUT_CHANGE";
const DECREMENT_INPUT_CHANGE = "DECREMENT_INPUT_CHANGE";
const INPUT_VALUE_CHANGE = "INPUT_VALUE_CHANGE";

export default {
  // handle station modal types
  SHOW_STATION_MODAL,
  HIDE_STATION_MODAL,

  // handle cash drawer modal types
  SHOW_CASH_DRAWER_MODAL,
  HIDE_CASH_DRAWER_MODAL,

  // handle store station info
  STORE_STATION_INFO,

  // handle store station stripe info
  STORE_STATION_STRIPE_INFO,

  // handle store stripe terminal info
  STORE_STRIPE_TERMINAL_INFO,

  // handle show and hide and hide for stripe terminal disconnected modal types
  HIDE_STRIPE_TERMINAL_DISCONNECTED_MODAL,
  SHOW_STRIPE_TERMINAL_DISCONNECTED_MODAL,

  // handle reset terminal types
  RESET_STRIPE_TERMINAL_INFO,

  // handle input changes ===> inc , dec, value types
  INCEREMENT_INPUT_CHANGE,
  DECREMENT_INPUT_CHANGE,
  INPUT_VALUE_CHANGE,
};
