import { v4 as uuid } from "uuid";

// Helper function to download any link
const downloadFileFromLink = (uri) => {
  var link = document.createElement("a");
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const clamp = (value, max = Infinity, min = 0) => {
  return Math.min(Math.max(value, min), max);
};

const getItemAttributes = (item_attributes = []) => {
  let attributes = [];
  item_attributes?.forEach((attribute, i) => {
    let currentAttr = attributes?.find(
      (attr) => attr?.attr_id == attribute?.attr_id
    );
    if (currentAttr) {
      let { attr_option_id, attr_option_name, attr_option_value } = attribute;
      currentAttr.attributeValue = null;
      currentAttr.attributeName = null;
      currentAttr.options.push({
        attr_option_id,
        attr_option_name,
        attr_option_value,
      });
    } else {
      let { attr_option_id, attr_option_name, attr_option_value } = attribute;
      let newAttr = {
        ...attribute,
        attributeValue: attr_option_id,
        attributeName: attr_option_name,
        attributeId: attribute?.attr_id,
        options: [{ attr_option_id, attr_option_name, attr_option_value }],
      };
      attributes.push(newAttr);
    }
  });

  return attributes;
};

const getItemCombinations = (itemCompos = [], cartItem) => {
  let itemCombinations = [];

  const rfn = (compo, parentId = null) => {
    if (compo?.child_items && compo?.child_items?.length) {
      let childCompo = {
        parentCartItemId: parentId,
        isBackorder: compo?.is_backorder,
        item_type: compo?.item_type,
        id: `kitchild-${compo?.id}`,
        image_url: compo?.image_url,
        name: compo?.name,
        description: compo?.description,
        item_attributes: compo?.item_attributes,
        available_combinations: compo?.available_combinations,
        custom_attributes: getItemAttributes(compo?.item_attributes),
        hasActiveCompo: hasAvailableCombinations(compo?.available_combinations),
        hasCustomAttributes: compo?.item_attributes?.length > 0 ? true : false,
        isCustom: !hasAvailableCombinations(compo?.available_combinations),
      };
      itemCombinations.push(childCompo);
      for (let i = 0; i < compo?.child_items?.length; i++) {
        rfn(compo?.child_items[i], `kitchild-${compo?.id}`);
      }
    }
    if (!compo?.child_items) {
      let updatedCompo = {
        parentCartItemId: parentId,
        item_type: compo?.item_type,
        id: uuid(),
        isBackorder: compo?.is_backorder,
        itemId: compo?.id,
        image_url: compo?.image_url,
        name: compo?.name,
        description: compo?.description,
        item_attributes: compo?.item_attributes,
        available_combinations: compo?.available_combinations,
        custom_attributes: getItemAttributes(compo?.item_attributes),
        hasActiveCompo: hasAvailableCombinations(compo?.available_combinations),
        hasCustomAttributes: compo?.item_attributes?.length > 0,
        isCustom: !hasAvailableCombinations(compo?.available_combinations), //is default active compo false,
      };
      if (compo?.id) {
        itemCombinations.push(updatedCompo);
      }
    }
  };

  let firstParentId =
    cartItem?.item_type === "Kit" ? `kitItem-${cartItem?.item_id}` : null;
  itemCompos?.forEach((compo) => rfn(compo, firstParentId));
  return itemCombinations;
};

// to solve broken url image issue
const addDefaultSrc = (ev) => {
  ev.target.src = "https://ma.aioboportal.com/images/item/default.png";
};

// Check if array is empty
function isArrayEmpty(arr) {
  return arr.length === 0 || (arr.length === 1 && arr?.includes(null));
}

// Check if object has specific properties with values of null
function hasNullProperties(obj) {
  return Object.values(obj).every(
    (value) => value === null || isArrayEmpty(value)
  );
}

// Check if array contains objects with specific properties having values of null
function doesArrayContainNullObjects(arr) {
  return arr.every((obj) => hasNullProperties(obj));
}

const hasAvailableCombinations = (availableCombinations = []) => {
  if (!availableCombinations) {
    return false;
  }
  if (isArrayEmpty(availableCombinations)) {
    // console.log("Array is empty");
    return false;
  } else if (doesArrayContainNullObjects(availableCombinations)) {
    // console.log("Array contains objects with specific properties having values of null");
    return false;
  } else {
    //  console.log("Array is not empty and does not contain only objects with specific properties having values of null");
    return true;
  }
};

// Helper function to get the initals of any name
const getInitials = (name) => {
  if (name) {
    let fullname = name.trim().split(" ");
    return `${fullname[0][0]}${fullname[fullname.length - 1][0]}`;
  }
};

// Helper function to generate colors from strings
const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string?.length; i += 1) {
    hash = string?.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export default {
  downloadFileFromLink,
  clamp,
  getItemAttributes,
  getItemCombinations,
  addDefaultSrc,
  getInitials,
  stringToColor,
};
