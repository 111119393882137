import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import { Spinner, Button } from "reactstrap";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckoutRoundedIcon from "@mui/icons-material/ShoppingCartCheckoutRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCartAction,
  resetCartTotalAction,
  removeCartItemAction,
  fetchCountriesAction,
  addDataToValidatedCartAction,
  hideConfirmPasswordModalAction,
} from "../../Redux/Actions";
import HelperFns from "../../Helpers/HelperFns";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CartIncDecInput from "../Cart/CartIncDecInput";
import { useLazyQuery, useMutation } from "@apollo/client";
import { fetchCountriesQuery } from "../../graphql/queries";
import { validatedCartMutation } from "../../graphql/mutations";
import Swal from "sweetalert2";
import _ from "lodash";

const Cart = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // states from store
  const cart = useSelector((state) => state?.main?.items?.cart);
  const cartTotalPrice = useSelector(
    (state) => state?.main?.items?.cartTotalPrice
  );

  // handle clear cart
  const handleClearCart = () => {
    dispatch(clearCartAction());

    // to reset cart total
    dispatch(resetCartTotalAction());
  };

  // handle remove cart item
  const handleRemoveCartItem = (index, itemId) => {
    dispatch(removeCartItemAction(index, itemId));
  };

  // Start of handle fetch countries query
  const [
    fetchCountries,
    {
      data: countriesData,
      loading: countriesLoading,
      error: countriesResponseError,
    },
  ] = useLazyQuery(fetchCountriesQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      if (data?.getCountries) {
        // to store countries data
        dispatch(fetchCountriesAction(data?.getCountries));
      }
      console.log("onCompleted", data);
    },
  });
  // End of handle fetch countries query

  // to remove specific object key from cart data array
  let validateItemsArray = cart
    ?.map((el) => el?.itemDetails?.data)
    ?.flat(Infinity);
  let serializedCartData = validateItemsArray?.flatMap((obj) =>
    _.omit(obj, "attributeOptionName")
  );

  // handle checkout btn
  const handleCheckoutBtn = () => {
    fetchCountries();

    // close exit session modal
    dispatch(hideConfirmPasswordModalAction());

    // handle add data to validated cart
    let data = {
      storeId: 1000000,
      stationId: +props?.storeFrontId,
      storeFrontId: +props?.storeId,
      items: serializedCartData,
    };

    // handle validate the cart
    handleValidateCart({ data });
  };

  // Start of handle validate cart mutation
  const handleValidateCart = (cartData) => {
    validateCart({
      variables: {
        cart: cartData?.data,
      },
    })
      .then((response) => {
        if (response?.errors || response?.validateCart?.status === "false") {
          // error swal
          Swal.fire({
            title: "Error",
            text:
              response?.errors[0]?.extensions?.validation ??
              response?.errors[0]?.message,
            timer: 3000,
            showConfirmButton: false,
            imageUrl: require("../../Assets/img/error-icon.png"),
            imageAlt: "Image not found",
            customClass: {
              popup: "swal-error-style",
              container: "swal-title",
            },
          });
        }
      })
      .catch((error) => {
        console.log("catch", { error });
      });
  };

  const [
    validateCart,
    {
      data: validateCartResponse,
      loading: validateCartIsLoading,
      error: validateCartError,
    },
  ] = useMutation(validatedCartMutation, {
    onCompleted: (data) => {
      if (data?.validateCart?.status === "true") {
        // handle add data to validated cart
        let data = {
          storeId: 1000000,
          stationId: +props?.storeFrontId,
          storeFrontId: +props?.storeId,
          items: serializedCartData,
        };

        dispatch(addDataToValidatedCartAction({ data }));

        // navigate to checkout form
        navigate(
          `/checkout/${props?.storeFrontId}/${props?.stationName}/${props?.storeId}/${props?.storeName}`
        );
      }
    },
  });
  // End of handle validate cart mutation

  return (
    <div className="cart_wrapper_style">
      <div className="cart_header_style">
        <p className="mb-0">Cart Details</p>

        <h2 onClick={handleClearCart}>Delete All</h2>
      </div>

      <div className="cart_items_wrapper_style">
        {cart?.length ? (
          <>
            {cart?.map((item, i) => {
              return (
                <div className="cart_items_divs_style" key={i}>
                  <div className="d-flex align-items-start justify-content-between">
                    <div className="d-flex">
                      <img
                        className="product_in_cart_img_style"
                        height={85}
                        src={item?.itemDetails?.image_url}
                        onError={(e) => HelperFns.addDefaultSrc(e)}
                        alt="Image Not Found"
                      />

                      <div className="px-2">
                        <h2>{item?.itemDetails?.name}</h2>

                        {item?.itemDetails?.item_type?.toLowerCase() ===
                        "kit" ? (
                          <>
                            {item?.itemDetails?.data
                              ?.filter((el) => el?.parentCartItemId !== null)
                              ?.map((kitItem) => (
                                <>
                                  <h2 className="kit_item_style mt-2">
                                    {kitItem?.itemName} :
                                  </h2>

                                  <div className="cart_item_description_style">
                                    <p className="mb-0">
                                      {kitItem?.attributeOptionName} x{" "}
                                      {kitItem?.quantity}
                                    </p>
                                  </div>

                                  {/* {kitItem?.firstCustomText ? (
                                <div className="cart_item_custom_text_style">
                                  <p className="mb-0 mt-1 text-break">
                                    {kitItem?.firstCustomTextLabel}:{" "}
                                    {kitItem?.firstCustomText}
                                  </p>
                                </div>
                              ) : null} */}
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            <div className="cart_item_description_style">
                              <p className="mb-0">
                                {
                                  item?.itemDetails?.data[0]
                                    ?.attributeOptionName
                                }
                              </p>
                            </div>

                            {/* {item?.firstCustomText ? (
                          <div className="cart_item_custom_text_style">
                            <p className="mb-0 mt-1 text-break">
                              {item?.firstCustomTextLabel}:{" "}
                              {item?.firstCustomText}
                            </p>
                          </div>
                        ) : null} */}
                          </>
                        )}
                      </div>
                    </div>

                    <img
                      className="cursor-pointer"
                      onClick={() =>
                        handleRemoveCartItem(i, item?.itemDetails?.id)
                      }
                      src={require("../../Assets/img/trash-icon.png")}
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="input_Increment_decrement_wrapper_style">
                      <div className="px-2">
                        <p className="mb-0">X</p>
                      </div>

                      <div className="px-0">
                        <p className="mb-0">
                          ${+item?.itemDetails?.price?.toFixed(2)}
                        </p>
                      </div>

                      <div className="d-flex align-items-baseline px-1">
                        <p className="px-1 mb-0">=</p>
                        <p className="px-1 mb-0">
                          $
                          {(
                            +item?.itemDetails?.price *
                            +item?.itemDetails?.quantity
                          )?.toFixed(2)}
                        </p>
                      </div>
                    </div>

                    <CartIncDecInput
                      quantity={item?.itemDetails?.quantity}
                      price={item?.itemDetails?.price}
                      quanity_limit={item?.itemDetails?.maxQuantity}
                      salesTaxses={item?.itemDetails?.tax}
                      index={i}
                    />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center empty_cart_wrapper_style">
              <img src={require("../../Assets/img/empty-cart-icon.png")} />
            </div>
          </div>
        )}
      </div>

      {cart?.length > 0 ? (
        <div className="cart_bottom_style">
          <div className="d-flex align-items-center justify-content-between">
            <h4>Subtotal</h4>

            <h3>
              $
              {cartTotalPrice
                ?.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue?.itemTotalPrice,
                  0
                )
                ?.toFixed(2)}
            </h3>
          </div>

          <div className="d-flex align-items-center justify-content-between my-3">
            <h4>Sales Tax</h4>

            <h3>
              $
              {cartTotalPrice
                ?.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue?.itemTotalSalesTaxses,
                  0
                )
                ?.toFixed(2)}
            </h3>
          </div>

          <hr />

          <div className="d-flex align-items-center justify-content-between">
            <h6>
              <b>Total</b>
            </h6>

            <h5>
              <b>
                $
                {(
                  cartTotalPrice?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue?.itemTotalPrice,
                    0
                  ) +
                  cartTotalPrice?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue?.itemTotalSalesTaxses,
                    0
                  )
                )?.toFixed(2)}
              </b>
            </h5>
          </div>

          <Button
            className="checkout_cart_btn_style"
            datatestid="validate_cart_items_btn"
            onClick={handleCheckoutBtn}
          >
            {countriesLoading || validateCartIsLoading ? (
              <Spinner size={"sm"} style={{ color: "#fff" }} />
            ) : (
              <>Checkout</>
            )}
          </Button>
        </div>
      ) : (
        <div className="cart_bottom_style empty_cart_wrapper_style">
          <Button className="checkout_cart_btn_style">Checkout</Button>
        </div>
      )}
    </div>
  );
};

export default Cart;
