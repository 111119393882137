import Types from "../Actions/types";

export const initState = {
  countries: [],

  checkoutFormData: {
    customerForm: {},
    cartTotalAmount: 0,
  },

  shippingAddressOptions: [],

  creditCardSplitPayments: [],
  cashSplitPayments: [],

  itemsSplitPayments: [],

  showSplitPaymentSection: false,

  showSplitItemsSection: false,

  showCashSection: "creditCardSection",

  disablePayAmountInput: false,

  splitPaymentAmountValue: 0,

  splitByItemIndex: "",

  remainingValueOfSplitPayment: 0,

  paidValueOfSplitPayment: 0,

  itemsDiscounts: [],

  receiptOptionsModal: {},
  receiptOptionsModalActions: {
    isVissible: false,
  },
};

export const checkoutReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.FETCH_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };

    case Types.STORE_CUSTOMER_INFO_FORM_DATA:
      if (action?.payload?.id === "674") {
        return {
          ...state,
          checkoutFormData: {
            ...state.checkoutFormData,
            customerForm: action.payload,
          },
          shippingAddressOptions: [],
        };
      } else {
        return {
          ...state,
          checkoutFormData: {
            ...state.checkoutFormData,
            customerForm: action.payload,
          },
          shippingAddressOptions: [
            ...state.shippingAddressOptions,
            {
              id: action?.payload?.shipping_address_id ?? 0,
              address:
                action?.payload?.address_one +
                " " +
                action.payload?.address_two,
              city: action?.payload?.city,
              state: action?.payload?.state,
              country: action?.payload?.country,
              phone: action?.payload?.phone,
            },
          ],
        };
      }

    case Types.ADD_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddressOptions: [
          ...state.shippingAddressOptions,
          action.payload,
        ],
      };

    case Types.CLEAR_SHIPPING_ADDRESSES:
      return {
        ...state,
        shippingAddressOptions: [],
        creditCardSplitPayments: [],
        cashSplitPayments: [],
      };

    case Types.ADD_DATA_TO_VALIDATED_CART:
      return {
        ...state,
        validatedCart: {
          ...state.validatedCart,
          ...action.payload.data,
        },
      };

    case Types.STORE_CART_TOTAL_AMOUNT:
      return {
        ...state,
        checkoutFormData: {
          ...state.checkoutFormData,
          cartTotalAmount: +action.payload,
        },
      };

    case Types.SHOW_SPLIT_PAYMENT_SECTION:
      return {
        ...state,
        showSplitPaymentSection: action.payload,
      };

    case Types.SHOW_SPLIT_ITEM_SECTION:
      return {
        ...state,
        showSplitItemsSection: action.payload,
      };

    case Types.DISABLE_PAY_SPLIT_AMOUNT_INPUT:
      return {
        ...state,
        disablePayAmountInput: action.payload.isDisabled,
        splitPaymentAmountValue: action.payload.amountValue,
        splitByItemIndex: action.payload.itemIndex,
      };

    case Types.SHOW_CASH_SECTION:
      return {
        ...state,
        showCashSection: action.payload,
      };

    case Types.SET_REMAINING_VALUE_OF_SPLIT_PAYMENTS:
      return {
        ...state,
        remainingValueOfSplitPayment: action.payload,
      };

    case Types.SET_PAID_VALUE_OF_SPLIT_PAYMENTS:
      return {
        ...state,
        paidValueOfSplitPayment: action.payload,
      };

    case Types.STORE_CREDIT_CARD_SPLIT_PAYMENTS:
      return {
        ...state,
        creditCardSplitPayments: [
          ...state.creditCardSplitPayments,
          action.payload?.data,
        ],
      };

    case Types.STORE_CASH_SPLIT_PAYMENTS:
      return {
        ...state,
        cashSplitPayments: [...state.cashSplitPayments, action.payload?.data],
      };

    case Types.ADD_SPLIT_PAYMENTS_ITEMS:
      let selectedItemsArray = action.payload?.map((obj) => ({
        ...obj,
        item_selected: false,
        payment_status: "unpaid",
      }));
      return {
        ...state,
        itemsSplitPayments: [...selectedItemsArray],
        paidSplitItems: [...selectedItemsArray],
      };

    case Types.REMOVE_SPLIT_ITEM_PAYMENT:
      let paidItemsArray = state?.itemsSplitPayments
        ?.filter((_, i) => i === action.payload?.index)
        ?.map((obj) => ({
          ...obj,
          payment_status: "paid",
        }));
      return {
        ...state,
        itemsSplitPayments: state?.itemsSplitPayments?.filter(
          (_, i) => i !== action.payload?.index
        ),
        paidSplitItems: [...state?.paidSplitItems, ...paidItemsArray],
      };

    case Types.HANDLE_ON_CHANGE_ITEMS_PAYMENTS:
      let newItemsSplitPaymentValues = [...state.itemsSplitPayments];
      newItemsSplitPaymentValues[action.payload.index][action.payload.name] =
        action.payload.value;
      return {
        ...state,
        itemsSplitPayments: newItemsSplitPaymentValues,
      };

    case Types.STORE_ITEM_DSICOUNT:
      let discountedItemsArray = action?.payload?.items;

      const filteredDiscountedItemsArray = discountedItemsArray?.map(
        ({ discounts, __typename, ...rest }) => {
          return rest;
        }
      );

      const { items, ...withoutTheItemsArray } = { ...state.validatedCart };

      let newValidatedCartItemsValues = {
        ...withoutTheItemsArray,
        items: [...filteredDiscountedItemsArray],
      };

      // handle change price object key
      discountedItemsArray?.map(
        (item, index) =>
          (newValidatedCartItemsValues["items"][index]["price"] = {
            amount: item?.price?.amount,
            discountAmount: item?.price?.discountAmount,
          })
      );

      // handle change tax object key
      discountedItemsArray?.map(
        (item, index) =>
          (newValidatedCartItemsValues["items"][index]["tax"] = {
            amount: item?.tax?.amount,
            discountAmount: item?.tax?.discountAmount,
          })
      );

      return {
        ...state,
        itemsDiscounts: action.payload,
        validatedCart: newValidatedCartItemsValues,
      };

    case Types.RESET_CHECKOUT:
      return {
        ...state,
        checkoutFormData: {
          customerForm: {},
          cartTotalAmount: 0,
        },

        shippingAddressOptions: [],

        creditCardSplitPayments: [],
        cashSplitPayments: [],

        itemsSplitPayments: [],
        paidSplitItems: [],

        showSplitPaymentSection: false,

        showSplitItemsSection: false,

        disablePayAmountInput: false,

        splitPaymentAmountValue: 0,

        splitByItemIndex: "",

        showCashSection: "creditCardSection",

        remainingValueOfSplitPayment: 0,

        paidValueOfSplitPayment: 0,

        itemsDiscounts: [],
      };

    case Types.SHOW_RECEIPT_OPTIONS_MODAL:
      return {
        ...state,
        receiptOptionsModalActions: {
          isVissible: true,
        },
        receiptOptionsModal: {
          ...state.receiptOptionsModal,
          ...action.payload.data,
        },
      };
    case Types.HIDE_RECEIPT_OPTIONS_MODAL:
      return {
        ...state,
        receiptOptionsModalActions: {
          isVissible: false,
        },
        receiptOptionsModal: initState.receiptOptionsModal,
      };

    default:
      return state;
  }
};
