import React from "react";

const InputForm = (props) => {
  // handle input on change
  const handleInputFormComponentChange = (e) => {
    props?.handleInputOnChange(e?.target?.name, e?.target?.value);
  };

  return (
    <div className="d-flex align-items-baseline">
      <input
        type={props?.type}
        className="custom_input_form_style"
        name={props?.name}
        onChange={handleInputFormComponentChange}
        {...props}
      />

      {props?.inputIconComponent && (
        <div className="input_form_icon_component_style">
          {props?.inputIconComponent}
        </div>
      )}
    </div>
  );
};

export default InputForm;
