export const ITEM_ATTRIBUTE_TYPE_TEXT = "1";
export const ITEM_ATTRIBUTE_TYPE_LIST = "2";
export const ITEM_ATTRIBUTE_TYPE_IMAGE = "3";
export const ITEM_ATTRIBUTE_TYPE_COLOR = "4";
export const ITEM_ATTRIBUTE_TYPE_ITEM = "5";
export const ITEM_ATTRIBUTE_TYPE_FILE = "6";
export const ITEM_ATTRIBUTE_TYPE_EMBROIDERY = "7";




export default {
    ITEM_ATTRIBUTE_TYPE_TEXT,
    ITEM_ATTRIBUTE_TYPE_LIST,
    ITEM_ATTRIBUTE_TYPE_IMAGE,
    ITEM_ATTRIBUTE_TYPE_COLOR,
    ITEM_ATTRIBUTE_TYPE_ITEM,
    ITEM_ATTRIBUTE_TYPE_FILE,
    ITEM_ATTRIBUTE_TYPE_EMBROIDERY
}