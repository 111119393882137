import gql from "graphql-tag";

export const returnOrderFragment = gql`
  fragment returnOrderUnionResponse on ReturnOrderUnion {
    ... on Response {
      status
      message
    }
    ... on ReturnOrder {
      id
      order_is_exist
      transaction_id
      amount_is_editable
      is_returnable
      remaining_refundable_shipping
      shipping_cost
      items {
        order_item_id
        item_id
        item_name
        image_url {
          small
        }
        price_after_discount
        price
        tax
        is_returnable
        flag_warranty_days
        warranty_days
        flag_return_days
        return_days
        quantity
      }
    }
  }
`;
