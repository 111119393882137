import React from "react";
import Select, { components } from "react-select";

// to add custom attribute to react select
const addCustomAttribute = (Component, dataTestIdAttr) => (props) =>
  (
    <Component
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        datatestid: dataTestIdAttr,
      })}
    />
  );

const SelectForm = (props) => {
  // handle custom component
  const CUSTOM_COMPONENTS = {
    // Option: addCustomAttribute(components.Option, props?.optionDataTestIds),
    SelectContainer: addCustomAttribute(
      components.SelectContainer,
      props?.selectContainerDataTestId
    ),
  };

  return (
    <div>
      <Select
        components={CUSTOM_COMPONENTS}
        classNamePrefix="custom_select_style"
        name={props?.name}
        options={props?.selectOptions}
        onChange={props?.handleSelectFormChange}
        placeholder="Select option"
        {...props}
      />
    </div>
  );
};

export default SelectForm;
