import React from "react";
import { Button } from "reactstrap";
import SelectForm from "../FormComponents/SelectForm";

const FilterMenu = (props) => {
  return (
    <div className="filter_menu_wrapper_style">
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="d-flex align-items-center">
            <div className="w-100">
              <div className="search_by_product_name_wrapper_style w-100">
                <div className="col-sm-8 col-md-9 col-xl-10 px-0">
                  <form onSubmit={props?.handleSearchInputOnChange}>
                    <input
                      placeholder="Search products..."
                      datatestid="search_by_name_input_filter"
                      onChange={(e) => {
                        props?.setSearchInput(e?.target?.value);
                      }}
                    />
                  </form>
                </div>

                <div className="col-sm-4 col-md-3 col-xl-2 px-0 text-end">
                  <img src={require("../../Assets/img/search-icon.png")} />
                </div>
              </div>
            </div>

            <div className="w-100">
              <form onSubmit={props?.handleSearchInputOnChange}>
                <input
                  className="search_input_style"
                  datatestid="search_by_serial_number_input_filter"
                  placeholder="Enter serial number..."
                  onChange={(e) => {
                    props?.setBarcodeValue(e?.target?.value);
                  }}
                />
              </form>
            </div>

            <Button className="scan_bar_code_btn_style">
              <img src={require("../../Assets/img/scan-bar-icon.png")} />
            </Button>
          </div>
        </div>

        <div className="col-12 col-md-4 d-flex justify-content-end">
          <Button
            className="filter_btn_style"
            onClick={props?.handleToggleFilterSection}
          >
            {props?.showFiltersSection ? (
              <img src={require("../../Assets/img/open-filter-icon.png")} />
            ) : (
              <img src={require("../../Assets/img/close-filter-icon.png")} />
            )}
          </Button>
        </div>
      </div>

      {props?.showFiltersSection ? (
        <div className="row mt-4">
          <div className="col-12 col-md-6">
            <SelectForm
              name="category_id"
              selectOptions={props?.categoryArrayOptions?.map(
                ({ id, name }) => ({
                  label: name,
                  value: id,
                })
              )}
              placeholder="Select Category"
              handleSelectFormChange={(e) =>
                props?.handleSelectOnChange(e, "category_id")
              }
              selectContainerDataTestId="category_container_filter_data_test_id"
              // optionDataTestIds="category-option-filter"
              isClearable
            />
          </div>

          <div className="col-12 col-md-6">
            <SelectForm
              name="brand_id"
              selectOptions={props?.brandArrayOptions?.map(({ id, name }) => ({
                label: name,
                value: id,
              }))}
              placeholder="Select Brand"
              handleSelectFormChange={(e) =>
                props?.handleSelectOnChange(e, "brand_id")
              }
              selectContainerDataTestId="brand_container_filter_data_test_id"
              isClearable
            />
          </div>

          <div className="col-12 mt-3">
            <div className="d-flex">
              <div className="w-100">
                <SelectForm
                  name="manufacturer_id"
                  selectOptions={props?.manafactureArrayOptions?.map(
                    ({ id, name }) => ({
                      label: name,
                      value: id,
                    })
                  )}
                  placeholder="Select Manufacturer"
                  handleSelectFormChange={(e) =>
                    props?.handleSelectOnChange(e, "manufacturer_id")
                  }
                  selectContainerDataTestId="manufacturer_container_filter_data_test_id"
                  isClearable
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterMenu;
