import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalLayout from "../ModalLayout";
import { hideOpenDrawerManuallyAction } from "../../Redux/Actions";
import { Button, Spinner } from "reactstrap";
import { useLazyQuery } from "@apollo/client";
import { manualDrawerPrintViewQuery } from "../../graphql/queries";
import HelperFns from "../../Helpers/HelperFns";
import { Radio, FormControlLabel, Alert } from "@mui/material";

const OpenDrawerManuallyModal = (props) => {
  const dispatch = useDispatch();

  // states from store
  const isModalVissible = useSelector(
    (state) => state?.main?.items?.openDrawerManuallyModalActions?.isVissible
  );
  const openDrawerManuallyModal = useSelector(
    (state) => state?.main?.items?.openDrawerManuallyModal
  );

  // modal error message useState
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  // submit useState
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [radioBtnValue, setRadioBtnValue] = useState("Page");

  // handle radio btn on change
  const handleRadioBtnChange = (event) => {
    setRadioBtnValue(event.target.value);
  };

  const handleSubmitBtn = () => {
    setModalErrorMessage("");
    setIsSubmitting(true);

    handleOpenDrawerManually({
      variables: {
        page_type: radioBtnValue,
        station_id: props?.stationId,
      },
    });
  };

  // handle toggle modal
  const toggleModal = () => {
    dispatch(hideOpenDrawerManuallyAction());
  };

  // Start of handle print drawer manually query
  const [
    handleOpenDrawerManually,
    {
      data: manualDrawerPrintResponse,
      loading: isManualDrawerPrintLoading,
      error: manualDrawerPrintError,
    },
  ] = useLazyQuery(manualDrawerPrintViewQuery, {
    onError(err) {
      // handle error message
      setModalErrorMessage(
        err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message
      );

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);

      if (data?.manualDrawerPrintView) {
        // close modal
        toggleModal();

        // handle download link
        HelperFns.downloadFileFromLink(data?.manualDrawerPrintView);
      } else {
        setModalErrorMessage("Something went wrong");
      }
    },
  });
  // End of handle print drawer manually query

  useEffect(() => {
    setIsSubmitting(false);
    setModalErrorMessage("");
    return () => {};
  }, [isModalVissible]);

  return (
    <>
      <ModalLayout
        modalTitle="Choose printing format"
        isOpen={isModalVissible}
        toggle={toggleModal}
        className="confirm_password_modal_wrapper_style"
        centered
      >
        <div>
          <div className="row mb-3">
            <div className="col-12">
              <FormControlLabel
                className="radio_label_style"
                control={
                  <Radio
                    checked={radioBtnValue === "Page"}
                    onChange={handleRadioBtnChange}
                    value="Page"
                    sx={{
                      color: "#b7c0cd",
                      "&.Mui-checked": {
                        color: "#3e8ef7",
                      },
                    }}
                  />
                }
                label="Page Format"
                labelPlacement="end"
              />
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-12">
              <FormControlLabel
                className="radio_label_style"
                control={
                  <Radio
                    checked={radioBtnValue === "Receipt"}
                    onChange={handleRadioBtnChange}
                    value="Receipt"
                    sx={{
                      color: "#b7c0cd",
                      "&.Mui-checked": {
                        color: "#3e8ef7",
                      },
                    }}
                  />
                }
                label="Receipt Printer Format"
                labelPlacement="end"
              />
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center mt-4">
            <Button
              disabled={isManualDrawerPrintLoading}
              className="closeing_station_btn_style w-100"
              type="submit"
              onClick={handleSubmitBtn}
            >
              {isManualDrawerPrintLoading ? (
                <Spinner size={"sm"} style={{ color: "#fff" }} />
              ) : (
                "Print"
              )}
            </Button>
          </div>
        </div>

        {/* (Start) Error Message */}
        {modalErrorMessage && isSubmitting && (
          <Alert severity="error" className="mt-2">
            {modalErrorMessage}
          </Alert>
        )}
        {/* (End) Error Message */}
      </ModalLayout>
    </>
  );
};

export default OpenDrawerManuallyModal;
