import Types from "../types";

// Start of fetch countries action
export const fetchCountriesAction = (data) => ({
  type: Types.FETCH_COUNTRIES,
  payload: data,
});
// End of fetch countries action

// Start of store customer info form data action
export const storeCustomerInfoFormAction = (data) => ({
  type: Types.STORE_CUSTOMER_INFO_FORM_DATA,
  payload: data,
});
// End of store customer info form data action

// Start of add shipping address action
export const addShippingAddressAction = (data) => ({
  type: Types.ADD_SHIPPING_ADDRESS,
  payload: data,
});
// End of add shipping address action

// Start of clear shipping address action
export const clearShippingAddresses = () => ({
  type: Types.CLEAR_SHIPPING_ADDRESSES,
});
// End of clear shipping address action

// Start of add data to validate cart action
export const addDataToValidatedCartAction = (data) => ({
  type: Types.ADD_DATA_TO_VALIDATED_CART,
  payload: data,
});
// End of add data to validate cart action

// Start of store cart total amount action
export const storeCartTotalAmount = (data) => ({
  type: Types.STORE_CART_TOTAL_AMOUNT,
  payload: data,
});
// End of store cart total amount action

// Start of show split payment section action
export const showSplitPaymentSectionAction = (data) => ({
  type: Types.SHOW_SPLIT_PAYMENT_SECTION,
  payload: data,
});
// End of show split payment section action

// Start of show split items section action
export const showSplitItemsSectionAction = (data) => ({
  type: Types.SHOW_SPLIT_ITEM_SECTION,
  payload: data,
});
// End of show split items section action

// Start of show cash section action
export const showCashSectionAction = (data) => ({
  type: Types.SHOW_CASH_SECTION,
  payload: data,
});
// End of show cash section action

// Start of disable split amount action
export const disablePayAmountInputAction = (
  isDisabled,
  amountValue,
  itemIndex
) => ({
  type: Types.DISABLE_PAY_SPLIT_AMOUNT_INPUT,
  payload: {
    isDisabled,
    amountValue,
    itemIndex,
  },
});
// End of disable split amount action

// Start of set remainig value action
export const setReamainigValueAction = (data) => ({
  type: Types.SET_REMAINING_VALUE_OF_SPLIT_PAYMENTS,
  payload: data,
});
// End of set remainig value action

// Start of set paid value action
export const setPaidValueAction = (data) => ({
  type: Types.SET_PAID_VALUE_OF_SPLIT_PAYMENTS,
  payload: data,
});
// End of set paid value action

// Start of store credit card split payments action
export const storeCreditCardSplitPaymentsAction = (data) => ({
  type: Types.STORE_CREDIT_CARD_SPLIT_PAYMENTS,
  payload: data,
});
// End of store credit card split payments action

// Start of store cash split payments action
export const storeCashSplitPaymentsAction = (data) => ({
  type: Types.STORE_CASH_SPLIT_PAYMENTS,
  payload: data,
});
// End of store cash split payments action

// Start of add split payments items action
export const addSplitPaymentsItemsAction = (data) => ({
  type: Types.ADD_SPLIT_PAYMENTS_ITEMS,
  payload: data,
});
// End of add split payments items action

// Start of remove split payments items action
export const removeSplitPaymentItemAction = (index) => {
  return {
    type: Types.REMOVE_SPLIT_ITEM_PAYMENT,
    payload: {
      index,
    },
  };
};
// End of remove split payments items action

// Start of handle split items payment action
export const splitItemsPaymentsAction = (value, name, index) => ({
  type: Types.HANDLE_ON_CHANGE_ITEMS_PAYMENTS,
  payload: {
    value,
    name,
    index,
  },
});
// End of handle split items payment action

// Start of store items discount action
export const storeItemDiscountAction = (data) => ({
  type: Types.STORE_ITEM_DSICOUNT,
  payload: data,
});
// End of store items discount action

// Start of reset checkout action
export const resetCheckoutAction = () => ({
  type: Types.RESET_CHECKOUT,
});
// End of reset checkout action

// Start of show and hide of Receipt options modal action
export const showReceiptOptionsModalAction = (data) => ({
  type: Types.SHOW_RECEIPT_OPTIONS_MODAL,
  payload: data,
});

export const hideReceiptOptionsModalAction = () => ({
  type: Types.HIDE_RECEIPT_OPTIONS_MODAL,
});
// End of show and hide of Receipt options modal action
