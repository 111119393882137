import React from "react";
import {
  onInputIncrementAction,
  onInputDecrementAction,
} from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

const IncDecInputForm = (props) => {
  const dispatch = useDispatch();

  // Start of increment decrement input number function
  let incNum = () => {
    dispatch(onInputIncrementAction(props?.formName, props?.name));
  };

  let decNum = () => {
    dispatch(onInputDecrementAction(props?.formName, props?.name));
  };
  // End of increment decrement input number function

  return (
    <div className="number_input_wrapper_style">
      <IndeterminateCheckBoxIcon onClick={decNum} />

      <input
        type="number"
        onWheel={(e) => e.target.blur()}
        name={props?.name}
        datatestid={props?.datatestid}
        value={props?.value}
        onChange={props?.handleInputChange}
      />

      <AddBoxIcon onClick={incNum} />
    </div>
  );
};

export default IncDecInputForm;
