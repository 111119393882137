// handle fetch countries types
const FETCH_COUNTRIES = "FETCH_COUNTRIES";

// handle store customer info form types
const STORE_CUSTOMER_INFO_FORM_DATA = "STORE_CUSTOMER_INFO_FORM_DATA";

// handle add shipping address types
const ADD_SHIPPING_ADDRESS = "ADD_SHIPPING_ADDRESS";

// hnadle clear shipping address types
const CLEAR_SHIPPING_ADDRESSES = "CLEAR_SHIPPING_ADDRESSES";

// handle add data to validated cart types
const ADD_DATA_TO_VALIDATED_CART = "ADD_DATA_TO_VALIDATED_CART";

// handle store cart total amount types
const STORE_CART_TOTAL_AMOUNT = "STORE_CART_TOTAL_AMOUNT";

// handle show split payment items types
const SHOW_SPLIT_PAYMENT_SECTION = "SHOW_SPLIT_PAYMENT_SECTION";

// handle of payment types
const SHOW_SPLIT_ITEM_SECTION = "SHOW_SPLIT_ITEM_SECTION";
const SHOW_CASH_SECTION = "SHOW_CASH_SECTION";
const SET_REMAINING_VALUE_OF_SPLIT_PAYMENTS =
  "SET_REMAINING_VALUE_OF_SPLIT_PAYMENTS";
const SET_PAID_VALUE_OF_SPLIT_PAYMENTS = "SET_PAID_VALUE_OF_SPLIT_PAYMENTS";
const REMOVE_SPLIT_ITEM_PAYMENT = "REMOVE_SPLIT_ITEM_PAYMENT";
const DISABLE_PAY_SPLIT_AMOUNT_INPUT = "DISABLE_PAY_SPLIT_AMOUNT_INPUT";

// handle store credit card split payment types
const STORE_CREDIT_CARD_SPLIT_PAYMENTS = "STORE_CREDIT_CARD_SPLIT_PAYMENTS";

// handle store cash split payment types
const STORE_CASH_SPLIT_PAYMENTS = "STORE_CASH_SPLIT_PAYMENTS";

// handle add split payment items types
const ADD_SPLIT_PAYMENTS_ITEMS = "ADD_SPLIT_PAYMENTS_ITEMS";

// handle items split payment types
const HANDLE_ON_CHANGE_ITEMS_PAYMENTS = "HANDLE_ON_CHANGE_ITEMS_PAYMENTS";

// handle item discount
const STORE_ITEM_DSICOUNT = "STORE_ITEM_DSICOUNT";

// handle reset checkout type
const RESET_CHECKOUT = "RESET_CHECKOUT";

// handle receipt options modal types
const SHOW_RECEIPT_OPTIONS_MODAL = "SHOW_RECEIPT_OPTIONS_MODAL";
const HIDE_RECEIPT_OPTIONS_MODAL = "HIDE_RECEIPT_OPTIONS_MODAL";

export default {
  // handle fetch countries types
  FETCH_COUNTRIES,

  // handle store customer info form types
  STORE_CUSTOMER_INFO_FORM_DATA,

  // handle add shipping address types
  ADD_SHIPPING_ADDRESS,

  // handle clear shipping address types
  CLEAR_SHIPPING_ADDRESSES,

  // handle add data to validated cart types
  ADD_DATA_TO_VALIDATED_CART,

  // handle store cart total amount types
  STORE_CART_TOTAL_AMOUNT,

  // handle show split payment items types
  SHOW_SPLIT_PAYMENT_SECTION,

  // handle of payment types
  SHOW_SPLIT_ITEM_SECTION,
  SHOW_CASH_SECTION,
  SET_REMAINING_VALUE_OF_SPLIT_PAYMENTS,
  SET_PAID_VALUE_OF_SPLIT_PAYMENTS,
  REMOVE_SPLIT_ITEM_PAYMENT,
  DISABLE_PAY_SPLIT_AMOUNT_INPUT,

  // handle store credit card split payment types
  STORE_CREDIT_CARD_SPLIT_PAYMENTS,

  // handle store cash split payment types
  STORE_CASH_SPLIT_PAYMENTS,

  // handle add split payment items types
  ADD_SPLIT_PAYMENTS_ITEMS,

  // handle items split payment types
  HANDLE_ON_CHANGE_ITEMS_PAYMENTS,

  // handle item discount
  STORE_ITEM_DSICOUNT,

  // handle reset checkout type
  RESET_CHECKOUT,

  // handle receipt options modal types
  SHOW_RECEIPT_OPTIONS_MODAL,
  HIDE_RECEIPT_OPTIONS_MODAL,
};
