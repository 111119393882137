import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const UnauthorizedPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      // navigate to login page
      navigate(`/`);
    }, 3000);

    return () => {};
  }, []);

  return (
    <div className="login_wrapper_style">
      <div>
        <img
          src={require("../../Assets/img/unothrized-loader.gif")}
          width="600"
          alt="Image Not Found"
        />
      </div>
    </div>
  );
};

export default UnauthorizedPage;
