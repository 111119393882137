import gql from "graphql-tag";

// login mutation
export const loginMutation = gql`
  mutation login($id: ID) {
    login(id: $id) {
      token
      entity {
        id
        name
        email
        contactName
      }
    }
  }
`;

// close station balance mutation
export const closeStationBalanceMutation = gql`
  mutation closeStationBalance($input: StationBalanceInput!) {
    closeStationBalance(input: $input) {
      status
      message
    }
  }
`;

// open station balance mutation
export const openStationBalanceMutation = gql`
  mutation openStationBalance($input: StationBalanceInput) {
    openStationBalance(input: $input) {
      status
      message
    }
  }
`;

// fetch item sales taxses mutation
export const salesTaxesMutation = gql`
  mutation fetchItemSalesTaxses($item_id: ID, $station_id: ID, $store_id: ID) {
    salesTaxes(
      item_id: $item_id
      station_id: $station_id
      store_id: 1000000
      store_front_id: $store_id
    ) {
      value
    }
  }
`;

// create new customer mutation
export const createNewCustomerMutation = gql`
  mutation createNewCustomer($input: CreateCustomerInput) {
    createCustomer(input: $input) {
      status
      message
      customer {
        id
        name
        email
        detail {
          phone
        }
        shippingAddresses {
          id
          address
          city
          state
          zip
          country {
            id
            code
          }
        }
      }
    }
  }
`;

// create shipping address mutation
export const creatShippingAddressMutation = gql`
  mutation createNewShippingAddress($input: CreateShippingAddress) {
    createShippingAddress(input: $input) {
      status
      message
      shippingAddress {
        id
        phone
        address
        city
        state
        zip
        country {
          id
          code
        }
      }
    }
  }
`;

// initiate payment mutation
export const initiatePaymentMutation = gql`
  mutation initiatePayment(
    $amount: Float
    $method: Int
    $stationId: Int
    $customer_id: Int
  ) {
    initiatePayment(
      amount: $amount
      method: $method
      stationId: $stationId
      customer_id: $customer_id
    ) {
      client_secret
      redirectUrl
    }
  }
`;

// validate cart mutation
export const validatedCartMutation = gql`
  mutation validateCart($cart: Cart) {
    validateCart(cart: $cart) {
      status
      message
    }
  }
`;

// checkout mutation
export const checkoutMutation = gql`
  mutation checkout($cart: Cart) {
    checkout(cart: $cart) {
      status
      message
      receipt
      order {
        id
      }
    }
  }
`;

// apply discount mutation
export const applyDiscountMutation = gql`
  mutation applyDiscount($cart: Cart) {
    applyDiscount(cart: $cart) {
      items {
        id
        parentCartItemId
        itemId
        itemName
        attributeOptionData
        quantity
        customAttributes {
          attributeId
          attributeValue
        }
        isBackorder
        price {
          amount
          discountAmount
        }
        tax {
          amount
          discountAmount
        }
        discounts {
          discountCalculation {
            type
            amount
          }
        }
      }
      shipping {
        amount
        discountAmount
      }
      applyDiscountCode
    }
  }
`;

// cancel discount mutation
export const cancelDiscountMutation = gql`
  mutation cancelDiscount($cart: Cart) {
    cancelDiscount(cart: $cart) {
      items {
        id
        parentCartItemId
        itemId
        itemName
        attributeOptionData
        quantity
        customAttributes {
          attributeId
          attributeValue
        }
        isBackorder
        price {
          amount
          discountAmount
        }
        tax {
          amount
          discountAmount
        }
        discounts {
          discountCalculation {
            type
            amount
          }
        }
      }
      shipping {
        amount
        discountAmount
      }
    }
  }
`;

// create terminal token mutation
export const createTerminalTokenMutation = gql`
  mutation createTerminalToken($method: Int, $stationId: Int) {
    createTerminalToken(method: $method, stationId: $stationId) {
      secret
    }
  }
`;

// return items cart mutation
export const returnItemsCartMutation = gql`
  mutation return($cart: ReturnCart) {
    return(cart: $cart) {
      message
      receipt
    }
  }
`;

// create receipt options mutation
export const sendOrderReceiptMutation = gql`
  mutation sendOrderReceipt(
    $orderId: ID!
    $type: OrderNotificationTypeEnum
    $receipt: String!
    $phone: String
    $email: String
  ) {
    sendOrderReceipt(
      orderId: $orderId
      type: $type
      receipt: $receipt
      phone: $phone
      email: $email
    ) {
      status
      message
    }
  }
`;
