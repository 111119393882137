import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalLayout from "../ModalLayout";
import { Alert } from "@mui/material";
import {
  hideCashDrawerModalAction,
  onInputValueChangeAction,
} from "../../Redux/Actions";
import { Button, Spinner } from "reactstrap";
import { useMutation, useLazyQuery } from "@apollo/client";
import {
  closeStationBalanceMutation,
  openStationBalanceMutation,
} from "../../graphql/mutations";
import { validateEnteredAmountQuery } from "../../graphql/queries";
import Swal from "sweetalert2";
import WarningForCashBalanceSection from "./WarningForCashBalanceSection";
import IncDecInputForm from "../FormComponents/IncDecInputForm";

const CashDrawerModal = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  // states from store
  const isModalVissible = useSelector(
    (state) => state?.main?.station?.cashDrawerModalActions?.isVissible
  );
  const cashDrawerModal = useSelector(
    (state) => state?.main?.station?.cashDrawerModal
  );

  // modal error message useState
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  // submit useState
  const [isSubmitting, setIsSubmitting] = useState(false);

  // notes and warning sections useStates
  const [showWarningSection, setShowWarningSection] = useState(false);
  const [showNotesSection, setShowNotesSectionNoteTextAreaValue] =
    useState(false);

  // handle toggle modal
  const toggleModal = () => {
    dispatch(hideCashDrawerModalAction());
  };

  // handle show notes section in modal
  const handleShowNotesSection = () => {
    setShowWarningSection(false);
    setShowNotesSectionNoteTextAreaValue(true);
  };

  // handle inputs change
  const handleInputChange = (e) => {
    dispatch(onInputValueChangeAction("cashDrawerModal", e));
  };

  // Start of handle validate Entered Amount query
  const handleValidateEnteredAmount = (totalEnteredAmount) => {
    validateEnteredAmount({
      variables: {
        input: {
          station_id: +cashDrawerModal?.station_id,
          amount: parseFloat(totalEnteredAmount),
          type: cashDrawerModal?.modalType === "open_station" ? 1 : 2,
        },
      },
    });
  };

  const [
    validateEnteredAmount,
    {
      data: validateEnteredAmountResponse,
      loading: isValidatingEntredAmountLoading,
      error: validateEnteredAmountError,
    },
  ] = useLazyQuery(validateEnteredAmountQuery, {
    onError(err) {
      // error swal
      Swal.fire({
        title: "Error",
        text:
          err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message,
        timer: 3000,
        showConfirmButton: false,
        imageUrl: require("../../Assets/img/error-icon.png"),
        imageAlt: "Image not found",
        customClass: {
          popup: "swal-error-style",
          container: "swal-title",
        },
      });

      // close station modal
      toggleModal();

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);

      if (data?.validateEnteredBillsAmount?.status == "1") {
        // handle open / close stattion according to action type
        if (cashDrawerModal?.modalType === "open_station") {
          handleOpenStationBalance(
            cashDrawerModal?.hundred_bill * 100 +
              cashDrawerModal?.fifty_bill * 50 +
              cashDrawerModal?.twenty_bill * 20 +
              cashDrawerModal?.ten_bill * 10 +
              cashDrawerModal?.five_bill * 5 +
              cashDrawerModal?.one_bill * 1 +
              cashDrawerModal?.twenty_five_cents * 0.25 +
              cashDrawerModal?.ten_cents * 0.1 +
              cashDrawerModal?.five_cents * 0.05 +
              cashDrawerModal?.one_cents * 0.01,
            cashDrawerModal?.notes
          );
        }
        if (cashDrawerModal?.modalType === "close_station") {
          handleCloseStationBalance(
            cashDrawerModal?.hundred_bill * 100 +
              cashDrawerModal?.fifty_bill * 50 +
              cashDrawerModal?.twenty_bill * 20 +
              cashDrawerModal?.ten_bill * 10 +
              cashDrawerModal?.five_bill * 5 +
              cashDrawerModal?.one_bill * 1 +
              cashDrawerModal?.twenty_five_cents * 0.25 +
              cashDrawerModal?.ten_cents * 0.1 +
              cashDrawerModal?.five_cents * 0.05 +
              cashDrawerModal?.one_cents * 0.01,
            cashDrawerModal?.notes
          );
        }
      } else {
        // show warning for cash balance section in modal
        setShowWarningSection(true);
      }
    },
  });
  // End of handle validate Entered Amount query

  // Start of handle close station mutation
  const handleCloseStationBalance = (totalCloseAmount, notes) => {
    closeStationBalance({
      variables: {
        input: {
          station_id: +cashDrawerModal?.station_id,
          amount: parseFloat(totalCloseAmount),
          note: notes,
        },
      },
    })
      .then((response) => {
        if (
          response?.errors ||
          response?.closeStationBalance?.status == "error"
        ) {
          // handle error message
          setModalErrorMessage(
            response.data?.closeStationBalance?.message
              ? response?.data?.closeStationBalance?.message
              : response?.errors[0]?.extensions?.reason
          );
        }
      })
      .catch((error) => {
        setModalErrorMessage(error?.message ?? "Something went wrong");
        console.log("catch", { error });
      });
  };

  const [
    closeStationBalance,
    {
      data: closeStationBalanceResponse,
      loading: isCloseStationBalanceLoading,
      error: closeStationBalanceError,
    },
  ] = useMutation(closeStationBalanceMutation, {
    onCompleted: (data) => {
      if (data?.closeStationBalance?.status == "success") {
        Swal.fire({
          title: "Success",
          text: data?.closeStationBalance?.message,
          timer: 3000,
          showConfirmButton: false,
          imageUrl: require("../../Assets/img/sucsses-icon.png"),
          imageAlt: "Image not found",
          customClass: {
            popup: "swal-success-style",
            container: "swal-title",
          },
        });

        // to close cash drawer modal
        toggleModal();

        // refetch store fronts
        window.location.reload();
        props?.handleFetchStoreFronts({});
      }
    },
  });
  // End of handle close station mutation

  // Start of handle open station mutation
  const handleOpenStationBalance = (totalOpenAmount, notes) => {
    openStationBalance({
      variables: {
        input: {
          station_id: +cashDrawerModal?.station_id,
          amount: parseFloat(totalOpenAmount),
          note: notes,
        },
      },
    })
      .then((response) => {
        if (
          response?.errors ||
          response.data?.openStationBalance?.status == "error"
        ) {
          setModalErrorMessage(
            response.data?.openStationBalance?.message
              ? response?.data?.openStationBalance?.message
              : response?.errors[0]?.message
          );
        }
      })
      .catch((error) => {
        setModalErrorMessage(error?.message ?? "Something went wrong");
        console.log("catch", { error });
      });
  };

  const [
    openStationBalance,
    {
      data: openStationBalanceResponse,
      loading: isOpenStationBalanceLoading,
      error: openStationBalanceError,
    },
  ] = useMutation(openStationBalanceMutation, {
    onCompleted: (data) => {
      if (data?.openStationBalance?.status == "success") {
        Swal.fire({
          title: "Success",
          text: data?.openStationBalance?.message,
          timer: 3000,
          showConfirmButton: false,
          imageUrl: require("../../Assets/img/sucsses-icon.png"),
          imageAlt: "Image not found",
          customClass: {
            popup: "swal-success-style",
            container: "swal-title",
          },
        });

        // to close cash drawer modal
        toggleModal();

        // navigate to the selected store items page
        navigate(
          `/products?station_id=${cashDrawerModal?.station_id}&station_name=${cashDrawerModal?.station_name}&store_id=${cashDrawerModal?.store_id}&store_name=${cashDrawerModal?.storeName}`
        );

        // refetch store fronts
        props?.handleFetchStoreFronts({});
      }
    },
  });
  // End of handle open station mutation

  const handleSubmitBtn = (submitType) => {
    if (submitType === "from_notes_section") {
      // handle open / close stattion according to action type
      if (cashDrawerModal?.modalType === "open_station") {
        handleOpenStationBalance(
          cashDrawerModal?.hundred_bill * 100 +
            cashDrawerModal?.fifty_bill * 50 +
            cashDrawerModal?.twenty_bill * 20 +
            cashDrawerModal?.ten_bill * 10 +
            cashDrawerModal?.five_bill * 5 +
            cashDrawerModal?.one_bill * 1 +
            cashDrawerModal?.twenty_five_cents * 0.25 +
            cashDrawerModal?.ten_cents * 0.1 +
            cashDrawerModal?.five_cents * 0.05 +
            cashDrawerModal?.one_cents * 0.01,
          cashDrawerModal?.notes
        );
      }
      if (cashDrawerModal?.modalType === "close_station") {
        handleCloseStationBalance(
          cashDrawerModal?.hundred_bill * 100 +
            cashDrawerModal?.fifty_bill * 50 +
            cashDrawerModal?.twenty_bill * 20 +
            cashDrawerModal?.ten_bill * 10 +
            cashDrawerModal?.five_bill * 5 +
            cashDrawerModal?.one_bill * 1 +
            cashDrawerModal?.twenty_five_cents * 0.25 +
            cashDrawerModal?.ten_cents * 0.1 +
            cashDrawerModal?.five_cents * 0.05 +
            cashDrawerModal?.one_cents * 0.01,
          cashDrawerModal?.notes
        );
      }
    } else {
      // handle validation the amount
      handleValidateEnteredAmount(
        cashDrawerModal?.hundred_bill * 100 +
          cashDrawerModal?.fifty_bill * 50 +
          cashDrawerModal?.twenty_bill * 20 +
          cashDrawerModal?.ten_bill * 10 +
          cashDrawerModal?.five_bill * 5 +
          cashDrawerModal?.one_bill * 1 +
          cashDrawerModal?.twenty_five_cents * 0.25 +
          cashDrawerModal?.ten_cents * 0.1 +
          cashDrawerModal?.five_cents * 0.05 +
          cashDrawerModal?.one_cents * 0.01
      );
    }

    setModalErrorMessage("");
    setIsSubmitting(true);
  };

  useEffect(() => {
    setIsSubmitting(false);
    setModalErrorMessage("");
    setShowWarningSection(false);
    setShowNotesSectionNoteTextAreaValue(false);
    return () => {};
  }, [isModalVissible]);

  return (
    <>
      <ModalLayout
        isOpen={isModalVissible}
        toggle={toggleModal}
        modalTitle={showWarningSection || showNotesSection ? "" : "Cash Drawer"}
        className="cash_drawer_modal_wrapper_style modal_right_style"
      >
        {showWarningSection || showNotesSection ? null : (
          <div>
            <div>
              {/* <div className="d-flex align-items-end justify-content-between">
                <label>Cash Drawer</label>

                <Button className="open_drawer_btn_style">Open Drawer</Button>
              </div> */}

              <p>Enter the current drawer count</p>
            </div>

            <div className="row">
              <label className="mb-2">Bills</label>

              <div className="row pe-0">
                <div className="col-12 col-lg-4 mb-3 mb-lg-0 pe-0">
                  <label className="mb-0 input_label_style">$100</label>

                  <IncDecInputForm
                    name="hundred_bill"
                    datatestid="hundred_bill_input"
                    value={cashDrawerModal?.hundred_bill}
                    formName="cashDrawerModal"
                    handleInputChange={handleInputChange}
                  />
                </div>

                <div className="col-12 col-lg-4 mb-3 mb-lg-0 pe-0">
                  <label className="mb-0 input_label_style">$50</label>

                  <IncDecInputForm
                    name="fifty_bill"
                    datatestid="fifty_bill_input"
                    value={cashDrawerModal?.fifty_bill}
                    formName="cashDrawerModal"
                    handleInputChange={handleInputChange}
                  />
                </div>

                <div className="col-12 col-lg-4 pe-0">
                  <label className="mb-0 input_label_style">$20</label>

                  <IncDecInputForm
                    name="twenty_bill"
                    datatestid="twenty_bill_input"
                    value={cashDrawerModal?.twenty_bill}
                    formName="cashDrawerModal"
                    handleInputChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="row margin_24px_style pe-0">
                <div className="col-12 col-lg-4 mb-3 mb-lg-0 pe-0">
                  <label className="mb-0 input_label_style">$10</label>

                  <IncDecInputForm
                    name="ten_bill"
                    datatestid="ten_bill_input"
                    value={cashDrawerModal?.ten_bill}
                    formName="cashDrawerModal"
                    handleInputChange={handleInputChange}
                  />
                </div>

                <div className="col-12 col-lg-4 mb-3 mb-lg-0 pe-0">
                  <label className="mb-0 input_label_style">$5</label>

                  <IncDecInputForm
                    name="five_bill"
                    datatestid="five_bill_input"
                    value={cashDrawerModal?.five_bill}
                    formName="cashDrawerModal"
                    handleInputChange={handleInputChange}
                  />
                </div>

                <div className="col-12 col-lg-4 pe-0">
                  <label className="mb-0 input_label_style">$1</label>

                  <IncDecInputForm
                    name="one_bill"
                    datatestid="one_bill_input"
                    value={cashDrawerModal?.one_bill}
                    formName="cashDrawerModal"
                    handleInputChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="row">
              <label className="mb-2">Coins</label>

              <div className="col-12 col-lg-4 mb-3 mb-lg-0 pe-lg-0">
                <label className="mb-0 input_label_style">25¢</label>

                <IncDecInputForm
                  name="twenty_five_cents"
                  datatestid="twenty_five_cents_input"
                  value={cashDrawerModal?.twenty_five_cents}
                  formName="cashDrawerModal"
                  handleInputChange={handleInputChange}
                />
              </div>

              <div className="col-12 col-lg-4 mb-3 mb-lg-0 pe-lg-0">
                <label className="mb-0 input_label_style">10¢</label>

                <IncDecInputForm
                  name="ten_cents"
                  datatestid="ten_cents_input"
                  value={cashDrawerModal?.ten_cents}
                  formName="cashDrawerModal"
                  handleInputChange={handleInputChange}
                />
              </div>

              <div className="col-12 col-lg-4">
                <label className="mb-0 input_label_style">5¢</label>

                <IncDecInputForm
                  name="five_cents"
                  datatestid="five_cents_input"
                  value={cashDrawerModal?.five_cents}
                  formName="cashDrawerModal"
                  handleInputChange={handleInputChange}
                />
              </div>

              <div className="col-12 col-lg-4 margin_24px_style">
                <label className="mb-0 input_label_style">1¢</label>

                <IncDecInputForm
                  name="one_cents"
                  datatestid="one_cents_input"
                  value={cashDrawerModal?.one_cents}
                  formName="cashDrawerModal"
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>

            <div className="mt-5 pt-5">
              <Button
                disabled={isValidatingEntredAmountLoading}
                className="closeing_station_btn_style"
                datatestid="validate_entered_amount_btn"
                type="submit"
                onClick={() => handleSubmitBtn("")}
              >
                {isValidatingEntredAmountLoading ? (
                  <Spinner size={"sm"} style={{ color: "#fff" }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </div>
        )}

        {showWarningSection ? (
          <div>
            <WarningForCashBalanceSection />

            <div className="row">
              <div className="col-12">
                <Button
                  className="recount_btn_style"
                  onClick={() => setShowWarningSection(false)}
                >
                  Recount
                </Button>
              </div>

              <div className="col-12">
                <Button
                  className="continue_over_short_btn_style"
                  type="submit"
                  onClick={handleShowNotesSection}
                >
                  Continue Over/ Short
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        {showNotesSection ? (
          <div className="drawer_cash_notes_section_style">
            <label className="mb-2">Cash drawer notes</label>
            <textarea
              rows="4"
              name="notes"
              onChange={handleInputChange}
              value={cashDrawerModal?.notes}
              placeholder="Enter Note"
            />

            <div className="d-flex justify-content-end">
              <Button
                disabled={
                  isOpenStationBalanceLoading || isCloseStationBalanceLoading
                }
                className="closeing_station_btn_style"
                type="submit"
                onClick={() => handleSubmitBtn("from_notes_section")}
              >
                {isOpenStationBalanceLoading || isCloseStationBalanceLoading ? (
                  <Spinner size={"sm"} style={{ color: "#fff" }} />
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </div>
        ) : null}

        {/* (Start) Error Message */}
        {modalErrorMessage && isSubmitting && (
          <Alert severity="error" className="mt-2">
            {modalErrorMessage}
          </Alert>
        )}
        {/* (End) Error Message */}
      </ModalLayout>
    </>
  );
};

export default CashDrawerModal;
