import React from "react";
import { TextField } from "@mui/material";
import ItemConstants from "../../Helpers/Constants/ItemConstants";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import _ from "lodash";
import Alert from "@mui/material/Alert";

const ItemCustomAttributes = (props) => {
  const handleAttrChange = (e, itemAttr) => {
    let updatedAttribute = { ...itemAttr };
    updatedAttribute.attributeValue = e?.target?.value;
    updatedAttribute.attributeName = e?.target?.getAttribute("attr_name");
    props?.onItemAttrbuteChange(updatedAttribute);
  };

  const handleResetItemAttrs = () => {
    props.onResetItemAttrs();
    return;
  };

  const renderItemAttrs = () => {
    return props?.item_attributes?.map((itemAttr, i) => {
      return (
        <>
          {itemAttr?.attr_type == ItemConstants.ITEM_ATTRIBUTE_TYPE_TEXT ? (
            <div key={itemAttr?.attr_id}>
              <TextField
                fullWidth
                className="custom_input_style"
                type="text"
                name={itemAttr?.attr_id}
                value={itemAttr?.attributeValue || ""}
                onChange={(e) => handleAttrChange(e, itemAttr)}
                label={`${itemAttr?.attr_name}`}
                disabled={!!props?.disabled}
                maxLength={itemAttr?.max_characters}
                variant="outlined"
                inputProps={{
                  maxLength: itemAttr?.max_characters,
                }}
              />
            </div>
          ) : (
            <div
              className={`${
                itemAttr?.options?.length > 1
                  ? "d-flex flex-column custom_combinations_toggle_btn_style"
                  : "d-none"
              }`}
            >
              <label>{itemAttr?.attr_name}</label>

              <ToggleButtonGroup
                variant=""
                color="dark"
                value={itemAttr?.attributeValue?.toString()}
                exclusive
                onChange={(e, value) => handleAttrChange(e, itemAttr)}
                aria-label="Platform"
                disabled={!!props?.disabled}
              >
                {itemAttr?.options.map((opt) => {
                  return (
                    <ToggleButton
                      className={
                        opt?.attr_option_id == itemAttr?.attributeValue
                          ? "active"
                          : ""
                      }
                      name={itemAttr?.attr_id}
                      attr_name={opt?.attr_option_name}
                      value={opt?.attr_option_id?.toString()}
                      // disabled={!!props?.disabled}
                    >
                      {opt?.attr_option_name}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </div>
          )}

          {props?.validation?.find(
            (item) =>
              item?.id == props?.itemCombo?.id &&
              item?.attrId == itemAttr?.attr_id
          ) ? (
            <Alert severity="error">{itemAttr?.attr_name} is required</Alert>
          ) : null}
        </>
      );
    });
  };

  return (
    <div className="attributes_wrapper_style">
      <div className="align-items-center d-flex flex-row justify-content-between default_attr_wrapper_style">
        <div className="default_attr_name_style">
          {props?.item_attributes
            ?.sort()
            ?.map((attr) => attr?.attributeName)
            ?.join("  ")}
        </div>

        <div
          className="reset_custom_combination_btn_style"
          onClick={() => handleResetItemAttrs()}
        >
          Clear
        </div>
      </div>

      <div className="choose_custom_attr_wrapper_style">
        {renderItemAttrs()}
      </div>
    </div>
  );
};

export default ItemCustomAttributes;
