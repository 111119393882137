import { gql } from "@apollo/client";
import { returnOrderFragment } from "../fragments";

// fetch items query
export const fetchItemsQuery = gql`
  query fetchItems(
    $page: Int
    $store_id: Int!
    $station_id: Int!
    $item_name: String
    $barcode: String
    $category_id: Int
    $brand_id: Int
    $manufacturer_id: Int
  ) {
    getItems(
      input: {
        station_id: $station_id
        store_id: 1000000
        store_front_id: $store_id
        filters: {
          item_name: $item_name
          barcode: $barcode
          category_id: $category_id
          brand_id: $brand_id
          manufacturer_id: $manufacturer_id
        }
      }
      first: 12
      page: $page
    ) {
      paginatorInfo {
        count
        currentPage
        total
        lastPage
      }
      data {
        id
        name
        price
        quantity
        is_backorder
        item_type
        weight
        weight_unit
        quantity
        description
        image_url {
          small
        }
      }
    }
    getBrands {
      id
      name
    }
    getItemCategories {
      id
      name
    }
    getManufacturers {
      id
      name
    }
  }
`;

// fetch items combination query
export const fetchItemCombinationQuery = gql`
  query fetchItemCombinations($item_id: Int, $stationId: Int!, $storeId: Int!) {
    getItemCombinations(
      item_id: $item_id
      input: {
        store_id: 1000000
        station_id: $stationId
        store_front_id: $storeId
      }
    ) {
      id
      name
      price
      is_backorder
      description
      weight
      weight_unit
      image_url {
        small
      }
      item_type
      available_combinations {
        attribute_option_data
        parsed_attribute_option_data
        quantity
      }
      item_attributes {
        attr_id
        attr_type
        attr_name
        attr_option_id
        attr_option_name
        attr_option_value
        free_characters
        max_characters
        price_per_extra_character
      }
      child_items {
        id
        name
        price
        is_backorder
        description
        weight
        weight_unit
        image_url {
          small
        }
        item_type
        available_combinations {
          attribute_option_data
          parsed_attribute_option_data
          quantity
        }
        item_attributes {
          attr_id
          attr_type
          attr_name
          attr_option_id
          attr_option_name
          attr_option_value
          free_characters
          max_characters
          price_per_extra_character
        }
      }
    }
  }
`;

// fetch store fronts query
export const fetchStoreFrontsQuery = gql`
  query getStores($store_name: String) {
    storeFronts(store_id: 1000000, store_name: $store_name) {
      id
      name
      is_active
      stations {
        id
        status
        stationUser {
          cashier {
            id
            name
          }
        }
      }
    }
  }
`;

// fetch store stations query
export const fetchStationsQuery = gql`
  query getStations($storeId: Int!) {
    getStations(store_front_id: $storeId) {
      id
      name
      status
      stripe_location_id
      is_terminal_connected
      terminal_type
      test_terminal
      stationUser {
        cashier {
          id
          name
        }
      }
    }
  }
`;

// validate sales person id query
export const validateSalesPersonIdQuery = gql`
  query validateSalesPersonId($sales_person_id: Int!) {
    validateSalesPersonId(sales_person_id: $sales_person_id) {
      status
      message
    }
  }
`;

// validate entered amount for cash drawer query
export const validateEnteredAmountQuery = gql`
  query validateSalesPersonId($input: EnteredBillsAmountInput) {
    validateEnteredBillsAmount(input: $input) {
      status
      message
    }
  }
`;

// validate auth password query
export const validateAuthPasswordQuery = gql`
  query validateAuthPassword($password: String!) {
    validateAuthPassword(password: $password)
  }
`;

// print drawer manually query
export const manualDrawerPrintViewQuery = gql`
  query manualDrawerPrintView($page_type: PageType!, $station_id: Int!) {
    manualDrawerPrintView(page_type: $page_type, station_id: $station_id)
  }
`;

// fetch customer info query
export const fetchCustomerInfoQuery = gql`
  query getCustomerInfo($filters: CustomerFiltersInput) {
    getCustomers(filters: $filters) {
      id
      name
      email
      detail {
        phone
      }
      shippingAddresses {
        id
        address
        city
        apartment
        email
        entity_id
        first_name
        name
        last_name
        phone
        state
        warehouse_id
        country {
          code
          id
          name
        }
        zip
      }
    }
  }
`;

// fetch countries query
export const fetchCountriesQuery = gql`
  query fetchCountries {
    getCountries {
      id
      name
      code
    }
  }
`;

// fetch country states query
export const fetchCountryStatesQuery = gql`
  query fetchCountryStates($country_id: Int!) {
    getStates(country_id: $country_id) {
      id
      name
    }
  }
`;

// verfiy address query
export const verfiyAddressQuery = gql`
  query verfiyAddress(
    $input: AddressDetailsArgumentsInput!
    $store_front_id: Int!
  ) {
    verifyAddress(input: $input, store_front_id: $store_front_id) {
      status
      original_address {
        name
        phone
        company_name
        address_line1
        address_line2
        address_line3
        city_locality
        state_province
        postal_code
        country_code
        address_residential_indicator
      }
      matched_address {
        name
        phone
        company_name
        address_line1
        address_line2
        address_line3
        city_locality
        state_province
        postal_code
        country_code
        address_residential_indicator
      }
      messages {
        code
        message
        type
        detail_code
      }
    }
  }
`;

// calacualte estimate rate query
export const estimateRateQuery = gql`
  query calcualteEstimateRate(
    $station_id: Int!
    $shipping_address_id: Int!
    $weight: WeightInput!
  ) {
    getEstimateRate(
      station_id: $station_id
      shipping_address_id: $shipping_address_id
      weight: $weight
    ) {
      status
      shipping_rate {
        carrier_id
        carrier_nickname
        service_type
        package_type
        shipping_amount {
          currency
          amount
        }
        carrier_delivery_days
        estimated_delivery_date
      }
    }
  }
`;

// get return order query
export const getReturnOrderQuery = gql`
  query getReturnOrder($id: String!) {
    getReturnOrder(id: $id) {
      ...returnOrderUnionResponse
    }
  }
  ${returnOrderFragment}
`;

// fetch return reason options query
export const fetchReturnReasonOptionsQuery = gql`
  query fetchReturnReasonOptions {
    rmaReasons {
      id
      name
      is_required
      is_active
      is_return
      is_default
    }
  }
`;

// Get user query
export const getUserQuery = gql`
  query getUser {
    me {
      id
      name
      email
      contactName
    }
  }
`;
