import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ModalLayout from "../ModalLayout";
import {
  hideConfirmPasswordModalAction,
  showOpenDrawerManuallyModalAction,
  showSalesPersonIdModalAction,
  resetStripeTerminalInfoAction,
} from "../../Redux/Actions";
import { Alert, TextField } from "@mui/material";
import { Button, Spinner } from "reactstrap";
import { useLazyQuery } from "@apollo/client";
import { validateAuthPasswordQuery } from "../../graphql/queries";

const ConfirmPasswordModal = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const confirmPasswordModal = useSelector(
    (state) => state?.main?.auth?.confirmPasswordModal
  );

  const stationInfo = useSelector((state) => state?.main?.station?.stationInfo);

  // states from store
  const isModalVissible = useSelector((state) =>
    confirmPasswordModal?.modalType === "exit_pos_session"
      ? state?.main?.auth?.confirmPasswordModalOutOfSessionActions?.isVissible
      : state?.main?.auth?.confirmPasswordModalActions?.isVissible
  );

  // modal error message useState
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  // submit useState
  const [isSubmitting, setIsSubmitting] = useState(false);

  // inputs useStates
  const [passwordValue, setPasswordValue] = useState("");

  const handleSubmitBtn = () => {
    handleConfirmPassword({
      variables: {
        password: passwordValue,
      },
    });

    setModalErrorMessage("");
    setIsSubmitting(true);
  };

  // handle toggle modal
  const toggleModal = () => {
    dispatch(hideConfirmPasswordModalAction());
  };

  // handle inputs change
  const handleInputChange = (e) => {
    setPasswordValue(e?.target?.value);
  };

  // handle show open drawer manually modal
  const handleOpenDrawerManually = () => {
    let data = {};
    dispatch(showOpenDrawerManuallyModalAction({ data }));
  };

  // Start of handle validate auth password query
  const [
    handleConfirmPassword,
    {
      data: confirmPasswordResponse,
      loading: isConfirmPasswordLoading,
      error: confirmPasswordError,
    },
  ] = useLazyQuery(validateAuthPasswordQuery, {
    onError(err) {
      // handle error message
      setModalErrorMessage(
        err?.graphQLErrors[0]?.extensions?.reason ??
          err?.graphQLErrors[0]?.message
      );

      console.log("onError", { err });
    },
    onCompleted: (data) => {
      console.log("onCompleted", data);

      if (data?.validateAuthPassword) {
        // close sales person id modal
        toggleModal();

        if (confirmPasswordModal?.modalType === "open_drawer_manually") {
          // show open drawer manually modal
          handleOpenDrawerManually();
        } else {
          // navigate to store front page
          navigate(`/store-fronts`);
        }
      } else {
        setModalErrorMessage("Something went wrong");
      }
    },
  });
  // End of handle validate auth password query

  // handle go back to pos
  const handleGoBackToPos = () => {
    // close sales person id modal
    toggleModal();

    // reset stripe terminal info
    dispatch(resetStripeTerminalInfoAction());

    // show sales person id
    let data = {};
    dispatch(showSalesPersonIdModalAction({ data }));

    navigate(
      `/products?station_id=${stationInfo?.stationId}&station_name=${stationInfo?.stationName}&store_id=${stationInfo?.storeId}&store_name=${stationInfo?.storeName}`
    );
  };

  useEffect(() => {
    setIsSubmitting(false);
    setModalErrorMessage("");
    setPasswordValue("");
    return () => {};
  }, [isModalVissible]);

  return (
    <>
      <ModalLayout
        modalTitle={
          confirmPasswordModal?.modalType === "open_drawer_manually"
            ? "To Open Drawer"
            : "To Exit POS and Return to Non POS Applications"
        }
        isOpen={
          location?.pathname === "/products" &&
          confirmPasswordModal?.modalType === "exit_pos_session"
            ? false
            : isModalVissible
        }
        toggle={
          confirmPasswordModal?.modalType === "exit_pos_session"
            ? null
            : toggleModal
        }
        className="confirm_password_modal_wrapper_style"
        centered
      >
        <div>
          <div className="row">
            <div className="col-12">
              <TextField
                fullWidth
                placeholder="Enter Email"
                type="text"
                name="email"
                value={confirmPasswordModal?.authEmail}
                disabled
                label="Email"
                variant="outlined"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <TextField
                fullWidth
                type="password"
                name="password"
                placeholder="Enter Your Login Password"
                datatestid="confirm_password_input_in_modal"
                value={passwordValue}
                onChange={handleInputChange}
                label="Password"
                variant="outlined"
              />
            </div>
          </div>

          <Button
            disabled={isConfirmPasswordLoading}
            className="closeing_station_btn_style w-100"
            datatestid="confirm_password_btn_in_modal"
            type="submit"
            onClick={handleSubmitBtn}
          >
            {isConfirmPasswordLoading ? (
              <Spinner size={"sm"} style={{ color: "#fff" }} />
            ) : (
              "Confirm Password"
            )}
          </Button>

          {confirmPasswordModal?.modalType === "exit_pos_session" ? (
            <Button
              className="closeing_station_btn_style go_to_pos_btn_style w-100"
              datatestid="go_to_pos_btn_in_confirm_password_modal"
              onClick={handleGoBackToPos}
            >
              Go Back to POS
            </Button>
          ) : null}
        </div>

        {/* (Start) Error Message */}
        {modalErrorMessage && isSubmitting && (
          <Alert severity="error" className="mt-2">
            {modalErrorMessage}
          </Alert>
        )}
        {/* (End) Error Message */}
      </ModalLayout>
    </>
  );
};

export default ConfirmPasswordModal;
